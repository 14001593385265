import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as snakesActions from "../../../redux/actions/SnakesActions";
import * as dogsActions from "../../../redux/actions/DogsActions";
import * as catsActions from "../../../redux/actions/CatsActions";
import DogAccessForm from "../forms/DogAccessForm";

export default function DogSnakesPanel(props) {
    const {dogId} = props;
    const [loadingSet, setLoadingSet] = useState(Set());
    const [updT, setUpdT] = useState(0);

    const {dog, loading, usersMap, dogs, getUser, snakes, currentUserId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let currentUserId = state.users.currentUserId;
        return {
            dog: state.dogs.dogsMap.get(dogId),
            loading: state.users.loading || state.snakes.loading || state.cats.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            getUser: x => state.users.usersMap.get(x),
            snakes: state.snakes.snakesMap.toArray().filter(a => (a.dogId == dogId))

        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(snakesActions.loadDogSnakes(dogId));
    }, [dogId]);

    return (
        <Wrapper>

            {snakes.length == 0 ?
                <div style={{marginBottom: 20}}>
                    Еще ни у кого нет доступа к Вашим прокси
                </div>
                :
                <ItemsPlaceholder>
                    {snakes.map((sn, i) => {
                        let isLoading = loadingSet.has(sn.id);
                        return (
                            <Item key={sn.id}>
                                <DatePlaceholder>
                                    {`${moment(sn.fromTimestamp).format('DD.MM.YYYY')} - ${moment(sn.toTimestamp).format('DD.MM.YYYY')}`}
                                </DatePlaceholder>
                                <BuyerPlaceholder>
                                    {sn.buyerId}
                                </BuyerPlaceholder>
                                {isLoading == true ? <Spin visible={true}/> :
                                    <DeleteSpan onClick={async () => {
                                        let aId = sn.id;
                                        setLoadingSet(loadingSet.add(sn.id));
                                        await dispatch(snakesActions.deleteSnake(sn.id));
                                        setLoadingSet(loadingSet.delete(aId));
                                    }}>
                                        удалить доступ
                                    </DeleteSpan>
                                }
                            </Item>
                        )
                    })}
                </ItemsPlaceholder>
            }

            <BottomFormPlaceholder key={updT}>
                <DogAccessForm loading={loading} onSave={async d => {
                    if (d.buyerId == undefined || d.buyerId.length < 5) {
                        return;
                    }
                    let dd = {...d, sellerId: currentUserId, dogId: dogId};
                    await dispatch(snakesActions.createSnake(dd));
                    await dispatch(snakesActions.loadDogSnakes(dogId));
                    await dispatch(catsActions.loadAllCats());

                    setUpdT(+new Date());
                }}/>
            </BottomFormPlaceholder>

        </Wrapper>
    );
}

const BottomFormPlaceholder = styled.div`
    
`;

const Wrapper = styled.div`
    
`;

const ItemsPlaceholder = styled.div`
    margin-bottom: 20px;  
`;

const Item = styled.div`
    padding: 5px;
    background: white;
    border-radius: 5px;
    border: 1px solid whitesmoke;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DatePlaceholder = styled.div`
    
`;

const BuyerPlaceholder = styled.div`
    margin-left: 30px;
    margin-right: 5px;
`;

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 20px;
    :hover{
      opacity: 0.9;
    }
`;
