import SnakesAPI from '../../api/SnakesAPI'

import * as types from '../ActionTypes'

let loadSnakes_ = () => {
    return {
        type: types.LOAD_SNAKES
    }
}
let loadSnakesSuccess = (snakes) => {
    return {
        type: types.LOAD_SNAKES_SUCCESS,
        snakes: snakes
    }
}
let loadSnakesFailed = (error) => {
    return {
        type: types.LOAD_SNAKES_FAIL,
        error: error
    }
}

//thunk
export function loadAllSnakes() {
    return (dispatch, getState) => {
        dispatch(loadSnakes_());
        return SnakesAPI.getAllSnakes().then(
            snakes => dispatch(loadSnakesSuccess(snakes)),
            error => dispatch(loadSnakesFailed(error))
        )
    }
}

export function loadSnakeById(id) {
    return (dispatch, getState) => {
        dispatch(loadSnakes_());
        return SnakesAPI.getSnake(id).then(
            snake => dispatch(loadSnakesSuccess([snake])),
            error => dispatch(loadSnakesFailed(error))
        )
    }
}

export function loadUserSnakes(userId) {
    return (dispatch, getState) => {
        dispatch(loadSnakes_());
        return SnakesAPI.getUserSnakes(userId).then(
            snake => dispatch(loadSnakesSuccess(snake)),
            error => dispatch(loadSnakesFailed(error))
        )
    }
}

export function loadDogSnakes(dogId) {
    return (dispatch, getState) => {
        dispatch(loadSnakes_());
        return SnakesAPI.getDogSnakes(dogId).then(
            snake => dispatch(loadSnakesSuccess(snake)),
            error => dispatch(loadSnakesFailed(error))
        )
    }
}

let createSnake_ = () => {
    return {
        type: types.CREATE_SNAKE
    }
}
let createSnakeSuccess = (snake) => {
    return {
        type: types.CREATE_SNAKE_SUCCESS,
        snake: snake
    }
}
let createSnakeFailed = (error) => {
    return {
        type: types.CREATE_SNAKE_FAIL,
        error: error
    }
}

//thunk
export function createSnake(data) {
    return (dispatch, getState) => {
        dispatch(createSnake_());
        return SnakesAPI.createSnake(data).then(
            snake => dispatch(createSnakeSuccess(snake)),
            error => dispatch(createSnakeFailed(error))
        )
    }
}

let updateSnake_ = () => {
    return {
        type: types.UPDATE_SNAKE
    }
}
let updateSnakeSuccess = (snake) => {
    return {
        type: types.UPDATE_SNAKE_SUCCESS,
        snake: snake
    }
}
let updateSnakeFailed = (error) => {
    return {
        type: types.UPDATE_SNAKE_FAIL,
        error: error
    }
}

//thunk
export function updateSnake(data) {
    return (dispatch, getState) => {
        dispatch(updateSnake_());
        return SnakesAPI.updateSnake(data).then(
            snake => dispatch(updateSnakeSuccess(snake)),
            error => dispatch(updateSnakeFailed(error))
        )
    }
}

let deleteSnake_ = () => {
    return {
        type: types.DELETE_SNAKE
    }
}
let deleteSnakeSuccess = (id) => {
    return {
        type: types.DELETE_SNAKE_SUCCESS,
        id: id
    }
}
let deleteSnakeFailed = (error) => {
    return {
        type: types.DELETE_SNAKE_FAIL,
        error: error
    }
}

//thunk
export function deleteSnake(id) {
    return (dispatch, getState) => {
        dispatch(deleteSnake_());
        return SnakesAPI.deleteSnake(id).then(
            () => dispatch(deleteSnakeSuccess(id)),
            error => dispatch(deleteSnakeFailed(error))
        )
    }
}
