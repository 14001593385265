import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";

import * as usersActions from '../../../redux/actions/UsersActions'
import * as actions from '../../../redux/actions/ProductsActions'
import * as dealsActions from '../../../redux/actions/DealsActions'
import {BlueButton, RedButton} from "../../ira/ui/Buttons";
import UpdateDealForm from "../forms/UpdateDealForm";
import TwoPeopleChatPanel from "../../chat/panels/TwoPeopleChatPanel";
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserDealsPanel(props) {
    const {userId, mode = 'incoming'} = props;
    const {currentUser, myTelegram, order, loading, isMe, deals, getUserDogs, currentUserId, products, allOrders, isMyOrder, uId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let uId = (userId == undefined) ? currentUserId : userId;
        console.log('uId = ', uId);
        let deals = state.deals.dealsMap.toArray().filter(a => (a.userId == uId));
        if (mode == 'incoming') {
            deals = deals.filter(a => (a.sellerId == uId && a.buyerId == currentUserId));
        } else {
            deals = deals.filter(a => (a.buyerId == currentUserId));
        }
        deals = deals.map(a => ({
            ...a,
            product: state.products.productsMap.get(a.productId)
        })).filter(a => (a.product != undefined))
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let products = state.products.productsMap.toArray().filter(a => (a.userId == uId));
        return {
            deals: deals,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            uId: uId,
            loading: state.products.loading,
            products: products,
            myTelegram: currentUser == undefined ? '' : currentUser.telegram
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadAllProducts(uId));
        dispatch(dealsActions.loadUserDeals(uId));
        dispatch(dealsActions.loadUserDeals(currentUserId));
        setTimeout(() => {
            dispatch(usersActions.loadUserById(currentUserId))
        }, 400);
    }, [uId]);

    const [selectedId, setSelectedId] = useState();
    const [addVisible, setAddVisible] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);

    const selectedDeal = (selectedId == undefined) ? undefined : deals.filter(a => (a.id == selectedId))[0];

    console.log('deals = ', deals);
    console.log('currentUser = ', currentUser);

    return (
        <Wrapper>

            {products.length == 0 ? null :
                <TopPlaceholder>
                    {/*<div style={{marginBottom: 10}} >*/}
                    {/*<BlueButton onClick={() => {*/}
                    {/*setChatVisible(true);*/}
                    {/*}} >*/}
                    {/*Открыть чат*/}
                    {/*</BlueButton>*/}
                    {/*</div>*/}
                    {products.length == 0 ? null :
                        <RedButton onClick={() => {
                            setAddVisible(true);
                        }}>
                            Запросить прокси
                        </RedButton>
                    }
                </TopPlaceholder>
            }


            {deals.length == 0 ? null :
                <h3 style={{textAlign: 'center'}}>
                    Предыдущие сделки c продавцом
                </h3>
            }

            <ListPlaceholder>
                {deals.map((a, i) => {
                    let {product} = a;
                    return (
                        <Box key={a.id} onClick={() => {
                            // setSelectedId(a.id);
                            CommonHelper.linkTo(`/product/${product.id}`)
                        }}>
                            <BoxTop>
                                <BoxName>
                                    {product.name}
                                </BoxName>
                            </BoxTop>
                            <BoxMiddle>

                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/info_svg_.svg')}/>
                                    <BoxRowName>
                                        {a.text}
                                    </BoxRowName>
                                </BoxRow>

                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/cal_cal.svg')}/>
                                    <BoxRowName>
                                        {moment(a.timestamp).format('DD.MM.YYYY HH:mm')}
                                    </BoxRowName>
                                </BoxRow>

                            </BoxMiddle>
                        </Box>
                    )
                })}
            </ListPlaceholder>

            {selectedDeal == undefined ? null :
                <NiceModalWithHeading width={820}
                                      heading={'Редактировать услугу'}
                                      subHeading={''}
                                      onClose={() => {
                                          setSelectedId(undefined);
                                      }}>

                    <UpdateDealForm {...selectedDeal}
                                    products={products}
                                    loading={loading}
                                    canDelete={true}
                                    onSave={async d => {
                                        await dispatch(dealsActions.updateDeal({...d, id: selectedDeal.id}));
                                        setSelectedId(undefined);
                                    }}
                                    onDelete={async () => {
                                        await dispatch(dealsActions.deleteDeal(selectedDeal.id));
                                        setSelectedId(undefined);
                                    }}

                    />

                </NiceModalWithHeading>
            }

            {addVisible == false ? null :
                <NiceModalWithHeading heading={'Запросить прокси'}
                                      width={820}
                                      subHeading={'Укажите информацию для поставщика прокси'}
                                      onClose={() => {
                                          setAddVisible(false);
                                      }}>

                    <UpdateDealForm
                        products={products}
                        loading={loading}
                        telegram={myTelegram}
                        onSave={async d => {
                            if (d.productId == undefined) {
                                window.alert('Необходимо выбрать прокси');
                                return;
                            }
                            await dispatch(dealsActions.createDeal({
                                ...d,
                                userId: uId,
                                buyerId: currentUserId,
                                sellerId: uId
                            }));
                            setAddVisible(false);
                            CommonHelper.linkTo(`/product/${d.productId}`);
                        }}
                    />

                </NiceModalWithHeading>
            }

            {chatVisible == false ? null :
                <NiceModalWithHeading heading={'Чат'}
                                      width={720}
                                      subHeading={''}
                                      onClose={() => {
                                          setChatVisible(false);
                                      }}>

                    <div style={{height: window.innerHeight * 0.7, width: '100%'}}>
                        <TwoPeopleChatPanel firstUserId={userId} secondUserId={currentUserId}/>
                    </div>

                </NiceModalWithHeading>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
`;


const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;


const ListPlaceholder = styled.div`

`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 6px;
    :hover{
      opacity: 0.8;
    }
    
   
    
`;


const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;

