import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

export default function DogUptimeTool(props) {
    const {id} = props;
    const [data, setData] = useState(undefined);
    useEffect(() => {
        axios.get(`${API_ENDPOINT}/dog/${id}/uptime`).then(d => d.data.result).then(pld => {
            setData(pld);
        })
    }, [id]);

    if (data == undefined) {
        return (<Spin visible={true}/>)
    }

    let {stat_1h, stat_6h, stat_24h, stat_7d} = data;

    return (
        <Wrapper>
            <Item>
                {`1ч - ${(stat_1h == undefined) ? 'N/A' : (stat_1h * 100.0).toFixed(1) + '%'}`}
            </Item>
            <Item>
                {`6ч - ${(stat_6h == undefined) ? 'N/A' : (stat_6h * 100.0).toFixed(1) + '%'}`}
            </Item>
            <Item>
                {`1д - ${(stat_24h == undefined) ? 'N/A' : (stat_24h * 100.0).toFixed(1) + '%'}`}
            </Item>
            <Item>
                {`7д - ${(stat_7d == undefined) ? 'N/A' : (stat_7d * 100.0).toFixed(1) + '%'}`}
            </Item>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;    
`;

const Item = styled.div`
    margin-right: 10px;
`;

