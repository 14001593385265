import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import NiceModal from "./NiceModal";

export default function NiceModalWithHeading(props) {
    const {
        onClose = () => {

        },
        width = 420,
        heading = 'Heading',
        subHeading = 'Sub Heading',
        closeOnClickOutside = false
    } = props;

    return (
        <NiceModal onClose={onClose} closeOnClickOutside={closeOnClickOutside} >

            <Inner width={width}>
                {heading == '' ? null :
                    <EditInnerHeader>
                        {heading}
                    </EditInnerHeader>
                }

                {subHeading == '' ? null :
                    <SubModalHeading>
                        {subHeading}
                    </SubModalHeading>
                }

                <Content>
                    {props.children}
                </Content>

            </Inner>

        </NiceModal>
    );
}

const Wrapper = styled.div`
    
`;

const defaultWidth = 420;

const Inner = styled.div`
    width: ${props => (props.width == undefined ? defaultWidth : props.width)}px;
    @media(max-width: ${props => ((props.width == undefined ? defaultWidth : props.width) + 60)}px){
      width: 100%;
    }
`;

const EditInnerHeader = styled.div`
    text-align: center;
    letter-spacing: 0.166667px;
    color: #333539;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 19px;
`;

const SubModalHeading = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.7);
    margin-bottom: 20px;
`;

const Content = styled.div`
    
`;
