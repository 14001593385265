import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {PAYMENT_OPTIONS} from "../panels/UserUpdatePaymentMethodsPanel";

import {Label, Input} from "../../ira/ui/Inputs";

import SmartSelect from '../../ira/ui/SmartSelect'

import {BlueButton} from "../../ira/ui/Buttons";

const OPTIONS = [
    {
        label: 'Проверен админимстрацией',
        value: 'trusted'
    },
    {
        label: 'Очень качественный',
        value: 'quality'
    }
]

export default function UserAdminForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;

    const [deposit, setDeposit] = useState(props.deposit == undefined ? 0 : props.deposit);
    const [badges, setBadges] = useState(props.badges == undefined ? [] : props.badges);

    return (
        <Wrapper>

            <Row>
                <Half>
                    <Label>
                        Отметки
                    </Label>
                    <SmartSelect options={OPTIONS}
                                 selectedValues={badges}
                                 isMulti={true}
                                 placeholder={'Выберите отметки'}
                                 onSelect={a => {
                                     setBadges(a);
                                 }}
                    />
                </Half>
                <Half>
                    <Label>
                        Депозит
                    </Label>
                    <Input value={deposit}
                           placeholder={'Депозит'}
                           onChange={evt => {
                               setDeposit(evt.target.value);
                           }}/>
                </Half>
            </Row>

            <div style={{marginTop: 10}}>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <BlueButton onClick={() => {
                        onSave({
                            deposit: +deposit,
                            badges: (badges == undefined) ? [] : badges
                        });
                    }}>
                        Save
                    </BlueButton>
                }
            </div>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
  
`;

const Half = styled.div`
    flex: 1;
`;
