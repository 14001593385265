import TestimonialsAPI from '../../api/TestimonialsAPI'

import * as types from '../ActionTypes'

let loadTestimonials_ = () => {
    return {
        type: types.LOAD_TESTIMONIALS
    }
}
let loadTestimonialsSuccess = (testimonials) => {
    return {
        type: types.LOAD_TESTIMONIALS_SUCCESS,
        testimonials: testimonials
    }
}
let loadTestimonialsFailed = (error) => {
    return {
        type: types.LOAD_TESTIMONIALS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTestimonials() {
    return (dispatch, getState) => {
        dispatch(loadTestimonials_());
        return TestimonialsAPI.getAllTestimonials().then(
            testimonials => dispatch(loadTestimonialsSuccess(testimonials)),
            error => dispatch(loadTestimonialsFailed(error))
        )
    }
}

export function loadUserTestimonials(userId) {
    return (dispatch, getState) => {
        dispatch(loadTestimonials_());
        return TestimonialsAPI.getUserTestimonials(userId).then(
            testimonials => dispatch(loadTestimonialsSuccess(testimonials)),
            error => dispatch(loadTestimonialsFailed(error))
        )
    }
}

export function loadTestimonialById(id) {
    return (dispatch, getState) => {
        dispatch(loadTestimonials_());
        return TestimonialsAPI.getTestimonial(id).then(
            testimonial => dispatch(loadTestimonialsSuccess([testimonial])),
            error => dispatch(loadTestimonialsFailed(error))
        )
    }
}

let createTestimonial_ = () => {
    return {
        type: types.CREATE_TESTIMONIAL
    }
}
let createTestimonialSuccess = (testimonial) => {
    return {
        type: types.CREATE_TESTIMONIAL_SUCCESS,
        testimonial: testimonial
    }
}
let createTestimonialFailed = (error) => {
    return {
        type: types.CREATE_TESTIMONIAL_FAIL,
        error: error
    }
}

//thunk
export function createTestimonial(data) {
    return (dispatch, getState) => {
        dispatch(createTestimonial_());
        return TestimonialsAPI.createTestimonial(data).then(
            testimonial => dispatch(createTestimonialSuccess(testimonial)),
            error => dispatch(createTestimonialFailed(error))
        )
    }
}

let updateTestimonial_ = () => {
    return {
        type: types.UPDATE_TESTIMONIAL
    }
}
let updateTestimonialSuccess = (testimonial) => {
    return {
        type: types.UPDATE_TESTIMONIAL_SUCCESS,
        testimonial: testimonial
    }
}
let updateTestimonialFailed = (error) => {
    return {
        type: types.UPDATE_TESTIMONIAL_FAIL,
        error: error
    }
}

//thunk
export function updateTestimonial(data) {
    return (dispatch, getState) => {
        dispatch(updateTestimonial_());
        return TestimonialsAPI.updateTestimonial(data).then(
            testimonial => dispatch(updateTestimonialSuccess(testimonial)),
            error => dispatch(updateTestimonialFailed(error))
        )
    }
}

let deleteTestimonial_ = () => {
    return {
        type: types.DELETE_TESTIMONIAL
    }
}
let deleteTestimonialSuccess = (id) => {
    return {
        type: types.DELETE_TESTIMONIAL_SUCCESS,
        id: id
    }
}
let deleteTestimonialFailed = (error) => {
    return {
        type: types.DELETE_TESTIMONIAL_FAIL,
        error: error
    }
}

//thunk
export function deleteTestimonial(id) {
    return (dispatch, getState) => {
        dispatch(deleteTestimonial_());
        return TestimonialsAPI.deleteTestimonial(id).then(
            () => dispatch(deleteTestimonialSuccess(id)),
            error => dispatch(deleteTestimonialFailed(error))
        )
    }
}
