import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateProfilePanel from "../users/panels/UpdateProfilePanel";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import UserProfileBalancePanel from "../users/panels/UserProfileBalancePanel";
import UserBookingsPanel from "../bookings/panels/UserBookingsPanel";
import ReshakaTemplate from "../templates/ReshakaTemplate";

const TABS = [
    {
        label: 'Найти продавца',
        value: 'sellers',
    },
    {
        label: 'Pre-order proxy',
        value: 'order'
    },
    {
        label: 'Bookings',
        value: 'bookings'
    },
    {
        label: 'Orders',
        value: 'orders'
    }
]

export default function BuyApp(props) {
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'order' : mode);
    const [balance, setBalance] = useState(undefined);

    return (
        <ReshakaTemplate active={'buy'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem key={a.value} selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                                CommonHelper.linkTo(`/buy/${a.value}`);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper>
                <Heading>
                    {
                        {
                            order: 'Order proxy',
                            bookings: 'My bookings',
                            sellers: 'Proxy sellers',
                            orders: 'My orders',
                        }[tab]
                    }
                </Heading>

                {tab != 'order' ? null :
                    <Content>
                        <SubHeading>
                            Order proxy
                        </SubHeading>

                    </Content>
                }

                {tab != 'bookings' ? null :
                    <>
                    <UserBookingsPanel/>
                    </>
                }


                {tab != 'search' ? null :
                    <Content>
                        <SubHeading>
                            Find proxy with filters
                        </SubHeading>

                    </Content>
                }

            </Wrapper>

        </ReshakaTemplate>
    );
}

const Wrapper = styled.div`
    padding: 40px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 40px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
`;

