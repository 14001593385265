import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

export default function DogUptimeDayDetailedTool(props) {
    const {id} = props;
    const [map, setMap] = useState({});
    const [loading, setLoading] = useState(false);
    const [dayTimestamp, setDayTimestamp] = useState();
    useEffect(() => {
        setMap({});
        let from = +moment(dayTimestamp).startOf('day');
        let to = +moment(dayTimestamp).endOf('day') + 3600000;
        setLoading(true);
        axios.get(`${API_ENDPOINT}/dog/${id}/uptime/stats`, {
            params: {
                from: from,
                to: to,
                timeAggrMinutes: 10
            }
        }).then(d => d.data.result).then(arr => {
            let newMap = {};
            for (let i in arr) {
                let a = arr[i];
                let x = Math.floor((+a.t - +from) / 600000.0);
                newMap[`r${x}`] = a.status;
            }
            setMap(newMap);
            setLoading(false);
        })
    }, [id, dayTimestamp]);

    let rows = Array.from({length: 6}).map((a, i) => i * (60.0 / 6));
    let hours = Array.from({length: 24}).map((a, i) => i);

    console.log('DogUptimeDayDetailedTool: render: map = ', map);

    return (
        <Wrapper key={`${id}_${dayTimestamp}_${loading ? '2' : '1'}`}>

            <TopPlaceholder>
                <Side onClick={() => {
                    setDayTimestamp(+moment(dayTimestamp).add(-1, 'days').startOf('day'));
                }}>
                    <ArrowImg style={{transform: 'rotate(180deg)'}}
                              src={require('../../../assets/images/chevron_right.png')}/>
                </Side>
                <CurrMonth>
                    {moment(dayTimestamp).format('DD.MM.YYYY')}
                </CurrMonth>
                <Side onClick={() => {
                    setDayTimestamp(+moment(dayTimestamp).add(1, 'days').startOf('day'));
                }}>
                    <ArrowImg src={require('../../../assets/images/chevron_right.png')}/>
                </Side>
            </TopPlaceholder>

            <ContPlaceholder>
                {rows.map((a, i) => {
                    return (
                        <Row key={a}>
                            {hours.map((b, j) => {
                                let t = j * 6 + i;
                                let h = `${j}`.padStart(2, '0');
                                let m = `${i * 10}`.padStart(2, '0');
                                let key = `r${t}`;
                                let st = map[`r${t}`];
                                let color = (st == undefined) ? 'transparent' : perc2color(st * 100.0);
                                return (
                                    <Box key={j} color={color}>
                                        {`${h}:${m}`}
                                    </Box>
                                )
                            })}
                        </Row>
                    )
                })}
            </ContPlaceholder>


        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`;

const CurrMonth = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
`;

const Side = styled.div`
    cursor: pointer;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ArrowImg = styled.img`
    height: 14px;
`;

const ContPlaceholder = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Row = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`;

const Box = styled.div`
    flex: 1;
    background: ${props => props.color};
    box-sizing: border-box;
    font-size: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #D5D5D5;
`;


function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    }
    else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}
