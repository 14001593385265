import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo, Component} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {FixedSizeList as List} from "react-window";

import {Input, Label} from "../../ira/ui/Inputs";
import './style.css'

import Select, {createFilter} from 'react-select'
import {API_ENDPOINT} from "../../../constants/config";
import {BlueButton} from "../../ira/ui/Buttons";
import NiceConfirm from "../../modals/NiceConfirm";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import ToolsAPI from "../../../api/ToolsAPI";

const PROXY_TYPES_OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'HTTP',
        value: 'http'
    }
];

const CHANGE_IP_REQUEST_METHODS_OPTIONS = [
    {
        label: 'GET',
        value: 'GET'
    },
    {
        label: 'POST',
        value: 'POST'
    }
];

export default function UpdateDogForm(props) {
    const {
        loading, onSave = d => {

        },
        canDelete = false,
        onDelete = () => {

        },
        onOpenAccessSettings = () => {

        },
        onOpenUptime = () => {

        },
    } = props;

    const [advancedVisible, setAdvancedVisible] = useState(false);

    const [checkingChangeIp, setCheckingChangeIp] = useState(false);
    const [isWorkingChangeIp, setIsWorkingChangeIp] = useState(false);
    const [changeIpChecked, setChangeIpChecked] = useState(false);

    const [checking, setChecking] = useState(false);
    const [isWorkingProxy, setIsWorkingProxy] = useState(false);
    const [mobileIp, setMobileIp] = useState(undefined);


    const [countriesLoading, setCountriesLoading] = useState(false);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    const [ip, setIp] = useState(props.ip == undefined ? '' : props.ip);
    const [port, setPort] = useState(props.port == undefined ? '' : props.port);
    const [login, setLogin] = useState(props.login == undefined ? '' : props.login);
    const [password, setPassword] = useState(props.password == undefined ? '' : props.password);
    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'socks5' : props.proxyType);

    const [name, setName] = useState(props.name == undefined ? '' : props.name);

    const [changeIpUrl, setChangeIpUrl] = useState(props.changeIpUrl == undefined ? '' : props.changeIpUrl);
    const [changeIpRequestMethod, setChangeIpRequestMethod] = useState(props.changeIpRequestMethod == undefined ? 'GET' : props.changeIpRequestMethod);

    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    const [countryCode, setCountryCode] = useState(props.countryCode == undefined ? 'RU' : props.countryCode);
    const [city, setCity] = useState(props.city == undefined ? 'Moscow' : props.city);

    console.log('render: city = ', city);
    console.log('render: props = ', props);

    useEffect(() => {
        setCountriesLoading(true);
        axios.get(`${API_ENDPOINT}/countries`).then(d => d.data).then(arr => {
            setAllCountries(arr);
            setCountriesLoading(false);
        })
    }, []);

    useEffect(() => {
        setCitiesLoading(true);
        axios.get(`${API_ENDPOINT}/country/${countryCode}/cities`).then(d => d.data.cities).then(arr => {
            console.log('aaarrr = ', arr);
            let sortedArr = arr.filter(a => (a != undefined && a.name_en != undefined)).sort((a, b) => {
                let s1 = a.name_en.toLowerCase();
                let s2 = a.name_en.toLowerCase();
                if (s1 > s2) {
                    return -1;
                }
                if (s1 < s2) {
                    return 1;
                }
                return 0;
            })
            setAllCities(sortedArr);
            setCitiesLoading(false);
        })
    }, [countryCode]);


    const COUNTRIES_OPTIONS = allCountries.map(a => ({value: a.iso, label: a.name_en}));
    const CITIES_OPTIONS = allCities.map(a => ({value: a.name_en, label: a.name_en}));

    if (countriesLoading == true) {
        return (
            <Wrapper>
                <Spin visible={true}/>
            </Wrapper>
        )
    }

    console.log('city = ', city);

    return (
        <Wrapper>


            <Row>

                <Half>
                    <Field>
                        <Label>
                            Страна
                        </Label>
                        <Select options={COUNTRIES_OPTIONS}
                                value={COUNTRIES_OPTIONS.filter(a => (a.value == countryCode))[0]}
                                onChange={a => {
                                    console.log('onSelect: a = ', a);
                                    setCountryCode(a.value);
                                    setCity(undefined);
                                }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Город
                        </Label>
                        <Spin visible={citiesLoading}/>
                        {citiesLoading == true ? null :
                            <Select options={CITIES_OPTIONS}
                                    components={{MenuList}}
                                    filterOption={createFilter({ignoreAccents: false})}
                                    value={CITIES_OPTIONS.filter(a => (a.value == city))[0]}
                                    onChange={a => {
                                        console.log('onSelect: a = ', a);
                                        setCity(a.value);
                                    }}/>
                        }
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Название
                        </Label>
                        <Input value={name}
                               placeholder={'Любое название, как Вам нравится :)'}
                               onChange={evt => {
                                   setName(evt.target.value)
                               }}/>
                    </Field>
                </Half>

            </Row>


            <Row>

                <Half>
                    <Field>
                        <Label>
                            Тип прокси
                        </Label>
                        {isWorkingProxy == true ? proxyType :
                            <Select options={PROXY_TYPES_OPTIONS}
                                    value={PROXY_TYPES_OPTIONS.filter(a => (a.value == proxyType))[0]}
                                    onChange={a => {
                                        setProxyType(a.value);
                                        setMobileIp(undefined);
                                        setIsWorkingProxy(false);
                                    }}/>
                        }
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Логин
                        </Label>
                        {isWorkingProxy == true ? login :
                            <Input value={login}
                                   placeholder={'proxy login'}
                                   onChange={evt => {
                                       setLogin(evt.target.value);
                                       setMobileIp(undefined);
                                       setIsWorkingProxy(false);
                                   }}/>
                        }
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Пароль
                        </Label>
                        {isWorkingProxy == true ? password :
                            <Input value={password}
                                   placeholder={'proxy password'}
                                   onChange={evt => {
                                       setPassword(evt.target.value)
                                       setMobileIp(undefined);
                                       setIsWorkingProxy(false);
                                   }}/>
                        }
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            IP
                        </Label>
                        {isWorkingProxy == true ? ip :
                            <Input value={ip}
                                   placeholder={'proxy IP'}
                                   onChange={evt => {
                                       setIp(evt.target.value)
                                       setMobileIp(undefined);
                                       setIsWorkingProxy(false);
                                   }}/>
                        }
                    </Field>
                </Half>
                <Half>
                    <Field>
                        <Label>
                            Порт
                        </Label>
                        {isWorkingProxy == true ? port :
                            <Input value={port}
                                   placeholder={'proxy port'}
                                   onChange={evt => {
                                       setPort(evt.target.value);
                                       setMobileIp(undefined);
                                       setIsWorkingProxy(false);
                                   }}/>
                        }
                    </Field>
                </Half>

                {isWorkingProxy == true || !isValidIP(ip) ? null :
                    <Half>
                        <Field>
                            <Label>
                                Проверка
                            </Label>
                            <Spin visible={checking}/>
                            {checking == false ? null :
                                <span style={{opacity: 0.5, cursor: 'pointer', fontSize: 10}} onClick={() => {
                                    setChecking(false);
                                }}>
                                отмена
                            </span>
                            }
                            {checking == true ? null :
                                <BlueButton onClick={async () => {
                                    setChecking(true);
                                    setIsWorkingProxy(false);
                                    setMobileIp(undefined);
                                    let mobIp = await ToolsAPI.getProxyIpByDetailsData({
                                        ip: ip,
                                        port: port,
                                        login: login,
                                        password: password,
                                        proxyType: proxyType
                                    });
                                    console.log('mobIp = ', mobIp);
                                    setChecking(false);
                                    setMobileIp(mobIp);
                                    setIsWorkingProxy(ip != undefined);
                                }}>
                                    проверить
                                </BlueButton>
                            }
                        </Field>
                    </Half>
                }


            </Row>

            {isWorkingProxy == false ? null :
                <Row>
                    <div style={{fontWeight: 'bold', fontSize: 18, marginBottom: 15}}>
                        {`Отлично! Прокси онлайн! Мобильный IP - ${mobileIp}`}
                        {isWorkingChangeIp == true ? null :
                            <>
                            <br/>
                            {`Осталось указать ссылку для смены IP`}
                            </>
                        }
                    </div>
                </Row>
            }


            {isWorkingProxy == false || (changeIpChecked == true && isWorkingChangeIp == true) ? null :
                <Row>

                    <Half>
                        <Field>
                            <Label>
                                {/*Change IP URL*/}
                                Ссылка для смены IP
                            </Label>
                            <Input value={changeIpUrl}
                                   placeholder={'Ссылка для смены IP'}
                                   onChange={evt => {
                                       setChangeIpUrl(evt.target.value)
                                   }}/>
                        </Field>
                    </Half>


                    {isWorkingChangeIp == true ? null :
                        <div style={{width: 185, marginLeft: 5}}>
                            <Label>
                                Проверка смены IP
                            </Label>
                            <Spin visible={checkingChangeIp}/>
                            {checkingChangeIp == true ? null :
                                <BlueButton disabled={!validURL(changeIpUrl)} onClick={async () => {
                                    if (!validURL(changeIpUrl)) {
                                        return;
                                    }
                                    setCheckingChangeIp(true);
                                    setIsWorkingChangeIp(false);
                                    setChangeIpChecked(false);
                                    let f = await ToolsAPI.checkIfChangeIpWorking({
                                        ip: ip,
                                        port: port,
                                        login: login,
                                        password: password,
                                        proxyType: proxyType,
                                        changeIpUrl: changeIpUrl,
                                        changeIpRequestMethod: changeIpRequestMethod
                                    });
                                    setCheckingChangeIp(false);
                                    setIsWorkingChangeIp(f);
                                    setChangeIpChecked(true);
                                }}>
                                    проверить
                                </BlueButton>
                            }
                        </div>
                    }

                    {/*<Field>*/}
                    {/*<Label>*/}
                    {/*Change IP request method*/}
                    {/*</Label>*/}
                    {/*<Select options={CHANGE_IP_REQUEST_METHODS_OPTIONS}*/}
                    {/*value={CHANGE_IP_REQUEST_METHODS_OPTIONS.filter(a => (a.value == changeIpRequestMethod))[0]}*/}
                    {/*onChange={a => {*/}
                    {/*setChangeIpRequestMethod(a.value);*/}
                    {/*}}/>*/}
                    {/*</Field>*/}


                </Row>
            }
            {changeIpChecked == false ? null :
                <div style={{marginTop: 5, color: (isWorkingChangeIp ? 'green' : 'red')}}>
                    {isWorkingChangeIp == true ? 'Смена IP по ссылке работает!' : 'Смена IP по ссылке не работает!'}
                </div>
            }


            <BottomSavePlaceholder canDelete={canDelete}>
                <BlueButton style={{width: 200}} disabled={!isWorkingProxy || !isWorkingChangeIp} loading={loading}
                            onClick={() => {
                                if (loading == true || isWorkingProxy == false || isWorkingChangeIp == false) {
                                    return;
                                }
                                onSave({
                                    name,
                                    countryCode,
                                    city,
                                    ip,
                                    port: +port,
                                    login,
                                    password,
                                    proxyType,
                                    changeIpRequestMethod: changeIpRequestMethod,
                                    changeIpUrl: changeIpUrl
                                });
                            }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : 'Сохранить'}
                </BlueButton>

                {canDelete == false ? null :
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <EditSpan style={{marginRight: 15}} onClick={() => {
                            onOpenUptime();
                        }}>
                            Аптайм
                        </EditSpan>
                        <EditSpan onClick={() => {
                            onOpenAccessSettings();
                        }}>
                            Доступы
                        </EditSpan>
                        <DeleteSpan onClick={() => {
                            setConfirmDeleteVisible(true);
                        }}>
                            Удалить прокси
                        </DeleteSpan>
                    </div>
                }


            </BottomSavePlaceholder>

            {confirmDeleteVisible == false ? null :
                <NiceConfirm
                    onClose={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onCancel={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onConfirm={() => {
                        onDelete();
                    }}
                    confirmText={'ДА, УДАЛИТЬ'}
                    cancelText={'Нет, не удалять'}
                    heading={'Удалить прокси'}
                    subHeading={'Вы уверены, что хотите удалить прокси?'}

                />
            }

            {advancedVisible == false ? null :
                <NiceModalWithHeading
                    heading={'Proxy settings'}
                    subHeading={'Advanced settings'}
                    onClose={() => {
                        setAdvancedVisible(false);
                    }}
                >

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const EditSpan = styled.div`
    cursor: pointer;
    color: #8593A8;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    margin-right: 5px;
    :hover{
      opacity: 0.9;
    }
`;

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 20px;
    :hover{
      opacity: 0.9;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    
    padding-left: 5px;
    padding-right: 5px;
    
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const height = 35;

class MenuList extends Component {
    render() {
        const {options, children, maxHeight, getValue} = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

function validURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function isValidIP(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return true;
    }
    return false;
}
