import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Label, Input} from "../../ira/ui/Inputs";
import {RedButton} from "../../ira/ui/Buttons";

import Select from 'react-select'
import {IP_CHANGE_MINUTES} from "../../../constants/config";

const PROXY_TYPES_OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'HTTP',
        value: 'http'
    }
];

export default function UpdateCatForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'socks5' : props.proxyType);
    const [login, setLogin] = useState(props.login == undefined ? '' : props.login);
    const [password, setPassword] = useState(props.password == undefined ? '' : props.password);


    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [ipChangeEnabled, setIpChangeEnabled] = useState(props.ipChangeEnabled == undefined ? false : props.ipChangeEnabled);
    const [ipChangeMinutesInterval, setIpChangeMinutesInterval] = useState(props.ipChangeMinutesInterval == undefined ? 2 : props.ipChangeMinutesInterval);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input placeholder={'Любое название =)'}
                       value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Смена IP по расписанию
                </Label>
                <Select options={IP_CHANGE_MINUTES}
                        isClearable={true}
                        placeholder={'Выберите интервал'}
                        value={ipChangeEnabled != true ? undefined : IP_CHANGE_MINUTES.filter(a => (a.value == ipChangeMinutesInterval))[0]}
                        onChange={async a => {
                            if (a == undefined) {
                                setIpChangeEnabled(false);
                                return;
                            }
                            setIpChangeEnabled(true);
                            setIpChangeMinutesInterval(a.value);
                        }}
                />
            </Field>

            <Row>
                <Half>
                    <Field>
                        <Label>
                            Тип прокси
                        </Label>
                        <Select options={PROXY_TYPES_OPTIONS}
                                value={PROXY_TYPES_OPTIONS.filter(a => (a.value == proxyType))[0]}
                                onChange={a => {
                                    setProxyType(a.value);
                                }}/>
                    </Field>
                </Half>
                <Half>
                    <Field>
                        <Label>
                            Логин
                        </Label>
                        <Input value={login} placeholder={'Логин прокси'} onChange={evt => {
                            setLogin(evt.target.value.trim());
                        }}/>
                    </Field>
                </Half>
                <Half>
                    <Field>
                        <Label>
                            Пароль
                        </Label>
                        <Input value={password} placeholder={'Пароль прокси'} onChange={evt => {
                            setPassword(evt.target.value.trim());
                        }}/>
                    </Field>
                </Half>
            </Row>


            <BottomPlaceholder>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <RedButton onClick={() => {
                        onSave({
                            name,
                            ipChangeEnabled,
                            ipChangeMinutesInterval,
                            login,
                            password,
                            proxyType
                        });
                    }}>
                        Save
                    </RedButton>
                }
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
`;
