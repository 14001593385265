import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import flag from 'country-code-emoji';

import * as dogsActions from '../../../redux/actions/DogsActions'
import * as snakesActions from '../../../redux/actions/SnakesActions'
import {RedButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateDogForm from "../forms/UpdateDogForm";
import DogIpInfoView from "../tools/DogIpInfoView";
import DogAccessForm from "../forms/DogAccessForm";
import DogSnakesPanel from "./DogSnakesPanel";
import IproxyConnectionsPanel from "../../iproxy/panels/IproxyConnectionsPanel";
import DogUptimeTool from "../tools/DogUptimeTool";
import DogUptimeDayDetailedTool from "../tools/DogUptimeDayDetailedTool";

const renderDogs = (dogs = [], onSelect = aId => {

}) => {
    return dogs.map((a, i) => {
        let {countryCode, ip, port, city, name, proxyType, login, password, snakes = []} = a;
        let label = (name == undefined) ? `${countryCode}, ${city}` : name;
        return (
            <Box key={a.id} onClick={() => {
                onSelect(a.id);
            }}>
                <BoxTop>
                    <BoxName>
                        {label}
                    </BoxName>
                    {countryCode == undefined ? null :
                        <BigFlagPlaceholder>
                            {flag(countryCode)}
                        </BigFlagPlaceholder>
                    }
                </BoxTop>
                <BoxMiddle>
                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/worldwide.svg')}/>
                        <BoxRowName>
                            {`${countryCode}, ${city}`}
                        </BoxRowName>
                    </BoxRow>
                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/plug_d.svg')}/>
                        <BoxRowName style={{fontSize: 12}}>
                            {`${proxyType}://${login}:${password}@${ip}:${port}`}
                        </BoxRowName>
                    </BoxRow>

                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/info_d.svg')}/>
                        <BoxRowName style={{fontSize: 12}}>
                            <DogIpInfoView {...a} />
                        </BoxRowName>
                    </BoxRow>

                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/info_d.svg')}/>
                        <BoxRowName style={{fontSize: 12}}>
                            {`Используется: `}
                            <b>
                                {`${snakes.length}`}
                            </b>
                        </BoxRowName>
                    </BoxRow>
                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/uptime_1.svg')} />
                        <BoxRowName style={{fontSize: 12, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <span style={{marginRight: 5}} >
                                {`Uptime: `}
                            </span>
                            <DogUptimeTool id={a.id}/>
                        </BoxRowName>
                    </BoxRow>

                </BoxMiddle>
            </Box>
        )
    })
}

export default function UserDogsPanel(props) {
    const {userId} = props;
    const [refreshing, setRefreshing] = useState(false);

    const [addMode, setAddMode] = useState('manual');
    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [accessVisible, setAccessVisible] = useState(false);
    const [uptimeVisible, setUptimeVisible] = useState(false);

    const now = +new Date();

    const {loading, usersMap, uId, dogs, isMe, getDogActiveSnakes, allSnakes} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            allSnakes: state.snakes.snakesMap.toArray(),
            loading: state.users.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            dogs: state.dogs.dogsMap.toArray().filter(a => (a.userId == uId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            isMe: uId == state.users.currentUserId,
            getDogActiveSnakes: dogId => state.snakes.snakesMap.toArray().filter(a => (a.dogId == dogId && (+a.fromTimestamp < +now && +a.toTimestamp > +now)))
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dogsActions.loadUserDogs(uId));
        // dispatch(snakesActions.loadAllSnakes()); // todo: optimize
        dispatch(snakesActions.loadUserSnakes(uId)); // todo: optimize
    }, [uId]);

    let selectedDog = (selectedId == undefined) ? undefined : dogs.filter(a => (a.id == selectedId))[0];

    // console.log('render: allSnakes = ', allSnakes);
    // console.log('render: dogs = ', dogs);
    let enhDogs = dogs.map(a => ({...a, snakes: getDogActiveSnakes(a.id)}));
    let freeDogs = enhDogs.filter(a => (a.snakes != undefined && a.snakes.length == 0));
    let busyDogs = enhDogs.filter(a => (a.snakes != undefined && a.snakes.length > 0));

    return (
        <Wrapper>

            {dogs.length == 0 ?
                <div>
                    <P>
                        Здесь вы можете добавлять свои прокси-доступы.
                        {/*Here you can add proxies for sale. We support both HTTP and socks5 proxies types.*/}
                    </P>

                    <P>
                        Добавьте первый прокси, чтобы начать зарабатывать!
                        {/*Add first proxy to start making money with proxy.broker!*/}
                    </P>

                    <div style={{display: 'inline-block'}}>
                        <RedButton onClick={() => {
                            setAddVisible(true);
                            setAddMode('manual');
                        }}>
                            Добавить первый прокси
                        </RedButton>
                    </div>
                </div>
                :
                <TopPlaceholder>
                    <Heading>
                        {`У Вас ${dogs.length} прокси`}
                    </Heading>

                    <RedButton onClick={() => {
                        setAddVisible(true);
                        setAddMode('manual');
                    }}>
                        Добавить прокси
                    </RedButton>

                </TopPlaceholder>
            }

            {isMe == false ? null :
                <>

                {freeDogs.length == 0 ? null :
                    <div>
                        <h3>
                            {`Свободные прокси (${freeDogs.length})`}
                        </h3>
                        <ListPlaceholder>
                            {renderDogs(freeDogs, aId => {
                                setSelectedId(aId);
                            })}
                        </ListPlaceholder>
                    </div>
                }


                {busyDogs.length == 0 ? null :
                    <div>
                        <h3>
                            {`Занятые прокси (${busyDogs.length})`}
                        </h3>
                        <ListPlaceholder>
                            {renderDogs(busyDogs, aId => {
                                setSelectedId(aId);
                            })}
                        </ListPlaceholder>
                    </div>
                }

                </>
            }

            {addVisible == false ? null :
                <NiceModalWithHeading
                    width={800}
                    heading={'Добавить новый прокси'}
                    subHeading={(
                        <div>
                            Укажите необходимую информацию для подключения. Эти данные видны только вам!
                            <br/>
                            <span
                                style={{
                                    fontSize: 12,
                                    marginTop: 10,
                                    opacity: 0.6,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setAddMode({manual: 'iproxy', iproxy: 'manual'}[addMode]);
                                }}
                            >
                                {addMode == 'manual' ? 'Импортировать из iProxy' : 'Ввести данные вручную'}
                            </span>
                        </div>
                    )}
                    onClose={() => {
                        setAddVisible(false);
                        setAddMode('manual');
                    }}>

                    <ModalInnerPlaceholder key={addMode}>
                        {addMode == 'manual' ?
                            <UpdateDogForm loading={loading} onSave={async d => {
                                console.log('onSave occured');
                                await dispatch(dogsActions.createDog({...d, userId: uId}));
                                setAddVisible(false);
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 500)
                            }}/>
                            :
                            <div style={{height: '70vh', maxHeight: '70vh', overflowY: 'auto'}}>
                                <IproxyConnectionsPanel loading={loading} onProxySelect={async d => {
                                    console.log('d = ', d);
                                    let pld = await dispatch(dogsActions.createDog({
                                        ...d,
                                        userId: uId,
                                        proxyType: d.type,
                                        changeIpRequestMethod: 'GET',
                                        countryCode: 'RU',
                                        city: 'Moscow'
                                    }));
                                    setAddVisible(false);
                                    setSelectedId(pld.dog.id);
                                }}
                                />
                            </div>
                        }
                    </ModalInnerPlaceholder>


                </NiceModalWithHeading>
            }


            {selectedDog == undefined ? null :
                <NiceModalWithHeading
                    width={800}
                    closeOnClickOutside={false}
                    heading={'Обновить прокси'}
                    subHeading={'Укажите необходимую информацию для подключения. Эти данные видны только вам!'}
                    onClose={() => {
                        setSelectedId(undefined);
                        setAccessVisible(false);
                    }}>

                    <ModalInnerPlaceholder>
                        <UpdateDogForm {...selectedDog}
                                       canDelete={true}
                                       loading={loading}
                                       onSave={async d => {
                                           console.log('onSave occured');
                                           await dispatch(dogsActions.updateDog({id: selectedDog.id, ...d}))
                                           setSelectedId(undefined);
                                       }}
                                       onDelete={async () => {
                                           await dispatch(dogsActions.deleteDog(selectedDog.id));
                                           setSelectedId(undefined);
                                       }}
                                       onOpenAccessSettings={() => {
                                           setAccessVisible(true);
                                       }}
                                       onOpenUptime={() => {
                                           setUptimeVisible(true);
                                       }}

                        />
                    </ModalInnerPlaceholder>

                </NiceModalWithHeading>
            }

            {(accessVisible == false || selectedDog == undefined) ? null :
                <NiceModalWithHeading width={820}
                                      heading={'Доступ к прокси'}
                                      subHeading={''}
                                      onClose={() => {
                                          setAccessVisible(false);
                                      }}>

                    <DogSnakesPanel dogId={selectedDog.id}/>
                    {/*<DogAccessForm  />*/}

                </NiceModalWithHeading>
            }

            {(uptimeVisible == false || selectedDog == undefined) ? null :
                <NiceModalWithHeading width={820}
                                      heading={'Статистика работы прокси'}
                                      subHeading={''}
                                      onClose={() => {
                                          setUptimeVisible(false);
                                      }}>

                    <div style={{height: 300, width: '100%'}} >
                        <DogUptimeDayDetailedTool id={selectedDog.id} />
                    </div>
                    {/*<DogAccessForm  />*/}

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;


const ModalInnerPlaceholder = styled.div`
    width: 100%;
    min-height: 200px;
`;

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;


const ListPlaceholder = styled.div`
    //display: flex;
    //flex-wrap: wrap;
`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    margin-bottom: 30px;
    margin-top: 0px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    :hover{
      opacity: 0.8;
    }
    
    
`;
