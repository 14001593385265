import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateProductForm from "../forms/UpdateProductForm";

import * as actions from '../../../redux/actions/ProductsActions'
import {RedButton} from "../../ira/ui/Buttons";
import CommonHelper from "../../../helpers/CommonHelper";
import NotificationsBubble from "../../notifications/panels/NotificationsBubble";

export default function UserProductsPanel(props) {
    const {userId} = props;
    const {currentUser, order, loading, isMe, products, getUserDogs, currentUserId, allOrders, isMyOrder, uId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let uId = (userId == undefined) ? currentUserId : userId;
        console.log('uId = ', uId);
        let products = state.products.productsMap.toArray().filter(a => (a.userId == uId));
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            products: products,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            uId: uId,
            loading: state.products.loading
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadUserProducts(uId));
    }, [uId]);

    const [selectedId, setSelectedId] = useState();
    const [addVisible, setAddVisible] = useState(false);

    const selectedProduct = (selectedId == undefined) ? undefined : products.filter(a => (a.id == selectedId))[0];

    console.log('products = ', products);

    return (
        <Wrapper>


            <p>
                {/*Добавьте ваше предложение рынку, чтобы Вас смог найти покупатель. Оно будет отображаться в поиске у*/}
                {/*покупателей.*/}
                Расскажите, какие прокси вы продаёте. Ваши предложения будут видны покупателям
            </p>

            <TopPlaceholder>
                <RedButton onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить предложение
                </RedButton>
            </TopPlaceholder>

            <ListPlaceholder>
                {products.map((a, i) => {
                    let {countryCode, city, name, operatorsText = '', pricePerWeek, pricePerDay, pricePerMonth} = a;
                    let label = (name == undefined) ? `${countryCode}, ${city}` : name;
                    return (
                        <Box key={a.id} onClick={() => {
                            CommonHelper.linkTo(`/product/${a.id}`);
                            // setSelectedId(a.id);

                        }}>
                            <BoxTop>
                                <BoxName>
                                    <NotificationsBubble
                                        style={{display: 'inline-flex', verticalAlign: 'top', marginRight: 5}}
                                        types={['new_incoming_deal']}
                                        referenceIds={[`${a.id}`]}/>
                                    <span>
                                        {label}
                                    </span>
                                </BoxName>
                                {countryCode == undefined ? null :
                                    <BigFlagPlaceholder>
                                        {flag(countryCode)}
                                    </BigFlagPlaceholder>
                                }
                            </BoxTop>
                            <BoxMiddle>
                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/worldwide.svg')}/>
                                    <BoxRowName>
                                        {`${countryCode}, ${city}`}
                                    </BoxRowName>
                                </BoxRow>

                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/pro_money.svg')}/>
                                    <BoxRowName>
                                        {`1д - ${pricePerDay}$, 7д - ${pricePerWeek}$, 30д - ${pricePerMonth}$,`}
                                    </BoxRowName>
                                </BoxRow>

                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/info_svg_.svg')}/>
                                    <BoxRowName>
                                        {operatorsText}
                                    </BoxRowName>
                                </BoxRow>

                            </BoxMiddle>
                        </Box>
                    )
                })}
            </ListPlaceholder>

            {selectedProduct == undefined ? null :
                <NiceModalWithHeading width={820}
                                      heading={'Редактировать услугу'}
                                      subHeading={''}
                                      onClose={() => {
                                          setSelectedId(undefined);
                                      }}>

                    <UpdateProductForm {...selectedProduct}
                                       loading={loading}
                                       canDelete={true}
                                       onSave={async d => {
                                           await dispatch(actions.updateProduct({...d, id: selectedProduct.id}));
                                           setSelectedId(undefined);
                                       }}
                                       onDelete={async () => {
                                           await dispatch(actions.deleteProduct(selectedProduct.id));
                                           setSelectedId(undefined);
                                       }}

                    />

                </NiceModalWithHeading>
            }

            {addVisible == false ? null :
                <NiceModalWithHeading heading={'Добавление услуги'}
                                      width={820}
                                      subHeading={'Введите информацию о продаваемой прокси'}
                                      onClose={() => {
                                          setAddVisible(false);
                                      }}>

                    <UpdateProductForm
                        loading={loading}
                        onSave={async d => {
                            await dispatch(actions.createProduct({...d, userId: uId}));
                            setAddVisible(false);
                        }}

                    />

                </NiceModalWithHeading>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;


const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;


const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    margin-bottom: 30px;
    margin-top: 0px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    :hover{
      opacity: 0.8;
    }
    
`;


const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;

