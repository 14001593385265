import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    testimonialsMap: Map(),
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TestimonialsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TESTIMONIAL:
        case types.UPDATE_TESTIMONIAL:
        case types.DELETE_TESTIMONIAL:
        case types.LOAD_TESTIMONIALS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TESTIMONIAL_FAIL:
        case types.UPDATE_TESTIMONIAL_FAIL:
        case types.DELETE_TESTIMONIAL_FAIL:
        case types.LOAD_TESTIMONIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TESTIMONIAL_SUCCESS:
        case types.UPDATE_TESTIMONIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                testimonialsMap: state.testimonialsMap.set(action.testimonial.id, action.testimonial)
            }


        case types.DELETE_TESTIMONIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                testimonialsMap: state.testimonialsMap.delete(action.id),
            }


        case types.LOAD_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                testimonialsMap: state.testimonialsMap
                    .merge(action.testimonials.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TestimonialsReducer;
