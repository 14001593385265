import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

import FirebaseAPI from './FirebaseAPI'


const ChatAPI = {

    async getRoomInfo(roomId, withMessages = false) {
        let params = (withMessages == true) ? {withMessages: 1} : {};
        let pld = (await axios.get(`${API_ENDPOINT}/chat/room/${roomId}`, {
            params: params
        })).data;
        console.log('getRoomInfo: pld = ', pld);
        return pld;
    },

    subscribeToRoomMessages(roomId, onMessage = msg => {

    }) {
        FirebaseAPI.subscribeOnRoomChat(roomId, () => {
            let from = +new Date() - 30000;
            axios.get(`${API_ENDPOINT}/chat/room/${roomId}/messages/since/${from}`).then(d => d.data).then(msgs => {
                console.log(`/messages/since/${from}: msgs = `, msgs);
                let arr = msgs.map(msg => ({
                    ...msg,
                    t: msg.timestamp,
                    roomId: roomId
                }));
                onMessage(arr);
            })
        }, {});
    },

    async getRoomUsersMap(room) {
        let map = (await axios.get(`${API_ENDPOINT}/chat/room/${room}/users_map`)).data;
        return map;
    },

    cancelSubscription(roomId) {
        // try {
        //     this.chatkitUser.roomSubscriptions[roomId].cancel()
        // } catch (e) {
        //     console.log('can not unsubscribe from room: e = ', e);
        // }
    },

    async sendMultipartMessage(roomId, parts = []) {
        try {
            let pld = this.chatkitUser.sendMultipartMessage({
                roomId: roomId,
                parts: parts
            });
            return pld;
        } catch (e) {
            console.log('can not send message: e = ', e);
        }
    },


    async sendTextMessage(room, text) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/chat/room/${room}`, {
                content: text
            })).data;
            await FirebaseAPI.updateRoomChatData(room, {});
            return pld;
        } catch (e) {
            console.log('can not send message: e = ', e);
        }
    },

    // async sendTextMessage(roomId, text) {
    //     try {
    //         let pld = this.chatkitUser.sendMultipartMessage({
    //             roomId: roomId,
    //             parts: [{type: 'text/plain', content: text}]
    //         });
    //         return pld;
    //     } catch (e) {
    //         console.log('can not send message: e = ', e);
    //     }
    // },


}

export default ChatAPI;
