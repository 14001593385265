import axios from 'axios'
import moment from 'moment'
import {API_ENDPOINT} from "../constants/config";

const DogsAPI = {

    getUserDogs(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/dogs`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAllDogs() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dogs/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getDog(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dog/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createDog(data) {
        return new Promise((resolve, reject) => {
            if (data.name == undefined) {
                data.name = `proxy ${moment().format('DD.MM HH:mm:ss')}`;
            }
            axios.post(`${API_ENDPOINT}/dog`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err.message));
        });
    },

    updateDog(data) {
        console.log('upd dog! data = ', data);
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/dog`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err.message));
        });
    },

    deleteDog(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/dog`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default DogsAPI;
