import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import ReshakaTemplate from "../templates/ReshakaTemplate";
import AllOrdersPanel from "../orders/panels/AllOrdersPanel";
import SearchProductsPanel from "../products/panels/SearchProductsPanel";
import CommonHelper from "../../helpers/CommonHelper";
import {RedButton} from "../ira/ui/Buttons";

export default function BuyerIndexApp(props) {
    let {mode} = props.match.params;
    const {currentUserId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        return {
            currentUserId: state.users.currentUserId,
        }
    }, []));
    const dispatch = useDispatch();

    return (
        <ReshakaTemplate active={'index'}>



            <Wrapper>

                <SearchProductsPanel/>

                <NotFoundPropProductPlaceholder>
                    <PropsNotFMsg>
                        Не нашли нужный прокси? Подайте объявление и Вам отпишутся подходящие продавцы!
                    </PropsNotFMsg>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
                        <RedButton onClick={() => {
                            CommonHelper.linkTo(`/orders`);
                            // setTab('orders');
                            // setOpenOrderModalByDefault(true);
                            // setUpdT(+new Date());
                        }}>
                            ПОДАТЬ ОБЪЯВЛЕНИЕ
                        </RedButton>
                    </div>

                </NotFoundPropProductPlaceholder>

            </Wrapper>

        </ReshakaTemplate>
    );
}

const NotFoundPropProductPlaceholder = styled.div`
    padding: 10px;
    text-align: center;
`;

const PropsNotFMsg = styled.div`
    
`;

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 1200px){
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const topHeight = 70;

