import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";

import * as usersActions from '../../../redux/actions/UsersActions'
import * as actions from '../../../redux/actions/ProductsActions'
import * as dealsActions from '../../../redux/actions/DealsActions'
import {BlueButton, RedButton} from "../../ira/ui/Buttons";
import UpdateDealForm from "../forms/UpdateDealForm";
import TwoPeopleChatPanel from "../../chat/panels/TwoPeopleChatPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import NotificationsBubble from "../../notifications/panels/NotificationsBubble";
import ViewNotificationButton from "../../notifications/panels/ViewNotificationButton";

const voidAva = require('../../../assets/images/vo_ava.jpg');

export default function ProductDealsPanel(props) {
    const {productId} = props;
    const {
        currentUser, myTelegram, order, loading,
        isMyProduct,
        isMe, deals, getUserDogs, currentUserId, products, allOrders, isMyOrder, uId
    } = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let deals = state.deals.dealsMap.toArray().filter(a => (a.productId == productId));
        deals = deals.map(a => ({
            ...a,
            product: state.products.productsMap.get(a.productId)
        })).filter(a => (a.product != undefined)).map(a => ({
            ...a,
            buyer: state.users.usersMap.get(a.buyerId)
        })).filter(a => (a.buyer != undefined));
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let product = state.products.productsMap.get(productId);
        let isMyProduct = (product != undefined) && (currentUserId == product.userId);
        if (isMyProduct == false) {
            deals = deals.filter(a => (a.buyerId == currentUserId))
        }
        return {
            deals: deals,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            loading: state.products.loading || state.deals.loading,
            myTelegram: currentUser == undefined ? '' : currentUser.telegram,
            isMyProduct: isMyProduct
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadAllProducts());
        dispatch(dealsActions.loadProductDeals(productId));
        dispatch(dealsActions.loadUserDeals(currentUserId));
        setTimeout(() => {
            dispatch(usersActions.loadUserById(currentUserId))
        }, 400);
    }, [uId]);

    const [selectedId, setSelectedId] = useState();
    const [addVisible, setAddVisible] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);

    const selectedDeal = (selectedId == undefined) ? undefined : deals.filter(a => (a.id == selectedId))[0];

    console.log('deals = ', deals);
    console.log('currentUser = ', currentUser);

    return (
        <Wrapper>

            {isMyProduct == false ? null :
                <h3>
                    {deals.length > 0 ? 'Заявки на покупки Вашей услуги' :
                        'На ваше предложение еще не поступало заявок'
                    }
                </h3>
            }

            <ListPlaceholder>
                {deals.map((a, i) => {
                    let {product} = a;
                    let u = a.buyer;
                    return (
                        <UserItem key={a.id} onClick={() => {
                            // setSelectedId(a.id);
                        }}>
                            <User>
                                <ProfilePic pic={u.avatar}/>
                                <UserData>
                                    <UserName onClick={() => {
                                        if (u != undefined) {
                                            CommonHelper.linkTo(`/user/${u.id}`);
                                        }
                                    }}>
                                        {isMyProduct == false ? null :
                                            <NotificationsBubble
                                                style={{display: 'inline-flex', verticalAlign: 'top', marginRight: 5}}
                                                types={['new_incoming_deal']}
                                                referenceIds={[`${a.id}`]}/>
                                        }
                                        {CommonHelper.getUserName(u)}
                                    </UserName>

                                    <div style={{fontSize: 12, opacity: 0.7}}>
                                        {`Telegram:`}
                                        {u.telegram == undefined ? 'Не указан' :
                                            <TLink href={`https://t.me/${u.telegram.replace('@', '')}`}
                                                   target={'_blank'}>
                                                {`@${u.telegram.replace('@', '')}`}
                                            </TLink>
                                        }
                                        <span style={{marginLeft: 5}}>
                                            {`ID: ${u.id}`}
                                        </span>
                                        <span style={{marginLeft: 5}}>
                                            {`DATE: ${moment(a.timestamp).format('DD.MM.YYYY HH:mm:ss')}`}
                                        </span>
                                    </div>

                                    <DealText>
                                        {a.text}
                                    </DealText>

                                </UserData>
                            </User>

                            <InlineControlsPlaceholder>
                                <ViewNotificationButton
                                    style={{display: 'inline-flex', verticalAlign: 'top', marginRight: 5}}
                                    types={['new_incoming_deal']}
                                    referenceIds={[`${a.id}`]}
                                />
                            </InlineControlsPlaceholder>

                        </UserItem>
                    )
                })}
            </ListPlaceholder>

            {/*{selectedDeal == undefined ? null :*/}
            {/*<NiceModalWithHeading width={820}*/}
            {/*heading={'просмотр предложения'}*/}
            {/*subHeading={''}*/}
            {/*onClose={() => {*/}
            {/*setSelectedId(undefined);*/}
            {/*}}>*/}


            {/*тут можно будет сразу дать покупателю прокси или удалить данную заявку*/}

            {/*</NiceModalWithHeading>*/}
            {/*}*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TLink = styled.a`
    text-decoration: underline;
    color: black;
    opacity: 0.7;
    margin-left: 5px;
    margin-right: 5px;
    :hover{
      opacity: 1;
    }
`;

const InlineControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
`;

const DealText = styled.div`
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
`;


const UserItem = styled.div`
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 30px;
    //cursor: pointer;
    //:hover{
    //  opacity: 0.8;
    //}
`;

const UserName = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #202224;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`;

const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MainInfoItem = styled.div`
  font-size: 36px;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  
  :before {
      content: 'from';
      display: block;
      font-size: 18px;
      margin-right: 10px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const DescriptionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
`;


const DescriptionContent = styled.div`
  line-height: 1.8em;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 20px;
  text-align: justify;
  font-size: 14px;
`;

const StatsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  text-align: center;
`;

const Stats = styled.div`
  background-color: #fbfbfb;
  width: 220px;
  box-sizing: content-box;
  padding: 20px;
`;

const StatsList = styled.div`

`;

const StatsItem = styled.div`
  margin: 10px 0;
  
  span {
    font-weight: bold;
  }
  :hover{
    text-decoration: ${props => (props.canBook == true ? 'underline' : 'none')};
  }
`;


const AchivesList = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
`;

const Achive = styled.img`
  //background-color: red;
  width: 22px;
  height: 26px;
  margin: 0 3px;
`;

const ProfilePic = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f5f2f2;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-image: url(${props => (props.pic == undefined ? voidAva : props.pic)});
`;


const User = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserData = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 15px;
  flex: 1;
  margin: 10px 0;
`;


const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
`;


const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;


const ListPlaceholder = styled.div`

`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 10px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 6px;
    :hover{
      opacity: 0.8;
    }
    
   
    
`;


const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;

