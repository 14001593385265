import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateProfilePanel from "../users/panels/UpdateProfilePanel";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import UserProfileBalancePanel from "../users/panels/UserProfileBalancePanel";
import UserBookingsPanel from "../bookings/panels/UserBookingsPanel";
import UserCatsPanel from "../cats/panels/UserCatsPanel";
import SearchProviderPanel from "../providers/panels/SearchProviderPanel";
import UserOrdersPanel from "../orders/panels/UserOrdersPanel";
import {RedButton} from "../ira/ui/Buttons";
import SearchProductsPanel from "../products/panels/SearchProductsPanel";
import NotificationsBubble from "../notifications/panels/NotificationsBubble";
import ReshakaTemplate from "../templates/ReshakaTemplate";

const LabRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CircSpan = styled.div`
    margin-left: 5px;
    font-weight: bold;
`;

const TABS = [
    {
        label: 'Найти продавца',
        value: 'index',
    },
    {
        label: (
            <LabRow>
                <span>
                    Мои прокси
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_cat']}/>
                </CircSpan>
            </LabRow>
        ),
        value: 'proxies'
    },
    // {
    //     label: 'Мои бронирования',
    //     value: 'bookings'
    // },
    {
        label: (
            <LabRow>
                <span>
                    Мои заказы
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_order_proposal']}/>
                </CircSpan>
            </LabRow>
        ),
        value: 'orders'
    }
]

const isMyProxies = (window.location.hash.indexOf('/proxies') > -1);
const isBookings = (window.location.hash.indexOf('/bookings') > -1);
const isOrders = (window.location.hash.indexOf('/orders') > -1);

export default function BuyApp(props) {
    let {mode} = props.match.params;
    // const [tab, setTab] = useState(mode == undefined ? 'index' : mode);
    const [tab, setTab] = useState(isBookings ? 'bookings' : (isMyProxies ? 'proxies' : (isOrders ? 'orders' : 'index')));

    const [openOrderModalByDefault, setOpenOrderModalByDefault] = useState(false);
    const [updT, setUpdT] = useState(0);

    return (
        <ReshakaTemplate active={'index'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                                if (a.value == 'index') {
                                    CommonHelper.linkTo(`/`);
                                } else {
                                    CommonHelper.linkTo(`/${a.value}`);
                                }
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>
                    <RedButton onClick={() => {
                        CommonHelper.linkTo(`/orders`);
                        setTab('orders');
                        setOpenOrderModalByDefault(true);
                        setUpdT(+new Date());
                    }}>
                        ЗАКАЗАТЬ ПРОКСИ
                    </RedButton>
                </TopRight>
            </HorHeading>

            <Wrapper>
                {/*<Heading>*/}
                {/*{*/}
                {/*{*/}
                {/*order: 'Order proxy',*/}
                {/*bookings: 'Bookings',*/}
                {/*sellers: 'Proxy sellers',*/}
                {/*orders: 'My orders',*/}
                {/*}[tab]*/}
                {/*}*/}
                {/*</Heading>*/}

                {tab != 'index' ? null :
                    <>
                    <SearchProductsPanel/>

                    <NotFoundPropProductPlaceholder>
                        <PropsNotFMsg>
                            Не нашли нужный прокси? Сделайте заказ и Вам отпишутся подходящие продавцы!
                        </PropsNotFMsg>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
                            <RedButton onClick={() => {
                                CommonHelper.linkTo(`/orders`);
                                setTab('orders');
                                setOpenOrderModalByDefault(true);
                                setUpdT(+new Date());
                            }}>
                                ЗАКАЗАТЬ ПРОКСИ
                            </RedButton>
                        </div>

                    </NotFoundPropProductPlaceholder>
                    {/*<SearchProviderPanel/>*/}
                    </>
                }

                {tab != 'proxies' ? null :
                    <>
                    <UserCatsPanel/>
                    </>
                }

                {tab != 'bookings' ? null :
                    <>
                    <UserBookingsPanel mode={'outgoing'}/>
                    </>
                }

                {tab != 'orders' ? null :
                    <React.Fragment key={`r_${openOrderModalByDefault}_${updT}`}>
                        <UserOrdersPanel openModal={openOrderModalByDefault}/>
                    </React.Fragment>
                }

            </Wrapper>

        </ReshakaTemplate>
    );
}



const NotFoundPropProductPlaceholder = styled.div`
    padding: 10px;
    text-align: center;
`;

const PropsNotFMsg = styled.div`
    
`;

const Wrapper = styled.div`
    padding: 40px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 10px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
`;

