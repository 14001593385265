import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {FACELESS_AVATAR} from "../../../constants/config";


export default function UserTelegramPanel(props) {
    const {currentUser} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            loading: state.users.loading,
            currentUser: currentUser
        }
    }, []));
    const dispatch = useDispatch();

    if (currentUser == undefined) {
        return null;
    }

    console.log('template: render: currentUser = ', currentUser);

    const {telegramChatIds} = currentUser;
    let hasTelegram = (telegramChatIds != undefined && telegramChatIds.length > 0);

    return (
        <Wrapper>

            {hasTelegram == true ? <PText>
                    <p>
                        Отлично! Вы авторизованы в телеграм-боте!
                    </p>
                </PText> :
                <PText>
                    <p>
                        <b>Обязательно</b> авторизуйтесь в телеграм-боте, чтобы получать уведомления {' '}
                        <TeleLink href={`https://telegram.me/proxy_broker_bot`} target={'_blank'}>
                            @proxy_broker_bot
                        </TeleLink>
                    </p>
                </PText>
            }

            <PText>

                <p>
                    Если у вас есть любые вопросы по платформе - пишите в группе поддержки {' '}
                    <TeleLink href={`https://t.me/joinchat/BBtyyxiyPYbpZpLWBZvf1Q`} target={'_blank'}>
                        Proxy.Broker - Support [RUSSIAN]
                    </TeleLink>
                </p>

                <p>
                    Для подтверждения статуса доверенного продавца прокси и доступа в <b>закрытую группу
                    поставщиков</b> напишите в телеграм {' '}
                    <TeleLink href={`https://telegram.me/smoguvse`} target={'_blank'}>
                        @smoguvse
                    </TeleLink>
                </p>

            </PText>

        </Wrapper>
    );
}

const PText = styled.div`
    
`;

const TeleLink = styled.a`
    color: #F07967;
    font-weight: bold;
`;

const Wrapper = styled.div`
  //display: flex;
  //flex-direction: row;
  //justify-content: space-between;
`;
