import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';

import OrderApp from "./OrderApp";
import SettingsApp from "./SettingsApp";
import SellApp from "./SellApp";
import BuyApp from "./BuyApp";
import UserApp from "./UserApp";
import TelegramApp from "./TelegramApp";
import ProductApp from "./ProductApp";

import FullPagePreloader from '../preloader/FullPagePreloader'

import * as usersActions from '../../redux/actions/UsersActions'
import * as bookingsActions from '../../redux/actions/BookingsActions'
import * as ordersActions from '../../redux/actions/OrdersActions'
import * as bidsActions from '../../redux/actions/BidsActions'
import * as productsActions from '../../redux/actions/ProductsActions'
import * as dealsActions from '../../redux/actions/DealsActions'
import * as testimonialsActions from '../../redux/actions/TestimonialsActions'
import * as notificationsActions from '../../redux/actions/NotificationsActions'

import DevApp from "./DevApp";
import IndexApp from "./IndexApp";
import IraLoginApp from "./IraLoginApp";
import NotificationsDaemon from "../notifications/panels/NotificationsDaemon";
import SellerIndexApp from "./SellerIndexApp";
import SellerProductsApp from "./SellerProductsApp";
import SellerProxiesApp from "./SellerProxiesApp";

import BuyerIndexApp from "./BuyerIndexApp";
import BuyerOrdersApp from "./BuyerOrdersApp";
import BuyerProxiesApp from "./BuyerProxiesApp";

// const userRoute = (
//     <Switch>
//         <Route exact path='/telegram' component={TelegramApp}/>
//         <Route exact path='/dev' component={DevApp}/>
//         <Route exact path='/settings' component={SettingsApp}/>
//         <Route exact path='/settings/:mode' component={SettingsApp}/>
//
//         <Route exact path='/user/:id' component={UserApp}/>
//         <Route exact path='/order/:id' component={OrderApp}/>
//         <Route exact path='/product/:id' component={ProductApp}/>
//
//         <Route exact path='/sell' component={SellApp}/>
//         <Route exact path='/sell/:mode' component={SellApp}/>
//
//         <Route exact path='/buy' component={BuyApp}/>
//         <Route exact path='/buy/:mode' component={BuyApp}/>
//
//         <Route exact path='/proxies' component={IndexApp}/>
//         <Route component={IndexApp}/>
//     </Switch>
// );

const sellerRoute = (
    <Switch>
        <Route exact path='/telegram' component={TelegramApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/settings' component={SettingsApp}/>
        <Route exact path='/settings/:mode' component={SettingsApp}/>

        <Route exact path='/user/:id' component={UserApp}/>
        <Route exact path='/order/:id' component={OrderApp}/>
        <Route exact path='/product/:id' component={ProductApp}/>

        <Route exact path='/' component={SellerIndexApp}/>
        <Route exact path='/sell/:mode' component={SellApp}/>

        <Route exact path='/proxy' component={SellerProxiesApp}/>
        <Route exact path='/products' component={SellerProductsApp}/>
        <Route component={SellerIndexApp}/>
    </Switch>
);

const buyerRoute = (
    <Switch>
        <Route exact path='/telegram' component={TelegramApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/settings' component={SettingsApp}/>
        <Route exact path='/settings/:mode' component={SettingsApp}/>

        <Route exact path='/user/:id' component={UserApp}/>
        <Route exact path='/order/:id' component={OrderApp}/>
        <Route exact path='/product/:id' component={ProductApp}/>

        <Route exact path='/' component={BuyerIndexApp}/>


        <Route exact path='/orders' component={BuyerOrdersApp}/>
        <Route exact path='/proxy' component={BuyerProxiesApp}/>
        <Route component={BuyerIndexApp}/>
    </Switch>
);

const guestRoute = (
    <Switch>
        <Route exact path='/order/:id' component={OrderApp}/>
        <Route path="/dev" component={DevApp}/>
        <Route exact path="*" component={IraLoginApp}/>
    </Switch>
)

export default function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            uiUserRole: state.ui.userRole,
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser, uiUserRole} = useMappedState(mapState);
    const dispatch = useDispatch();
    console.log('currentUser = ', currentUser);

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            console.log('initializeAuthorization: pld = ', pld);
            //todo: optimize the code below, check creds on backend
            // if (pld.user != undefined && pld.user.userRole == 'admin') {
            //
            // }

            //todo: optimize code below
            dispatch(usersActions.loadAllUsers()).then(() => {
                dispatch(usersActions.loadAllProviders()); // todo: optimize
            });
            dispatch(bidsActions.loadAllBids());
            dispatch(productsActions.loadAllProducts());
            dispatch(dealsActions.loadAllDeals());
            dispatch(testimonialsActions.loadAllTestimonials());
            if (pld.user != undefined) {
                dispatch(bookingsActions.loadUserBookings(pld.user.id));
                dispatch(ordersActions.loadUserOrders(pld.user.id));
                dispatch(notificationsActions.loadUserNotifications(pld.user.id));
            }

        });
    }, []);

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }
    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');

    let route = isGuest == true ? guestRoute : (uiUserRole == 'seller' ? sellerRoute : buyerRoute);
    console.log('isAdmin = ', isAdmin);

    return (
        <Wrapper>

            <HashRouter>
                {route}
            </HashRouter>

            {isGuest == true ? null : <NotificationsDaemon/>}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
