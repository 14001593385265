import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NotificationsBubble from "../../notifications/panels/NotificationsBubble";
import UserView from "../../users/views/UserView";


export default function ViewOrderTool(props) {
    const {
        message, countryCode, city, paymentMethods = [],
        userId,
        canViewContacts = false,
        showUserId = false, timestamp, bids = [], bidsNumber = 0,
        creator = {},
        id,
        isMyOrder = false
    } = props;
    let telegram = (creator.telegram == undefined) ? 'Не указан' : creator.telegram;


    console.log('ViewOrderTool: order = ', creator);
    console.log('ViewOrderTool: creator = ', creator);

    return (
        <Wrapper>
            <Box>

                {isMyOrder == true || (creator == undefined) || (creator.id == undefined) ? null :
                    <div>
                        <UserView {...creator} />
                    </div>
                }

                <BoxTop>
                    <BoxName>
                        <NotificationsBubble types={['new_order_proposal']}
                                             style={{display: 'inline-flex', verticalAlign: 'top', marginRight: 5}}
                                             referenceIds={[id]}/>
                        {`${countryCode}${city == undefined ? '' : `, ${city}`}`}
                    </BoxName>
                    {countryCode == undefined ? null :
                        <BigFlagPlaceholder>
                            {flag(countryCode)}
                        </BigFlagPlaceholder>
                    }
                </BoxTop>
                <BoxMiddle>

                    {message == undefined ? null :
                        <MsgPlaceholder>
                            {message}
                        </MsgPlaceholder>
                    }

                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/info_d.svg')}/>
                        <BoxRowName>
                            {moment(timestamp).format('DD.MM.YYYY HH:mm')}
                        </BoxRowName>
                    </BoxRow>
                    {paymentMethods.length == 0 ? null :
                        <BoxRow>
                            <BoxIconLittle src={require('../../../assets/images/pro_money.svg')}/>
                            <BoxRowName>
                                {paymentMethods.join(', ')}
                            </BoxRowName>
                        </BoxRow>
                    }
                    <BoxRow>
                        <BoxIconLittle src={require('../../../assets/images/crowd.svg')}/>
                        <BoxRowName>
                            {`${bidsNumber} ${bidsNumber == 0 ? 'откликов' : (bidsNumber == 1 ? 'отклик' : 'отклика')}`}
                        </BoxRowName>
                    </BoxRow>

                    {showUserId == false ? null :
                        <BoxRow>
                            <BoxIconLittle src={require('../../../assets/images/buyer.svg')}/>
                            <BoxRowName>
                                {`ID    Покупателя: ${userId}`}
                            </BoxRowName>
                        </BoxRow>
                    }

                    {canViewContacts == false ? null :
                        <BoxRow>
                            <BoxIconLittle src={require('../../../assets/images/telega_ico.svg')}/>
                            <BoxRowName>
                                {`Telegram: ${telegram}`}
                            </BoxRowName>
                        </BoxRow>
                    }


                </BoxMiddle>
            </Box>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const MsgPlaceholder = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
`;

const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;

const TopPlaceholder = styled.div`
`;


const PaymentMethodList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const PaymentMethod = styled.div`
  background-color: #eaeaea; 
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0 5px;
  
  :first-child {
    margin-left: 0;
  }
  
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    margin-bottom: 30px;
    margin-top: 0px;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    
`;


const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;
