import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from "styled-components";
import startImg from './../../../assets/images/star.svg';

import moment from 'moment'

import flag from 'country-code-emoji';
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserInfoPanel({user, ...props}) {
    console.log('Log:> props :=', user);
    if (user == undefined) {
        return null;
    }
    const {
        dogs = [],
        onBook = () => {

        },
        products = [],
        canBook = true
    } = props;
    const {
        lastName, firstName, avatar, countries = [], telegram,
        lastSeenTimestamp, timestamp, badges = [],
        paymentMethods = [], about = 'No information', locations = []
    } = user;
    let isTrusted = (badges != undefined && badges.indexOf('trusted') > -1);

    console.log('render: UserInfoPane: products = ', products);

    return (
        <Wrapper>
            <User>
                <ProfilePic pic={avatar}/>
                <UserData>
                    <UserName>
                        {CommonHelper.getUserName(user)}
                        <AchivesList>
                            {isTrusted == false ? null :
                                <Achive src={require('../../../assets/images/badge.svg')}/>
                            }
                            {/*<Achive/>*/}
                            {/*<Achive/>*/}
                        </AchivesList>
                    </UserName>
                    <div>
                        <LocationList>
                            {countries.map((a, i) => {
                                return (
                                    <Location key={a}>
                                        {`${flag(a)}`}
                                        <span style={{marginLeft: 7}}>
                                            {`${a}`}
                                        </span>
                                    </Location>
                                )
                            })}
                        </LocationList>
                    </div>
                    <div>
                        <PaymentMethodList>
                            {(paymentMethods == undefined ? [] : paymentMethods).map((p, i) => {
                                return (
                                    <PaymentMethod key={`${p}_${i}`}>
                                        {p}
                                    </PaymentMethod>
                                )
                            })}
                        </PaymentMethodList>
                    </div>
                    <div style={{fontSize: 12, opacity: 0.3}}>
                        {lastSeenTimestamp == undefined ? null :
                            <span>{`Был на сайте: `}<b>{`${moment(lastSeenTimestamp).format('DD.MM.YYYY HH:mm')},`}</b></span>}
                        <span style={{marginLeft: (lastSeenTimestamp == undefined ? 0 : 6)}}>
                                {`${lastSeenTimestamp == undefined ? 'Дата' : 'дата'} регистрации: `}<b>{`${moment(timestamp).format('DD.MM.YYYY')}`}</b>
                            </span>
                    </div>
                </UserData>
            </User>
            <Info>
                <Description>

                    <DescriptionTitle>Контакты:</DescriptionTitle>
                    <DescriptionContent style={{marginBottom: 20}}>
                        {`Telegram: `}
                        <span style={{marginLeft: 5, fontWeight: 'bold'}}>
                            {`${telegram == undefined ? 'Не указан' : telegram}`}
                        </span>
                    </DescriptionContent>

                    <DescriptionTitle>Информация:</DescriptionTitle>
                    <DescriptionContent style={{marginBottom: 20}}>
                        {about}
                    </DescriptionContent>
                    <DescriptionTitle>ПРОКСИ НА ПРОДАЖУ:</DescriptionTitle>
                    <DescriptionContent>
                        <StatsList>
                        </StatsList>
                        {products.map((a, i) => {
                            return (
                                <div style={{marginBottom: 10, paddingBottom: 5, borderBottom: '1px solid whitesmoke'}}
                                     key={a.id}>
                                    <div style={{
                                        fontSize: 14, display: 'flex',
                                        flexDirection: 'row', alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{flex: 1, boxSizing: 'border-box'}}>
                                            {a.name}
                                        </div>
                                        <PriceBoxesList>
                                            <PriceBox>
                                                {`день: `}{' '}<b style={{marginLeft: 4}}>{`${a.pricePerDay}$`}</b>
                                            </PriceBox>
                                            <PriceBox>
                                                {`7 дней: `}{' '}<b style={{marginLeft: 4}}>{`${a.pricePerWeek}$`}</b>
                                            </PriceBox>
                                            <PriceBox>
                                                {`30 дней: `}{' '}<b style={{marginLeft: 4}}>{`${a.pricePerMonth}$`}</b>
                                            </PriceBox>
                                        </PriceBoxesList>
                                    </div>
                                    <div style={{fontSize: 10, opacity: 0.6}}>
                                        {`${flag(a.countryCode)} ${a.countryCode}, ${a.city} | ${a.operatorsText}`}
                                    </div>
                                </div>
                            )
                        })}
                        {/*{locations.sort().reverse().map((c, i) => {*/}
                        {/*let city = c.split(', ')[1];*/}
                        {/*let countryCode = c.split(', ')[0];*/}
                        {/*let cDogs = dogs.filter(a => (a.city == city));*/}
                        {/*return (*/}
                        {/*<StatsItem canBook={canBook} key={c} style={{*/}
                        {/*fontWeight: 'normal',*/}
                        {/*cursor: (canBook == true ? 'pointer' : 'default')*/}
                        {/*}} onClick={() => {*/}
                        {/*if (canBook == true) {*/}
                        {/*onBook({*/}
                        {/*city: city,*/}
                        {/*countryCode: countryCode*/}
                        {/*})*/}
                        {/*}*/}
                        {/*}}>*/}
                        {/*<span style={{fontWeight: 'normal'}}>{`${c}:`}</span> <span*/}
                        {/*style={{fontWeight: 'bold'}}>{cDogs.length}</span>*/}
                        {/*</StatsItem>*/}
                        {/*)*/}
                        {/*})}*/}
                    </DescriptionContent>
                </Description>
                {/*<Stats>*/}
                {/*<StatsTitle>Info:</StatsTitle>*/}
                {/*<MainInfo>*/}
                {/*<MainInfoItem>*/}
                {/*<Rating>4.8</Rating>*/}
                {/*</MainInfoItem>*/}
                {/*<MainInfoItem>*/}
                {/*<Price>2$</Price>*/}
                {/*</MainInfoItem>*/}
                {/*</MainInfo>*/}
                {/*<StatsList>*/}
                {/*<StatsItem>*/}
                {/*<span>Sells:</span> 79*/}
                {/*</StatsItem>*/}
                {/*<StatsItem>*/}
                {/*<span>Top Type:</span> mobile proxy*/}
                {/*</StatsItem>*/}
                {/*<StatsItem>*/}
                {/*<span>Total proxy:</span> {dogs.length}*/}
                {/*</StatsItem>*/}
                {/*</StatsList>*/}
                {/*</Stats>*/}
            </Info>
        </Wrapper>
    );
}

const PriceBox = styled.div`
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    border: 1px dashed whitesmoke;
    margin-left: 5px;
    background: whitesmoke;
    font-size: 10px;
`;

const PriceBoxesList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 240px;
    justify-content: flex-end;
    text-align: right;
`;

const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MainInfoItem = styled.div`
  font-size: 36px;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  
  :before {
      content: 'from';
      display: block;
      font-size: 18px;
      margin-right: 10px;
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  :before {
    content: '';
    background-image: url(${startImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 25px;
    width: 25px;
    display: block;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

const DescriptionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  color: #8593A8;
`;


const DescriptionContent = styled.div`
  line-height: 1.8em;
`;

const Description = styled.div`
  flex: 1;
  //margin-right: 20px;
  text-align: justify;
  font-size: 14px;
`;

const StatsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  text-align: center;
`;

const Stats = styled.div`
  background-color: #fbfbfb;
  width: 220px;
  box-sizing: content-box;
  padding: 20px;
`;

const StatsList = styled.div`

`;

const StatsItem = styled.div`
  margin: 10px 0;
  
  span {
    font-weight: bold;
  }
  :hover{
    text-decoration: ${props => (props.canBook == true ? 'underline' : 'none')};
  }
`;

const Wrapper = styled.div`
`;


const AchivesList = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
`;

const Achive = styled.img`
  //background-color: red;
  width: 22px;
  height: 26px;
  margin: 0 3px;
`;

const voAva = require('../../../assets/images/vo_ava.jpg');

const ProfilePic = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f5f2f2;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-image: url(${props => (props.pic == undefined ? voAva : props.pic)});
`;

const UserName = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #202224;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LocationList = styled.div`
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 margin-top: 5px;
 margin-top: 5px;
`;

const Location = styled.div`
    font-size: 15px;
    color: rgba(82,82,82,0.8);
    border-right: 1px solid rgba(82,82,82,0.4);
    padding: 0 10px;
    
    :first-child {
      padding-left: 0;
    }
    
    :last-child {
      padding-right: 0;
      border: none;
    }
`;

const PaymentMethodList = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const PaymentMethod = styled.div`
  background-color: #eaeaea; 
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0 5px;
  margin-bottom: 5px;
  :first-child {
    margin-left: 0;
  }
  
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserData = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 15px;
  flex: 1;
  margin: 10px 0;
`;
