import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as usersActions from "../../../redux/actions/UsersActions";
import * as dogsActions from "../../../redux/actions/DogsActions";
import * as ordersActions from "../../../redux/actions/OrdersActions";
import ViewOrderTool from "../tools/ViewOrderTool";
import {RedButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import UpdateOrderForm from "../forms/UpdateOrderForm";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserOrdersPanel(props) {
    const {userId} = props;
    const {currentUser, orders, loading, isMe, bids, currentUserId, uId, myTelegram} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let userOrders = state.orders.ordersMap.toArray()
            .filter(a => (a.userId == uId))
            .sort((a, b) => (+b.timestamp - +a.timestamp))
        return {
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            userId: state.users.currentUserId,
            loading: state.orders.loading,
            orders: userOrders,
            myTelegram: (currentUser == undefined) ? '' : (currentUser.telegram == undefined ? '' : currentUser.telegram)

        }
    }, []));
    const dispatch = useDispatch();
    const [addVisible, setAddVisible] = useState(props.openModal == undefined ? false : props.openModal);

    useEffect(() => {
        dispatch(dogsActions.loadAllDogs());
        dispatch(usersActions.loadAllProviders());
        dispatch(ordersActions.loadUserOrders(uId));
    }, []);

    return (
        <Wrapper>

            {orders.length == 0 ?
                <div>
                    <p>
                        Если Вы не нашли нужного продавца, подайте объявление.
                        Это объявление увидят Ваши потенциальные продавци и они смогут откликнуться на Ваше объявление.
                        {/*У вас еще нет ни одного заказа. Создайте свой первый заказ на прокси!*/}
                        {/*You have no orders yet. Create first order!*/}
                    </p>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <span>
                            <RedButton onClick={() => {
                                setAddVisible(true);
                            }}>
                        Подать объявление
                    </RedButton>
                        </span>
                    </div>
                </div>
                :
                <TopPlaceholder>
                    <RedButton onClick={() => {
                        setAddVisible(true);
                    }}>
                        Подать объявление
                    </RedButton>
                </TopPlaceholder>
            }


            <OrdersList>

                {orders.map((ord, i) => {
                    return (
                        <OrderItem key={ord.id} onClick={() => {
                            CommonHelper.linkTo(`/order/${ord.id}`);
                        }}>
                            <ViewOrderTool {...ord} />
                        </OrderItem>
                    )
                })}

            </OrdersList>

            {addVisible == false ? null :
                <NiceModalWithHeading width={600}
                                      heading={'Заказать прокси'}
                                      subHeading={'Укажите ГЕО, желаемую цену и другие параметры, которые вам необходимы'}
                                      onClose={() => {
                                          setAddVisible(false);
                                      }}>

                    <UpdateOrderForm
                        telegram={myTelegram}
                        loading={loading}
                        saveButtonName={'Создать заказ'}
                        onSave={async d => {
                            await dispatch(ordersActions.createOrder({userId: currentUserId, ...d}))
                            setAddVisible(false);
                        }}/>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const OrdersList = styled.div`
    
`;

const OrderItem = styled.div`
    cursor: pointer;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
