import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import flag from 'country-code-emoji';

import * as dogsActions from '../../../redux/actions/DogsActions'
import * as snakesActions from '../../../redux/actions/SnakesActions'
import * as bookingsActions from '../../../redux/actions/BookingsActions'


import {RedButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateDogForm from "../forms/UpdateDogForm";
import UpdateBookingForm from "../../bookings/forms/UpdateBookingForm";
import NiceConfirm from "../../modals/NiceConfirm";
import CommonHelper from "../../../helpers/CommonHelper";

export default function ForBookingUserDogsPanel(props) {
    const {userId} = props;
    const [refreshing, setRefreshing] = useState(false);

    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [successBookingId, setSuccessBookingId] = useState();

    const {loading, usersMap, uId, dogs, isMe, getDogActiveSnakes, getDogBookings, currentUserId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            dogs: state.dogs.dogsMap.toArray().filter(a => (a.userId == uId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            isMe: uId == state.users.currentUserId,
            getDogActiveSnakes: dogId => state.snakes.snakesMap.toArray().filter(a => (a.dogId == dogId && a.active == true)),
            getDogBookings: dogId => state.bookings.bookingsMap.toArray().filter(a => (a.dogId == dogId)),

        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dogsActions.loadUserDogs(uId));
        dispatch(snakesActions.loadUserSnakes(uId));
    }, [uId]);

    let selectedDog = (selectedId == undefined) ? undefined : dogs.filter(a => (a.id == selectedId))[0];
    if (isMe == true) {
        return null;
    }

    return (
        <Wrapper>

            <ListPlaceholder>
                {dogs.map((a, i) => {
                    let {countryCode, ip, port, city, name, proxyType, login, password} = a;
                    let snakes = getDogActiveSnakes(a.id);
                    let bookings = getDogBookings(a.id);
                    let label = (name == undefined) ? `${countryCode}, ${city}` : name;
                    return (
                        <Box key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <BoxTop>
                                <BoxName>
                                    {label}
                                </BoxName>
                                {countryCode == undefined ? null :
                                    <BigFlagPlaceholder>
                                        {flag(countryCode)}
                                    </BigFlagPlaceholder>
                                }
                            </BoxTop>
                            <BoxMiddle>
                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/worldwide.svg')}/>
                                    <BoxRowName>
                                        {`${countryCode}, ${city}`}
                                    </BoxRowName>
                                </BoxRow>
                                {/*<BoxRow>*/}
                                {/*<BoxIconLittle src={require('../../../assets/images/plug_d.svg')}/>*/}
                                {/*<BoxRowName style={{fontSize: 12}}>*/}
                                {/*{`${proxyType}://${login}:${password}@${ip}:${port}`}*/}
                                {/*</BoxRowName>*/}
                                {/*</BoxRow>*/}
                            </BoxMiddle>
                        </Box>
                    )
                })}
            </ListPlaceholder>


            {selectedDog == undefined ? null :
                <NiceModalWithHeading
                    width={540}
                    heading={'Create booking'}
                    subHeading={'You can submit booking request here'}
                    onClose={() => {
                        setSelectedId(undefined);
                    }}>

                    <ModalInnerPlaceholder>
                        <UpdateBookingForm loading={loading}
                                           onSave={async a => {
                                               let pld = await dispatch(bookingsActions.createBooking({
                                                   ...a,
                                                   sellerId: currentUserId,
                                                   buyerId: uId,
                                                   dogId: selectedDog.id
                                               }));
                                               setSelectedId(undefined);
                                               if (pld.booking != undefined) {
                                                   setSuccessBookingId(pld.booking.id)
                                               }
                                           }}/>
                    </ModalInnerPlaceholder>

                </NiceModalWithHeading>
            }

            {successBookingId == undefined ? null :
                <NiceConfirm heading={'Booking created!'}
                             subHeading={'The booking has been created! Provider will contact you soon via contacts that you put in the request'}
                             onConfirm={() => {
                                 CommonHelper.linkTo(`/buy/bookings`);
                             }}
                             onCancel={() => {
                                 setSuccessBookingId(undefined);
                             }}
                             confirmText={'GO TO MY BOOKINGS'}
                             cancelText={'STAY ON THIS PAGE'}
                />
            }


        </Wrapper>
    );
}

const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;


const ModalInnerPlaceholder = styled.div`
    width: 100%;
    min-height: 200px;
`;

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;


const ListPlaceholder = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    width: calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 30px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    :nth-of-type(3n){
      margin-right: 0px;
    }
    :hover{
      opacity: 0.8;
    }
    
    @media(max-width: 920px){
      width: calc(50% - 15px);
      max-width: calc(50% - 15px);
      :nth-of-type(3n){
        margin-right: 30px;
      }
      :nth-of-type(2n){
        margin-right: 0px;
      }
    }
    
`;
