import React, {Component} from 'react';
import './App.css';
import styled from 'styled-components'
import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger'
import {reducer} from './redux/reducers'
import {default as ReduxThunk} from 'redux-thunk';
import RouterApp from "./components/apps/RouterApp";

import {StoreContext} from 'redux-react-hook';

const store = createStore(
    reducer,
    undefined,
    compose(
        applyMiddleware(ReduxThunk), applyMiddleware(logger)
    )
)

class App extends Component {
    render() {

        return (
            <StoreContext.Provider value={store}>
                <RouterApp/>
            </StoreContext.Provider>
        );
    }
}

export default App;
