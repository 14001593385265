import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from '../../../redux/actions/UsersActions'
import UpdateProfileForm from "../forms/UpdateProfileForm";

export const PAYMENT_OPTIONS = [
    {
        value: 'USDT',
        label: 'USDT'
    },
    {
        value: 'Visa',
        label: 'Visa'
    },
    {
        value: 'Mastercard',
        label: 'Mastercard'
    },
    {
        value: 'AmericanExpress',
        label: 'AmericanExpress'
    },
    {
        value: 'Paypal',
        label: 'Paypal'
    },
    {
        value: 'TransferWise',
        label: 'TransferWise'
    },
    {
        value: 'QIWI',
        label: 'QIWI'
    },
    {
        value: 'Webmoney',
        label: 'Webmoney'
    },
    {
        value: 'YandexMoney',
        label: 'YandexMoney'
    },
    {
        value: 'Bitcoin',
        label: 'Bitcoin'
    },
    {
        value: 'Ethereum',
        label: 'Ethereum'
    }

];

const filledCheck = require('../../../assets/images/che_filled.svg');
const voidCheck = require('../../../assets/images/void_ccc.svg');

const renderTick = (selected, text, onClick = () => {
}) => {
    return (
        <>
        <CheckImg src={(selected == true) ? filledCheck : voidCheck} onClick={() => {
            onClick();
        }}/>
        <CheckSpan onClick={() => {
            onClick();
        }}>
            {text}
        </CheckSpan>
        </>
    )
}

export default function UpdateUpdatePaymentMethodsPanel(props) {
    const {userId} = props;
    const dispatch = useDispatch();
    let {user, loading, uId} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        return {
            user: user,
            loading: state.users.loading,
            uId: uId
        }
    }, []));
    useEffect(() => {
        dispatch(actions.loadUserById(uId));
    }, [uId]);

    if (user == undefined) {
        return null;
    }

    let {paymentMethods = []} = user;
    console.log('render: paymentMethods = ', paymentMethods);

    return (
        <Wrapper>

            <OptionsPlaceholder>
                {PAYMENT_OPTIONS.map((a) => {
                    let isSelected = (paymentMethods.indexOf(a.value) > -1);
                    return (
                        <Row key={a.value}>
                            {renderTick(isSelected, a.label, async () => {
                                let newArr = (isSelected == true) ? paymentMethods.filter(x => (x != a.value)) : paymentMethods.concat([a.value]);
                                await dispatch(actions.updateUser({id: uId, paymentMethods: newArr}))
                            })}
                        </Row>
                    )
                })}
            </OptionsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const OptionsPlaceholder = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;


const CheckImg = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const ChRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
`;

const CheckSpan = styled.div`
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
