import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as productsActions from "../../../redux/actions/ProductsActions";
import * as dogsActions from "../../../redux/actions/DogsActions";
import * as snakesActions from "../../../redux/actions/SnakesActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import UserInfoPanel from "./UserInfoPanel";
import BookUserProxyForm from "../forms/BookUserProxyForm";
import UserCommetsHistoryPanel from "./UserCommetsHistoryPanel";
import * as bookingsActions from "../../../redux/actions/BookingsActions";
import CommonHelper from "../../../helpers/CommonHelper";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import NiceConfirm from "../../modals/NiceConfirm";
import UpdateBookingForm from "../../bookings/forms/UpdateBookingForm";
import {BlueButton} from "../../ira/ui/Buttons";
import UserDealsPanel from "../../deals/panels/UserDealsPanel";
import TestimonialsPanel from "../../testimonials/panels/TestimonialsPanel";
import UserAdminForm from "../forms/UserAdminForm";


export default function UserViewPanel(props) {
    const {id} = props;
    const [refreshing, setRefreshing] = useState(false);

    const [selectedId, setSelectedId] = useState(undefined);
    const [successBookingId, setSuccessBookingId] = useState();

    const {
        loading, usersMap, uId, dogs, isMe, getDogActiveSnakes,
        meIsAdmin,
        user, currentUserId, products, myTelegram
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (id == undefined) ? state.users.currentUserId : id;
        let products = state.products.productsMap.toArray().filter(a => (a.userId == uId));
        return {
            meIsAdmin: (currentUser != undefined && currentUser.userRole == 'admin'),
            myTelegram: currentUser == undefined ? '' : currentUser.telegram,
            user: state.users.usersMap.get(uId),
            loading: state.users.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            products: products,
            currentUserId: state.users.currentUserId,
            dogs: state.dogs.dogsMap.toArray().filter(a => (a.userId == uId)),
            isMe: uId == state.users.currentUserId,
            getDogActiveSnakes: dogId => state.snakes.snakesMap.toArray().filter(a => (a.dogId == dogId && a.active == true))
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dogsActions.loadUserDogs(uId));
        dispatch(snakesActions.loadUserSnakes(uId));
        dispatch(usersActions.loadUserById(uId));
        dispatch(productsActions.loadUserProducts(uId));
    }, [uId]);

    let locations = dogs.map(a => `${a.countryCode}, ${a.city}`).reduce((st, a) => st.add(a), Set()).toArray().sort().reverse();
    let selectedDog = (selectedId == undefined) ? undefined : dogs.filter(a => (a.id == selectedId))[0];

    console.log('render: products = ', products);

    if (user == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <Box>
                <BoxContent>
                    <UserInfoPanel user={user} dogs={dogs}
                                   products={products}
                                   canBook={!isMe} onBook={d => {
                        let {city, countryCode} = d;
                        let fDogs = dogs.filter(a => (a.countryCode == countryCode && a.city == city));
                        if (fDogs.length > 0) {
                            setSelectedId(fDogs[0].id);
                        }
                    }}/>
                </BoxContent>

                <BoxContent>
                    <TestimonialsPanel userId={uId}/>
                </BoxContent>

                {(meIsAdmin == false || user == undefined) ? null :
                    <div style={{marginBottom: 20}}>
                        <h3>
                            Видно только админу
                        </h3>
                        <UserAdminForm {...user}
                                       loading={loading}
                                       onSave={async d => {
                                           await dispatch(usersActions.updateUser({id: user.id, ...d}))
                                       }}/>
                    </div>
                }

            </Box>

            <div>
                <BoxInfo>
                    {/*<BookUserProxyForm canBook={!isMe} locations={locations} onBook={d => {*/}
                    {/*let {city, countryCode} = d;*/}
                    {/*let fDogs = dogs.filter(a => (a.countryCode == countryCode && a.city == city));*/}
                    {/*if (fDogs.length > 0) {*/}
                    {/*setSelectedId(fDogs[0].id);*/}
                    {/*}*/}
                    {/*}}/>*/}
                    {/*<BlueButton>*/}
                    {/*НАПИСАТЬ ПРОДАВЦУ*/}
                    {/*</BlueButton>*/}

                    {products.length == 0 ?
                        'На данный момент у пользователя нет прокси на продажу'
                        :
                        <UserDealsPanel userId={uId}/>
                    }

                </BoxInfo>
            </div>

            {selectedDog == undefined ? null :
                <NiceModalWithHeading
                    width={540}
                    heading={`${selectedDog.countryCode}, ${selectedDog.city}`}
                    subHeading={'Please specify booking details'}
                    onClose={() => {
                        setSelectedId(undefined);
                    }}>

                    <ModalInnerPlaceholder>
                        <UpdateBookingForm loading={loading}
                                           onSave={async a => {
                                               let pld = await dispatch(bookingsActions.createBooking({
                                                   ...a,
                                                   sellerId: uId,
                                                   buyerId: currentUserId,
                                                   dogId: selectedDog.id
                                               }));
                                               setSelectedId(undefined);
                                               if (pld.booking != undefined) {
                                                   setSuccessBookingId(pld.booking.id)
                                               }
                                           }}/>
                    </ModalInnerPlaceholder>

                </NiceModalWithHeading>
            }

            {successBookingId == undefined ? null :
                <NiceConfirm heading={'Booking created!'}
                             subHeading={'The booking has been created! Provider will contact you soon via contacts that you put in the request'}
                             onConfirm={() => {
                                 CommonHelper.linkTo(`/buy/bookings`);
                             }}
                             onCancel={() => {
                                 setSuccessBookingId(undefined);
                             }}
                             confirmText={'GO TO MY BOOKINGS'}
                             cancelText={'STAY ON THIS PAGE'}
                />
            }

        </Wrapper>
    );
}

const ModalInnerPlaceholder = styled.div`
    width: 100%;
    min-height: 200px;
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BoxInfo = styled.div`
    background: #FFFFFF;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    margin-left: 10px;
    box-sizing: border-box;
`;


const Box = styled.div`
    flex: 1;
    max-width: 850px;
`;

const BoxContent = styled.div`
    background: #FFFFFF;
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 10px;
`;
