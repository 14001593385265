import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const NotificationsAPI = {

    getUserNotifications(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/notifications`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                console.log('getUserNotifications: loaded notifications = ', pld.result);
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAllNotifications() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/notifications/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getNotification(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/notification/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createNotification(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/notification`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateNotification(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/notification`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteNotification(id) {
        console.log('deleteNotification occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/notification`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteSmartNotification(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/notification/smart_delete`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },


}

export default NotificationsAPI;
