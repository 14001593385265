import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../../helpers/ReactHelper";

import * as actions from '../../../redux/actions/NotificationsActions'
import {RedButton} from "../../ira/ui/Buttons";
import CommonHelper from "../../../helpers/CommonHelper";


export default function ViewNotificationButton(props) {
    const {types = [], referenceIds = [], style = {}} = props;
    const {
        loading, currentUserId, notifications, text = 'Отметить прочитанным'
    } = useMappedState(useCallback(state => {
        let nots = state.notifications.notificationsMap.toArray().filter(a => (types.indexOf(a.type) > -1));
        if (referenceIds.length > 0) {
            nots = nots.filter(a => CommonHelper.listsHaveIntersection(referenceIds, a.referenceIds))
        }
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            notifications: nots
        }
    }, []));
    const dispatch = useDispatch();

    if (notifications.length == 0) {
        return null;
    }

    return (
        <Wrapper style={style} onClick={async () => {
            for (let i in notifications) {
                let id = notifications[i].id;
                await dispatch(actions.deleteNotification(id));
            }
        }}>
            <Spin theme={'alfa-on-color'} visible={loading}/>
            {loading == true ? null : text}
        </Wrapper>
    );
}


const Wrapper = styled(RedButton)`
    
`;
