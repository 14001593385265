import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ReactStars from "react-rating-stars-component";

import {Input, Textarea, Label} from "../../ira/ui/Inputs";

import {BlueButton} from "../../ira/ui/Buttons";

export default function UpdateTestimonialForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;

    const [text, setText] = useState(props.text == undefined ? '' : props.text);
    const [rating, setRating] = useState(props.rating);

    return (
        <Wrapper>
            <Field>
                <Label>
                    Напишите свой отзыв
                </Label>
                <Textarea value={text} onChange={evt => {
                    setText(evt.target.value)
                }}/>
            </Field>

            <Field>
                <Label>
                    Оценка
                </Label>
                <div>
                    <ReactStars count={5} value={rating} onChange={a => {
                        setRating(a);
                    }}/>
                </div>
            </Field>

            <BottomPlaceholder>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <BlueButton onClick={() => {
                        onSave({
                            text: text,
                            rating: +rating
                        });
                    }}>
                        Оставить отзыв
                    </BlueButton>
                }
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const BottomPlaceholder = styled.div`
    
`;

const RatingPlaceholder = styled.div`
    
`;
