import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    snakesMap: Map(),
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const SnakesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_SNAKE:
        case types.UPDATE_SNAKE:
        case types.DELETE_SNAKE:
        case types.LOAD_SNAKES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_SNAKE_FAIL:
        case types.UPDATE_SNAKE_FAIL:
        case types.DELETE_SNAKE_FAIL:
        case types.LOAD_SNAKES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_SNAKE_SUCCESS:
        case types.UPDATE_SNAKE_SUCCESS:
            return {
                ...state,
                loading: false,
                snakesMap: state.snakesMap.set(action.snake.id, action.snake)
            }


        case types.DELETE_SNAKE_SUCCESS:
            return {
                ...state,
                loading: false,
                snakesMap: state.snakesMap.delete(action.id),
            }


        case types.LOAD_SNAKES_SUCCESS:
            return {
                ...state,
                loading: false,
                snakesMap: state.snakesMap
                    .merge(action.snakes.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default SnakesReducer;
