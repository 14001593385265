import NotificationsAPI from '../../api/NotificationsAPI'

import * as types from '../ActionTypes'

let loadNotifications_ = () => {
    return {
        type: types.LOAD_NOTIFICATIONS
    }
}
let loadNotificationsSuccess = (notifications) => {
    return {
        type: types.LOAD_NOTIFICATIONS_SUCCESS,
        notifications: notifications
    }
}
let loadNotificationsFailed = (error) => {
    return {
        type: types.LOAD_NOTIFICATIONS_FAIL,
        error: error
    }
}

//thunk
export function loadAllNotifications() {
    return (dispatch, getState) => {
        dispatch(loadNotifications_());
        return NotificationsAPI.getAllNotifications().then(
            notifications => dispatch(loadNotificationsSuccess(notifications)),
            error => dispatch(loadNotificationsFailed(error))
        )
    }
}

export function loadUserNotifications(userId) {
    return (dispatch, getState) => {
        dispatch(loadNotifications_());
        return NotificationsAPI.getUserNotifications(userId).then(
            notifications => dispatch(loadNotificationsSuccess(notifications)),
            error => dispatch(loadNotificationsFailed(error))
        )
    }
}

export function loadNotificationById(id) {
    return (dispatch, getState) => {
        dispatch(loadNotifications_());
        return NotificationsAPI.getNotification(id).then(
            notification => dispatch(loadNotificationsSuccess([notification])),
            error => dispatch(loadNotificationsFailed(error))
        )
    }
}

let createNotification_ = () => {
    return {
        type: types.CREATE_NOTIFICATION
    }
}
let createNotificationSuccess = (notification) => {
    return {
        type: types.CREATE_NOTIFICATION_SUCCESS,
        notification: notification
    }
}
let createNotificationFailed = (error) => {
    return {
        type: types.CREATE_NOTIFICATION_FAIL,
        error: error
    }
}

//thunk
export function createNotification(data) {
    return (dispatch, getState) => {
        dispatch(createNotification_());
        return NotificationsAPI.createNotification(data).then(
            notification => dispatch(createNotificationSuccess(notification)),
            error => dispatch(createNotificationFailed(error))
        )
    }
}

let updateNotification_ = () => {
    return {
        type: types.UPDATE_NOTIFICATION
    }
}
let updateNotificationSuccess = (notification) => {
    return {
        type: types.UPDATE_NOTIFICATION_SUCCESS,
        notification: notification
    }
}
let updateNotificationFailed = (error) => {
    return {
        type: types.UPDATE_NOTIFICATION_FAIL,
        error: error
    }
}

//thunk
export function updateNotification(data) {
    return (dispatch, getState) => {
        dispatch(updateNotification_());
        return NotificationsAPI.updateNotification(data).then(
            notification => dispatch(updateNotificationSuccess(notification)),
            error => dispatch(updateNotificationFailed(error))
        )
    }
}

let deleteNotification_ = () => {
    return {
        type: types.DELETE_NOTIFICATION
    }
}
let deleteNotificationSuccess = (id) => {
    return {
        type: types.DELETE_NOTIFICATION_SUCCESS,
        id: id
    }
}
let deleteNotificationFailed = (error) => {
    return {
        type: types.DELETE_NOTIFICATION_FAIL,
        error: error
    }
}

//thunk
export function deleteNotification(id) {
    return (dispatch, getState) => {
        dispatch(deleteNotification_());
        return NotificationsAPI.deleteNotification(id).then(
            () => dispatch(deleteNotificationSuccess(id)),
            error => dispatch(deleteNotificationFailed(error))
        )
    }
}


export function deleteSmartNotification(data) {
    return (dispatch, getState) => {
        dispatch(deleteNotification_());
        return NotificationsAPI.deleteSmartNotification(data).then(
            id => dispatch(deleteNotificationSuccess(id)),
            error => dispatch(deleteNotificationFailed(error))
        )
    }
}
