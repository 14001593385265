import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    catsMap: Map(),
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const CatsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_CAT:
        case types.UPDATE_CAT:
        case types.DELETE_CAT:
        case types.LOAD_CATS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_CAT_FAIL:
        case types.UPDATE_CAT_FAIL:
        case types.DELETE_CAT_FAIL:
        case types.LOAD_CATS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_CAT_SUCCESS:
        case types.UPDATE_CAT_SUCCESS:
            return {
                ...state,
                loading: false,
                catsMap: state.catsMap.set(action.cat.id, action.cat)
            }


        case types.DELETE_CAT_SUCCESS:
            return {
                ...state,
                loading: false,
                catsMap: state.catsMap.delete(action.id),
            }


        case types.LOAD_CATS_SUCCESS:
            return {
                ...state,
                loading: false,
                catsMap: state.catsMap
                    .merge(action.cats.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default CatsReducer;
