import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ToolsAPI from "../../../api/ToolsAPI";
import ReactHelper from "../../../helpers/ReactHelper";

export default function DogIpInfoView(props) {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const {prefix = '', watchInterval = 2 * 60 * 1000} = props;

    useEffect(() => {
        setLoading(true);
        ToolsAPI.getProxyIpInfo(props).then(a => {
            setData(a);
            setLoading(false)
        });
    }, []);

    ReactHelper.useInterval(() => {
        setLoading(true);
        ToolsAPI.getProxyIpInfo(props).then(a => {
            setData(a);
            setLoading(false)
        });
    }, [watchInterval]);

    if (loading == true && data == undefined) {
        return <Spin visible={true}/>;
    }

    let ip = undefined;
    try {
        ip = (data == undefined) ? undefined : JSON.parse(data).ip;
    } catch (e) {
        console.log('no ip');
    }

    return (
        <Wrapper>
            <Spin visible={loading} size={'s'}/>

            {ip == undefined ? <span style={{color: 'red'}}>OFFLINE</span> :
                <span style={{color: 'green'}}>{`${prefix}${ip}`}</span>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
