import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import * as usersActions from "../../../redux/actions/UsersActions";
import * as dogsActions from "../../../redux/actions/DogsActions";
import * as ordersActions from "../../../redux/actions/OrdersActions";
import ViewOrderTool from "../tools/ViewOrderTool";
import CommonHelper from "../../../helpers/CommonHelper";
import SmartSelect from "../../ira/ui/SmartSelect";
import {API_ENDPOINT} from "../../../constants/config";
import flag from "country-code-emoji";

import axios from 'axios'

export default function AllOrdersPanel(props) {
    const {userId} = props;
    const {currentUser, orders, loading, isMe, bids, currentUserId, uId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let allOrders = state.orders.ordersMap.toArray()
            .sort((a, b) => (+b.timestamp - +a.timestamp))
        return {
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            userId: state.users.currentUserId,
            loading: state.orders.loading,
            orders: allOrders
        }
    }, []));
    const dispatch = useDispatch();
    const [addVisible, setAddVisible] = useState(false);
    const [baseCountries, setBaseCountries] = useState([]);
    const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);

    useEffect(() => {
        dispatch(dogsActions.loadAllDogs());
        dispatch(usersActions.loadAllProviders());
        dispatch(ordersActions.loadAllOrders());
        axios.get(`${API_ENDPOINT}/countries`).then(a => a.data).then(arr => {
            setBaseCountries(arr);
        })
    }, []);

    const avCountSet = orders.map(a => a.countryCode).reduce((st, a) => st.add(a), Set());
    const COUNTRIES_OPTIONS = baseCountries.filter(a => avCountSet.has(a.iso)).map(a => ({
        value: a.iso,
        label: `${flag(a.iso)} ${a.name_en}`
    }));

    let fOrders = (selectedCountryCodes.length == 0) ? orders : orders.filter(a => CommonHelper.listsHaveIntersection(selectedCountryCodes, [a.countryCode]));

    return (
        <Wrapper>

            <VeryTopPlaceholder>
                <TopLeft>
                    Все объявления
                </TopLeft>
                <TopRight>
                    <SmartSelect options={COUNTRIES_OPTIONS}
                                 selectedValues={selectedCountryCodes}
                                 isMulti={true}
                                 placeholder={'Фильтр по ГЕО'}
                                 onSelect={a => {
                                     setSelectedCountryCodes(a);
                                 }}
                    />
                </TopRight>
            </VeryTopPlaceholder>

            <OrdersList>

                {fOrders.map((ord, i) => {
                    return (
                        <OrderItem key={ord.id} onClick={() => {
                            CommonHelper.linkTo(`/order/${ord.id}`);
                        }}>
                            <ViewOrderTool {...ord} />
                        </OrderItem>
                    )
                })}

            </OrdersList>

        </Wrapper>
    );
}

const TopLeft = styled.div`
    font-size: 24px;  
`;

const TopRight = styled.div`
    width: 300px;
`;

const VeryTopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    
`;

const OrdersList = styled.div`
    
`;

const OrderItem = styled.div`
    cursor: pointer;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
