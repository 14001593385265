import firebase from 'firebase'

import {FIREBASE_CREDENTIALS, STARTING_FEN} from "../constants/config";

const FirebaseAPI = {

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CREDENTIALS);
        }
    },
    // chat room

    getLazyRoomSettings(roomId, data) {
        console.log('getLazyRoomSettings: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/RoomSettings/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/RoomSettings/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    getLazyChatRoom(roomId, data) {
        console.log('getLazyChatRoom: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/ChatRoom/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/ChatRoom/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnRoomChat(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomSettings(roomId, defaultD).then(pld => {
                firebase.database().ref(`/ChatRoom/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateRoomChatData(roomId, newData) {
        console.log('updateRoomChatData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/ChatRoom/${roomId}`).set({
                ...newData,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

}

export default FirebaseAPI;
