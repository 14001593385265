import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {API_ENDPOINT} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";

import axios from 'axios'
import IraLoginForm from "../auth/forms/IraLoginForm";
import IraSignupForm from "../auth/forms/IraSignupForm";
import IraRecoverPasswordForm from "../auth/forms/IraRecoverPasswordForm";

const bgImg = require('../../assets/images/bg_prx_img.svg');

export default function IraLoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();
    const [mode, setMode] = useState('login');
    const [recovering, setRecovering] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [recoveringSent, setRecoveringSent] = useState(false);

    return (
        <Wrapper>
            <Inner>

                <TopLogoImg src={require('../../assets/images/proxy_logo_long.png')}/>

                {mode == 'login' && <IraLoginForm loading={loading}

                                                  onSignupClick={() => {
                                                      setMode('signup');
                                                  }}
                                                  onSubmit={async data => {
                                                      let pld = await dispatch(usersActions.logIn(data));
                                                      if (pld.error != undefined) {
                                                          if (pld.error.code == 101) {
                                                              window.alert('Неверный логин или пароль');
                                                          }
                                                      }
                                                  }}
                                                  onForgotPasswordClick={() => {
                                                      setMode('recover');
                                                  }}

                />}

                {mode == 'signup' && <IraSignupForm loading={registering} onLoginClick={() => {
                    setMode('login');
                }}
                                                    onSubmit={async d => {
                                                        setRegistering(true);
                                                        setRegistering(true);
                                                        let pld = (await axios.post(`${API_ENDPOINT}/users/create`, d)).data;
                                                        if (pld.error != undefined) {
                                                            window.alert(pld.error.message);
                                                            setRegistering(false);
                                                            return;
                                                        }
                                                        setRegistering(false);
                                                        await dispatch(usersActions.logIn(d));
                                                    }}
                />}

                {mode == 'recover' && <IraRecoverPasswordForm
                    recoveringSent={recoveringSent}
                    loading={recovering} onSubmit={async d => {
                    let {email} = d;
                    setRecovering(true);
                    let pld = (await axios.post(`${API_ENDPOINT}/recover`, {email: email})).data;
                    setRecovering(false);
                    setRecoveringSent(true);
                }}
                    onLoginClick={() => {
                        setMode('login');
                    }}
                />}

            </Inner>
        </Wrapper>
    );
}


const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #E9F2FD;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${bgImg});
    font-family: Montserrat;
`;

const Inner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TopLogoImg = styled.img`
    margin-bottom: 38px;
    width: 400px;
    @media(max-width: 720px){
      display: none;
    }
`;
