import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import {BlueButton} from "../../ira/ui/Buttons";

export default function ChangeCatIPWrapper(props) {
    const {
        id, onSuccess = () => {

        }, onError = () => {

        }
    } = props;

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    if (loading == true) {
        return (
            <Spin visible={true}/>
        )
    }

    return (
        <Wrapper>
            <Inner onClick={async () => {
                setLoading(true);
                try {
                    await axios.get(`${API_ENDPOINT}/cat/${id}/change_ip`);
                    setSuccessVisible(true);
                } catch (e) {
                    setErrorVisible(true);
                }
                setLoading(false);
            }}>
                {props.children}
            </Inner>

            {/*{successVisible == false ? null :*/}
                {/*<NiceModalWithHeading onClose={() => {*/}
                    {/*setSuccessVisible(false);*/}
                {/*}}*/}
                                      {/*heading={'Success'}*/}
                                      {/*subHeading={'Change IP command performed successfullly. Wait for some seconds to see if IP has been changed'}*/}
                {/*>*/}


                {/*</NiceModalWithHeading>*/}
            {/*}*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Inner = styled.div`
    
`;
