import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {createFilter} from "react-select";
import SimpleDateSelector from "../../ira/calendars/SimpleDateSelector";

import {Label, Input, Textarea} from "../../ira/ui/Inputs";
import NiceConfirm from "../../modals/NiceConfirm";

import {BlueButton} from "../../ira/ui/Buttons";

import Select from 'react-select'

const PROXY_TYPES_OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'HTTP',
        value: 'http'
    }
];

export default function UpdateBookingForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'socks5' : props.proxyType);
    const [fromTimestamp, setFromTimestamp] = useState(props.fromTimestamp == undefined ? +moment().startOf('day') : props.fromTimestamp);
    const [toTimestamp, setToTimestamp] = useState(props.toTimestamp == undefined ? +moment().add(7, 'days').startOf('day') : props.toTimestamp);
    const [contacts, setContacts] = useState(props.contacts == undefined ? '' : props.contacts);
    const [message, setMessage] = useState(props.message == undefined ? '' : props.message);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    return (
        <Wrapper>

            <Row>

                <Half>
                    <Field>
                        <Label>
                            Start of booking
                        </Label>
                        <SimpleDateSelector
                            selectedTimestamp={fromTimestamp}
                            onChange={a => {
                                setFromTimestamp(a);
                            }}
                        />
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            End of booking
                        </Label>
                        <SimpleDateSelector
                            selectedTimestamp={toTimestamp}
                            onChange={a => {
                                setToTimestamp(a);
                            }}
                        />
                    </Field>
                </Half>

                <div style={{width: 135, marginLeft: 5}}>
                    <Field>
                        <Label>
                            Proxy type
                        </Label>
                        <Select options={PROXY_TYPES_OPTIONS}
                                value={PROXY_TYPES_OPTIONS.filter(a => (a.value == proxyType))[0]}
                                onChange={a => {
                                    setProxyType(a.value);
                                }}/>
                    </Field>
                </div>

            </Row>

            <Field>
                <Label>
                    Your contacts
                </Label>
                <Input
                    value={contacts}
                    placeholder={'You contacts so the seller could contact you'}
                    onChange={evt => {
                        setContacts(evt.target.value);
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Additional message for the seller
                </Label>
                <Textarea value={message}
                          placeholder={'Anything you want to write to seller'}
                          onChange={evt => {
                              setMessage(evt.target.value);
                          }}
                />
            </Field>

            <BottomSavePlaceholder canDelete={canDelete}>
                <BlueButton style={{width: 200}} loading={loading} onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        message,
                        contacts,
                        fromTimestamp,
                        toTimestamp,
                        proxyType
                    });
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : 'Сохранить'}
                </BlueButton>
                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        setConfirmDeleteVisible(true);
                    }}>
                        delete booking
                    </DeleteSpan>
                }
            </BottomSavePlaceholder>

            {confirmDeleteVisible == false ? null :
                <NiceConfirm
                    onClose={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    confirmText={'YES, DELETE'}
                    cancelText={'No, nevermind'}
                    heading={'Delete booking'}
                    subHeading={'Are you sure that you want to delete this booking?'}
                />
            }

        </Wrapper>
    );
}

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    :hover{
      opacity: 0.9;
    }
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;
`;
