import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ViewOrderTool from "../tools/ViewOrderTool";

import * as dogsActions from '../../../redux/actions/DogsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import * as bidsActions from '../../../redux/actions/BidsActions'
import * as ordersActions from '../../../redux/actions/OrdersActions'
import * as productsActions from '../../../redux/actions/ProductsActions'

import flag from "country-code-emoji";
import CommonHelper from "../../../helpers/CommonHelper";
import {RedButton} from "../../ira/ui/Buttons";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateBidForm from "../forms/UpdateBidForm";
import NiceConfirm from "../../modals/NiceConfirm";
import ViewNotificationButton from "../../notifications/panels/ViewNotificationButton";
import NotificationsBubble from "../../notifications/panels/NotificationsBubble";
import UserView from "../../users/views/UserView";
import OrderFlowTool from "../tools/OrderFlowTool";

const voidAva = require('../../../assets/images/vo_ava.jpg');

export default function OrderPanel(props) {
    const {id} = props;
    const {
        currentUser, userId, order, isGuest, loading, myProducts,
        creator,
        isMe, bids, getUserDogs, currentUserId, allOrders, isMyOrder
    } = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let ord = state.orders.ordersMap.get(id);
        let me = (ord != undefined && ord.userId == currentUserId);
        let isMyOrder = (ord != undefined && ord.userId == currentUserId);
        let creator = (ord == undefined) ? undefined : state.users.usersMap.get(ord.userId);
        return {
            creator: creator,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isGuest: (currentUser == undefined),
            userId: state.users.currentUserId,
            order: state.orders.ordersMap.get(id),
            allOrders: state.orders.ordersMap.toArray(),
            loading: state.orders.loading || state.bids.loading,
            isMe: me,
            isMyOrder: isMyOrder,
            bids: state.bids.bidsMap.toArray()
                .filter(a => (a.orderId == id))
                .sort((a, b) => (+b.timestamp - +a.timestamp))
                .map(b => ({...b, user: state.users.usersMap.get(b.userId)}))
                .filter(a => (a.user != undefined)),
            getUserDogs: aId => state.dogs.dogsMap.toArray().filter(a => (a.userId == aId)),
            myProducts: state.products.productsMap.toArray().filter(a => (a.userId == currentUserId))
        }
    }, [id]));
    const dispatch = useDispatch();

    const [addBidVisible, setAddBidVisible] = useState(false);

    const [dontHaveProductsModalVisible, setDontHaveProductsModalVisible] = useState(false);

    useEffect(() => {
        dispatch(dogsActions.loadAllDogs());
        dispatch(usersActions.loadAllUsers()); // todo: optimize
        dispatch(ordersActions.loadOrderById(id));
        dispatch(productsActions.loadUserProducts(currentUserId));
    }, [id]);

    console.log('OrderPanel: id, order, allOrders = ', id, order, allOrders);

    if (order == undefined && loading == true) {
        return (
            <Code/>
        )
    }
    if (order == undefined) {
        return null;
    }
    let canAddBid = (currentUser != undefined && order.userId != currentUserId) && (bids.filter(a => (a.userId == currentUserId)).length == 0);
    let iAddedBid = (bids.filter(a => (a.userId == currentUserId))).length > 0;
    let iHaveProducts = (myProducts.length > 0);

    console.log('render: iAddedBid = ', iAddedBid);

    return (
        <Wrapper>

            <TopPlaceholder>

                <div style={{flex: 1}}>
                    <ViewOrderTool {...order}
                                   isMyOrder={isMyOrder}
                                   creator={creator}
                                   showUserId={true}
                                   canUpdate={isMyOrder} bids={bids}
                                   canViewContacts={!canAddBid}
                    />
                    {canAddBid == false ? null :
                        <div>
                            <RedButton onClick={() => {
                                if (iHaveProducts == false) {
                                    setDontHaveProductsModalVisible(true);
                                    return;
                                }
                                setAddBidVisible(true);
                            }}>
                                Откликнуться
                            </RedButton>
                        </div>
                    }
                </div>

                {isMyOrder == true ? null :
                    <React.Fragment>
                        <TopRightFlowPlaceholder style={{width: 550}}>
                            <OrderFlowTool
                                mode={(isMyOrder == true ? 'buyer' : 'seller')}
                                hasBid={iAddedBid}
                            />
                        </TopRightFlowPlaceholder>
                    </React.Fragment>
                }

            </TopPlaceholder>


            {isGuest == false ? null :
                <GuestPlaceholder>
                    <p>
                        Если вы хотите откликнуться на заказ, пожалуйста, <TeleLink
                        href={`/app/`}>авторизуйтесь</TeleLink>.
                    </p>
                    <p>
                        If you want to accept the order please <TeleLink href={`/app/`}>sign in</TeleLink>.
                    </p>
                </GuestPlaceholder>
            }

            <BidsPlaceholder>
                {(bids == undefined ? [] : bids).map((bid, i) => {
                    let u = bid.user;
                    let isMyBid = (u.id == currentUserId);
                    let locations = (u == undefined) ? [] : (u.locations == undefined ? [] : u.locations);
                    let paymentMethods = (u == undefined) ? [] : u.paymentMethods;
                    let countries = (u == undefined) ? [] : (u.countries == undefined ? [] : u.countries);
                    let uDogs = (u == undefined) ? [] : getUserDogs(u.id);
                    let canBook = (bid.userId != currentUserId);
                    let {message} = bid;

                    return (
                        <UserItem key={`${u.id}_${i}`}>
                            <User>
                                <ProfilePic pic={u.avatar}/>
                                <UserData>
                                    <UserDataTop>
                                        <UserName onClick={() => {
                                            CommonHelper.linkTo(`/user/${u.id}`);
                                        }}>

                                            <NotificationsBubble types={['new_order_proposal']}
                                                                 style={{
                                                                     display: 'inline-flex',
                                                                     verticalAlign: 'top',
                                                                     marginRight: 5
                                                                 }}
                                                                 referenceIds={[bid.id]}/>

                                            {CommonHelper.getUserName(u)}
                                            {/*<AchivesList>*/}
                                            {/*<Achive src={require('../../../assets/images/badge.svg')}/>*/}
                                            {/*</AchivesList>*/}
                                        </UserName>

                                        {isMyBid == true ? null :
                                            <UserDataTopActionsPlaceholder>

                                                <ViewNotificationButton
                                                    types={['new_order_proposal']}
                                                    style={{
                                                        display: 'inline-flex',
                                                        verticalAlign: 'top',
                                                        marginRight: 5
                                                    }}
                                                    referenceIds={[bid.id]}
                                                />

                                                {/*<OceanButton onClick={() => {*/}
                                                {/*// setSelectedProviderId(u.id);*/}
                                                {/*CommonHelper.linkTo(`/user/${u.id}`, true);*/}
                                                {/*}}>*/}
                                                {/*ЗАБРОНИРОВАТЬ*/}
                                                {/*</OceanButton>*/}
                                            </UserDataTopActionsPlaceholder>
                                        }
                                    </UserDataTop>

                                    <div>
                                        <LocationList>
                                            {countries.map((a, i) => {
                                                return (
                                                    <Location key={a}>
                                                        {`${flag(a)}`}
                                                        <span style={{marginLeft: 7}}>
                                            {`${a}`}
                                        </span>
                                                    </Location>
                                                )
                                            })}
                                        </LocationList>
                                    </div>


                                    <div style={{fontSize: 12, opacity: 0.7}}>
                                        {`Telegram:`}
                                        {u.telegram == undefined ? 'Не указан' :
                                            <TLink href={`https://t.me/${u.telegram.replace('@', '')}`}
                                                   target={'_blank'}>
                                                {`@${u.telegram.replace('@', '')}`}
                                            </TLink>
                                        }
                                        <span style={{marginLeft: 5}}>
                                            {`ID: ${u.id}`}
                                        </span>

                                    </div>
                                    <div>
                                        <span style={{fontSize: 12, opacity: 0.5}}>
                                            {`${moment(bid.timestamp).format('DD.MM.YYYY HH:mm')}`}
                                        </span>
                                    </div>

                                    <div>
                                        <PaymentMethodList>
                                            {(paymentMethods == undefined ? [] : paymentMethods).map((p, i) => {
                                                return (
                                                    <PaymentMethod key={`${p}_${i}`}>
                                                        {p}
                                                    </PaymentMethod>
                                                )
                                            })}
                                        </PaymentMethodList>
                                    </div>

                                    {/*{(paymentMethods == undefined ? [] : paymentMethods).length == 0 ? null :*/}
                                    {/*<div style={{marginTop: 10}}>*/}
                                    {/*<ProxyItem>*/}
                                    {/*{'Proxies: '}*/}
                                    {/*</ProxyItem>*/}
                                    {/*{locations.sort().reverse().map((c, i) => {*/}
                                    {/*let city = c.split(', ')[1];*/}
                                    {/*let countryCode = c.split(', ')[0];*/}
                                    {/*let cDogs = uDogs.filter(a => (a.city == city));*/}
                                    {/*return (*/}
                                    {/*<ProxyItem canBook={canBook} key={c} style={{*/}
                                    {/*fontWeight: 'normal',*/}
                                    {/*cursor: (canBook == true ? 'pointer' : 'default')*/}
                                    {/*}} onClick={() => {*/}
                                    {/*// if (canBook == true) {*/}
                                    {/*//     onBook({*/}
                                    {/*//         city: city,*/}
                                    {/*//         countryCode: countryCode*/}
                                    {/*//     })*/}
                                    {/*// }*/}
                                    {/*}}>*/}
                                    {/*<span style={{fontWeight: 'normal'}}>{`${c}:`}</span> <span*/}
                                    {/*style={{fontWeight: 'bold'}}>{cDogs.length}</span>*/}
                                    {/*</ProxyItem>*/}
                                    {/*)*/}
                                    {/*})}*/}
                                    {/*</div>*/}
                                    {/*}*/}


                                </UserData>

                            </User>

                            {(isMyOrder == false && bid.userId != currentUserId) ? null :
                                <BidMessagePlaceholder>
                                    {message}
                                </BidMessagePlaceholder>
                            }

                        </UserItem>
                    )
                })}
            </BidsPlaceholder>

            {addBidVisible == false ? null :
                <NiceModalWithHeading
                    heading={'Ваша заявка'}
                    subHeading={'Напишите сообщение покупателю. Если его заинтересует Ваше предложение, то он с Вами свяжется.'}
                    onClose={() => {
                        setAddBidVisible(false);
                    }}
                >

                    <UpdateBidForm loading={loading} onSave={async d => {
                        await dispatch(bidsActions.createBid({...d, userId: currentUserId, orderId: id}));
                        setAddBidVisible(false);
                    }}/>

                </NiceModalWithHeading>
            }

            {dontHaveProductsModalVisible == false ? null :
                <NiceConfirm heading={'У вас нет предложений'}
                             subHeading={'Сначала создайте Предложение, а потом уже вы сможете откликнуться.'}
                             cancelText={'Отмена'}
                             confirmText={'Добавить предложения'}
                             onCancel={() => {
                                 setDontHaveProductsModalVisible(false);
                             }}
                             onConfirm={() => {
                                 CommonHelper.linkTo(`/sell/products`);
                             }}
                />
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TLink = styled.a`
    text-decoration: underline;
    color: black;
    opacity: 0.7;
    margin-left: 5px;
    margin-right: 5px;
    :hover{
      opacity: 1;
    }
`;

const GLink = styled.a`
    
`;

const TeleLink = styled.a`
    color: #F07967;
    font-weight: bold;
`;

const GuestPlaceholder = styled.div`
    font-size: 20px;
    line-height: 30px;
`;

const BidMessagePlaceholder = styled.div`
    margin-top: 10px;
`;

const OceanButton = styled.div`
    background: #6380F2;
    border-radius: 50px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 35px;
    padding-right: 35px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const BidItem = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media(max-width: 920px){
      width: 100%;
      display: block;
    }
`;

const TopRightFlowPlaceholder = styled.div`
    width: 550px;
    @media(max-width: 920px){
      width: 100%;
      display: block;
    }
`;

const BidsPlaceholder = styled.div`
    margin-top: 20px;
`;


const ProxyItem = styled.div`
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    padding-left: 10px;
    :first-of-type{
      padding-left: 0px;
    }
`;

const UserItem = styled.div`
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 30px;
`;


const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MainInfoItem = styled.div`
  font-size: 36px;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  
  :before {
      content: 'from';
      display: block;
      font-size: 18px;
      margin-right: 10px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const DescriptionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
`;


const DescriptionContent = styled.div`
  line-height: 1.8em;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 20px;
  text-align: justify;
  font-size: 14px;
`;

const StatsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  text-align: center;
`;

const Stats = styled.div`
  background-color: #fbfbfb;
  width: 220px;
  box-sizing: content-box;
  padding: 20px;
`;

const StatsList = styled.div`

`;

const StatsItem = styled.div`
  margin: 10px 0;
  
  span {
    font-weight: bold;
  }
  :hover{
    text-decoration: ${props => (props.canBook == true ? 'underline' : 'none')};
  }
`;


const AchivesList = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
`;

const Achive = styled.img`
  //background-color: red;
  width: 22px;
  height: 26px;
  margin: 0 3px;
`;

const ProfilePic = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f5f2f2;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-image: url(${props => (props.pic == undefined ? voidAva : props.pic)});
`;

const UserDataTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const UserDataTopActionsPlaceholder = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`;

const UserName = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #202224;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: underline;
`;

const LocationList = styled.div`
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     margin-top: 5px;
`;

const Location = styled.div`
    font-size: 15px;
    color: rgba(82,82,82,0.8);
    border-right: 1px solid rgba(82,82,82,0.4);
    padding: 0 10px;
    
    :first-child {
      padding-left: 0;
    }
    
    :last-child {
      padding-right: 0;
      border: none;
    }
`;

const PaymentMethodList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const PaymentMethod = styled.div`
  background-color: #eaeaea; 
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0 5px;
  
  :first-child {
    margin-left: 0;
  }
  
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserData = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 15px;
  flex: 1;
  margin: 10px 0;
`;
