import * as types from '../ActionTypes'
import ls from 'local-storage'

export function setUserRole(role) {
    ls('ui_user_role', role);
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UI_USER_ROLE,
            role: role
        })
    }
}
