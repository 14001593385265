import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CatsAPI = {

    getOrderCats(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/order/${orderId}/cats`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getUserCats(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/cats`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAllCats() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/cats/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getCat(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/cat/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createCat(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/cat`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateCat(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/cat`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteCat(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/cat`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default CatsAPI;
