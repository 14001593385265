import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RoomChatPanel from "./RoomChatPanel";

export default function TwoPeopleChatPanel(props) {
    let {firstUserId, secondUserId} = props;

    let roomName = `${firstUserId}_${secondUserId}`;
    if (secondUserId < firstUserId) {
        roomName = `${secondUserId}_${firstUserId}`;
    }

    return (
        <Wrapper>

            <RoomChatPanel roomId={roomName}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
