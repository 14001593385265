import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import flag from 'country-code-emoji';
import startImg from './../../../assets/images/star.svg';

import * as actions from '../../../redux/actions/UsersActions'
import * as dogsActions from '../../../redux/actions/DogsActions'
import {API_ENDPOINT} from "../../../constants/config";
import SmartSelect from "../../ira/ui/SmartSelect";

import {PAYMENT_OPTIONS} from "../../users/panels/UserUpdatePaymentMethodsPanel";

import {Input, Label} from "../../ira/ui/Inputs";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import UserViewPanel from "../../users/panels/UserViewPanel";
import CommonHelper from "../../../helpers/CommonHelper";

const listsHaveIntersection = (arr1, arr2) => {
    if (arr1 == undefined || arr2 == undefined) {
        return false;
    }
    let f = false;
    for (let i in arr1) {
        let a = arr1[i];
        if (arr2.indexOf(a) > -1) {
            f = true;
            break;
        }
    }
    return f;
};

export default function SearchProviderPanel(props) {
    const {userId} = props;

    const [selectedUserId, setSelectedUserId] = useState(undefined);

    const {
        loading, usersMap, uId, dogs,
        isMe, getDogActiveSnakes,
        bookings, getDog, providers,
        getDogBookings, currentUserId, getUserDogs
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            providers: state.users.usersMap.toArray().filter(a => (a.countries != undefined && a.countries.length > 0)),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            dogs: state.dogs.dogsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            bookings: state.bookings.bookingsMap.toArray()
                .filter(a => (a.sellerId == uId || a.buyerId == uId)).map(a => ({
                    ...a,
                    dog: state.dogs.dogsMap.get(a.dogId)
                })).filter(a => (a.dog != undefined)),
            isMe: uId == state.users.currentUserId,
            getDog: dId => state.dogs.dogsMap.get(dId),
            getUserDogs: aId => state.dogs.dogsMap.toArray().filter(a => (a.userId == aId))
        }
    }, []));
    const dispatch = useDispatch();
    const [baseCountries, setBaseCountries] = useState([]);
    const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

    const [selectedProviderId, setSelectedProviderId] = useState(undefined);

    useEffect(() => {
        dispatch(actions.loadAllProviders());
        dispatch(dogsActions.loadAllDogs());
        axios.get(`${API_ENDPOINT}/countries`).then(a => a.data).then(arr => {
            setBaseCountries(arr);
        })
    }, []);

    const ALL_AVAILABLE_CITIES = dogs.map(a => a.city).reduce((st, a) => st.add(a), Set()).toArray();
    const ALL_AVAILABLE_COUNTRIES = dogs.map(a => a.countryCode).reduce((st, a) => st.add(a), Set()).toArray();
    const avCountSet = dogs.map(a => a.countryCode).reduce((st, a) => st.add(a), Set());

    console.log('render: baseCountries = ', baseCountries);
    console.log('render: dogs = ', dogs);

    const COUNTRIES_OPTIONS = baseCountries.filter(a => avCountSet.has(a.iso)).map(a => ({
        value: a.iso,
        label: `${flag(a.iso)} ${a.name_en}`
    }));

    let fProviders = (selectedCountryCodes.length == 0) ? providers : providers.filter(a => listsHaveIntersection(selectedCountryCodes, a.countries));
    fProviders = (selectedPaymentMethods.length == 0) ? fProviders : fProviders.filter(a => listsHaveIntersection(selectedPaymentMethods, a.paymentMethods));

    let isNotFound = (fProviders.length == 0 && (selectedCountryCodes.length > 0 || selectedPaymentMethods.length > 0));
    let selectedProvider = (selectedProviderId == undefined) ? undefined : providers.filter(a => (a.id == selectedProviderId))[0];

    console.log('render: COUNTRIES_OPTIONS = ', COUNTRIES_OPTIONS);

    return (
        <Wrapper>

            <TopFiltersPlaceholder>
                <TopHalf style={{paddingRight: 5}}>
                    <Label>
                        ГЕО
                    </Label>
                    <SmartSelect options={COUNTRIES_OPTIONS}
                                 selectedValues={selectedCountryCodes}
                                 isMulti={true}
                                 placeholder={'Выбрать страну'}
                                 onSelect={a => {
                                     setSelectedCountryCodes(a);
                                 }}
                    />
                </TopHalf>
                <TopHalf style={{paddingLeft: 5}}>
                    <Label>
                        Способы оплаты
                    </Label>
                    <SmartSelect options={PAYMENT_OPTIONS}
                                 selectedValues={selectedPaymentMethods}
                                 isMulti={true}
                                 placeholder={'Выберите способ оплаты'}
                                 onSelect={a => {
                                     setSelectedPaymentMethods(a);
                                 }}
                    />
                </TopHalf>
            </TopFiltersPlaceholder>

            {isNotFound == false ? null :
                <NotFoundPlaceholder>
                    {'По заданным критериям не найден ни один продавец 😞. Вы можете оставить заявку'}
                </NotFoundPlaceholder>
            }

            <ListPlaceholder>

                {fProviders.map((u, i) => {
                    let {paymentMethods = [], firstName, lastName, countries, avatar, locations = [], about} = u;
                    let canBook = (u.id != currentUserId);
                    let uDogs = getUserDogs(u.id);
                    let isMe = (u.id == currentUserId);
                    return (
                        <UserItem key={`${u.id}_${i}`}>
                            <User>
                                <ProfilePic pic={avatar}/>
                                <UserData>
                                    <UserDataTop>
                                        <UserName>
                                            {CommonHelper.getUserName(u)}
                                            <AchivesList>
                                                <Achive src={require('../../../assets/images/badge.svg')}/>
                                            </AchivesList>
                                        </UserName>

                                        {isMe == true ? null :
                                            <UserDataTopActionsPlaceholder>
                                                <OceanButton onClick={() => {
                                                    // setSelectedProviderId(u.id);
                                                    CommonHelper.linkTo(`/user/${u.id}`, true);
                                                }}>
                                                    ЗАБРОНИРОВАТЬ
                                                </OceanButton>
                                            </UserDataTopActionsPlaceholder>
                                        }
                                    </UserDataTop>

                                    <div>
                                        <LocationList>
                                            {countries.map((a, i) => {
                                                return (
                                                    <Location key={a}>
                                                        {`${flag(a)}`}
                                                        <span style={{marginLeft: 7}}>
                                            {`${a}`}
                                        </span>
                                                    </Location>
                                                )
                                            })}
                                        </LocationList>
                                    </div>
                                    <div>
                                        <PaymentMethodList>
                                            {paymentMethods.map((p, i) => {
                                                return (
                                                    <PaymentMethod key={`${p}_${i}`}>
                                                        {p}
                                                    </PaymentMethod>
                                                )
                                            })}
                                        </PaymentMethodList>
                                    </div>

                                    <div style={{marginTop: 10}}>
                                        <ProxyItem>
                                            {'Прокси: '}
                                        </ProxyItem>
                                        {locations.sort().reverse().map((c, i) => {
                                            let city = c.split(', ')[1];
                                            let countryCode = c.split(', ')[0];
                                            let cDogs = uDogs.filter(a => (a.city == city));
                                            return (
                                                <ProxyItem canBook={canBook} key={c} style={{
                                                    fontWeight: 'normal',
                                                    cursor: (canBook == true ? 'pointer' : 'default')
                                                }} onClick={() => {
                                                    // if (canBook == true) {
                                                    //     onBook({
                                                    //         city: city,
                                                    //         countryCode: countryCode
                                                    //     })
                                                    // }
                                                }}>
                                                    <span style={{fontWeight: 'normal'}}>{`${c}:`}</span> <span
                                                    style={{fontWeight: 'bold'}}>{cDogs.length}</span>
                                                </ProxyItem>
                                            )
                                        })}
                                    </div>

                                </UserData>

                            </User>

                            <Info>
                                <Description>
                                    <DescriptionContent style={{marginBottom: 20}}>
                                        {about}
                                    </DescriptionContent>
                                </Description>
                            </Info>

                        </UserItem>
                    )
                })}

            </ListPlaceholder>

            {selectedProvider == undefined ? null :
                <BrutalOverlayModal onClose={() => {
                    setSelectedProviderId(undefined)
                }}>

                    <UserViewPanel userId={selectedProviderId}/>

                </BrutalOverlayModal>
            }

        </Wrapper>
    );
}

const OceanButton = styled.div`
    background: #6380F2;
    border-radius: 50px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 35px;
    padding-right: 35px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const NotFoundPlaceholder = styled.div`
    text-align: center;
    padding: 20px;
    font-size: 24px;
    line-height: 32px;
`;

const TopFiltersPlaceholder = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: stretch;  
`;

const TopHalf = styled.div`
    flex: 1;
    box-sizing: border-box;
`;

const ProxyItem = styled.div`
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    padding-left: 10px;
    :first-of-type{
      padding-left: 0px;
    }
`;

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const UserItem = styled.div`
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 30px;
`;


const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MainInfoItem = styled.div`
  font-size: 36px;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  
  :before {
      content: 'from';
      display: block;
      font-size: 18px;
      margin-right: 10px;
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  :before {
    content: '';
    background-image: url(${startImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 25px;
    width: 25px;
    display: block;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const DescriptionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
`;


const DescriptionContent = styled.div`
  line-height: 1.8em;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 20px;
  text-align: justify;
  font-size: 14px;
`;

const StatsTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  text-align: center;
`;

const Stats = styled.div`
  background-color: #fbfbfb;
  width: 220px;
  box-sizing: content-box;
  padding: 20px;
`;

const StatsList = styled.div`

`;

const StatsItem = styled.div`
  margin: 10px 0;
  
  span {
    font-weight: bold;
  }
  :hover{
    text-decoration: ${props => (props.canBook == true ? 'underline' : 'none')};
  }
`;


const AchivesList = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
`;

const Achive = styled.img`
  //background-color: red;
  width: 22px;
  height: 26px;
  margin: 0 3px;
`;

const ProfilePic = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f5f2f2;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-image: url(${props => props.pic});
`;

const UserDataTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const UserDataTopActionsPlaceholder = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`;

const UserName = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #202224;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const LocationList = styled.div`
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     margin-top: 5px;
`;

const Location = styled.div`
    font-size: 15px;
    color: rgba(82,82,82,0.8);
    border-right: 1px solid rgba(82,82,82,0.4);
    padding: 0 10px;
    
    :first-child {
      padding-left: 0;
    }
    
    :last-child {
      padding-right: 0;
      border: none;
    }
`;

const PaymentMethodList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const PaymentMethod = styled.div`
  background-color: #eaeaea; 
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0 5px;
  
  :first-child {
    margin-left: 0;
  }
  
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserData = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 15px;
  flex: 1;
  margin: 10px 0;
`;
