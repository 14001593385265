import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const ITEMS = [
    {
        label: 'Покупатель создал объявление',
        description: ''
    },
    {
        label: 'Откликнитесь на объявление покупателя',
        description: 'Предложите покупателю свои прокси.'
    },
    {
        label: 'Согласуйте условия',
        description: 'Свяжитесь с покупателем через указанный им Telegram, обсудите условия. Предложите бесплатный тестовый период.'
    },
    {
        label: 'Выдан тестовый период',
        description: 'По завершению тестового периода, запросите оплату и выдайте прокси'
    },
    {
        label: 'Прокси выданы',
        description: 'Покупатель пользуется вашими прокси. Следите за стабильностью соединения. Будьте на связи с покупателем, чтобы предоставлять качественный сервис и по завершении сделки получить положительный отзыв. В случае необходимости согласуйте продление сделки.'
    },
    {
        label: 'Завершение сделки',
        description: 'Оставьте отзывы друг о друге'
    }
];

export default function OrderFlowTool(props) {
    const {hasBid = false} = props;

    let num = 0;
    if (hasBid == true) {
        num = 1;
    }

    return (
        <Wrapper>

            <ItemsPlaceholder>

                {ITEMS.map((it, i) => {
                    return (
                        <Item key={`a_${i}`}>
                            <ItemLeft>
                                <NumberCircle selected={(i <= num)}>
                                    {+i + 1}
                                </NumberCircle>
                            </ItemLeft>
                            <ItemRight>
                                <ItemName>
                                    {it.label}
                                </ItemName>
                                <ItemDescription>
                                    {it.description}
                                </ItemDescription>
                            </ItemRight>
                        </Item>
                    )
                })}

            </ItemsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsPlaceholder = styled.div`
    
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid whitesmoke;
`;

const ItemName = styled.div`
    font-weight: bold;
`;

const ItemDescription = styled.div`
    
`;

const NumberCircle = styled.div`
    width: 30px;
    height: 30px;
    font-weight: bold;
    border-radius: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid lightgrey;
    background: ${props => (props.selected == true ? 'green' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
`;

const ItemLeft = styled.div`
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ItemRight = styled.div`
    flex: 1;
`;
