import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Label, Input, Textarea} from "../../ira/ui/Inputs";

import {RedButton} from "../../ira/ui/Buttons";

export default function UpdateBidForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;
    const [message, setMessage] = useState(props.message == undefined ? '' : props.message);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Ваше сообщение
                </Label>
                <Textarea placeholder={'Попробуйте заинтересовать покупателя =)'} value={message} onChange={evt => {
                    setMessage(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <RedButton onClick={() => {
                        onSave({
                            message: message
                        });
                    }}>
                        Отправить
                    </RedButton>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    
`;
