import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import ReshakaTemplate from "../templates/ReshakaTemplate";
import AllOrdersPanel from "../orders/panels/AllOrdersPanel";
import UserDogsPanel from "../dogs/panels/UserDogsPanel";
import UserProductsPanel from "../products/panels/UserProductsPanel";

export default function SellerProductsApp(props) {
    let {mode} = props.match.params;
    const {currentUserId} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        return {
            currentUserId: state.users.currentUserId,
        }
    }, []));
    const dispatch = useDispatch();

    return (
        <ReshakaTemplate active={'products'}>

            <Wrapper>

                <UserProductsPanel/>

            </Wrapper>

        </ReshakaTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 1200px){
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const topHeight = 70;

