import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {createFilter} from "react-select";
import SimpleDateSelector from "../../ira/calendars/SimpleDateSelector";

import {Label, Input, Textarea} from "../../ira/ui/Inputs";
import NiceConfirm from "../../modals/NiceConfirm";

import {BlueButton} from "../../ira/ui/Buttons";

import Select from 'react-select'

const PROXY_TYPES_OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'HTTP',
        value: 'http'
    }
];

export default function DogAccessForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [buyerId, setBuyerId] = useState(props.buyerId == undefined ? '' : props.buyerId);
    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'http' : props.proxyType);
    const [fromTimestamp, setFromTimestamp] = useState(props.fromTimestamp == undefined ? +moment().startOf('day') : props.fromTimestamp);
    const [toTimestamp, setToTimestamp] = useState(props.toTimestamp == undefined ? +moment().add(7, 'days').startOf('day') : props.toTimestamp);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    return (
        <Wrapper>

            <Row>

                <div style={{width: 165, marginRight: 5}}>
                    <Field>
                        <Label>
                            ID покупателя
                        </Label>
                        <Input value={buyerId} style={{height: 38}} onChange={evt => {
                            setBuyerId(evt.target.value.replace(/ /g, ''));
                        }}/>
                    </Field>
                </div>

                <Half style={{paddingRight: 4}}>
                    <Field>
                        <Label>
                            Дата начала доступа
                        </Label>
                        <SimpleDateSelector
                            selectedTimestamp={fromTimestamp}
                            onChange={a => {
                                setFromTimestamp(a);
                            }}
                        />
                    </Field>
                </Half>

                <Half style={{paddingLeft: 4}}>
                    <Field>
                        <Label>
                            Дата окончание доступа
                        </Label>
                        <SimpleDateSelector
                            selectedTimestamp={toTimestamp}
                            onChange={a => {
                                console.log(`setting to timestamp = ${new Date(a)}`);
                                setToTimestamp(a);
                            }}
                        />
                    </Field>
                </Half>

                {/*<div style={{width: 115, marginLeft: 5}}>*/}
                {/*<Field>*/}
                {/*<Label>*/}
                {/*Тип прокси*/}
                {/*</Label>*/}
                {/*<Select options={PROXY_TYPES_OPTIONS}*/}
                {/*value={PROXY_TYPES_OPTIONS.filter(a => (a.value == proxyType))[0]}*/}
                {/*onChange={a => {*/}
                {/*setProxyType(a.value);*/}
                {/*}}/>*/}
                {/*</Field>*/}
                {/*</div>*/}

                <div style={{width: 175, marginLeft: 5}}>
                    <Label style={{opacity: 0}}>
                        _
                    </Label>
                    <BlueButton loading={loading} style={{height: 38}} onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSave({
                            buyerId,
                            fromTimestamp,
                            toTimestamp,
                            proxyType
                        });
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null : 'Дать доступ'}
                    </BlueButton>
                </div>

            </Row>

        </Wrapper>
    );
}

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    :hover{
      opacity: 0.9;
    }
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;
`;
