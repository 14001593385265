import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../helpers/CommonHelper";

import * as uiActions from '../../redux/actions/UiActions'
import IraGuestTemplate from "./IraGuestTemplate";
import NotificationsBubble from "../notifications/panels/NotificationsBubble";

const voAva = require('../../assets/images/vo_ava.jpg');

const LabRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CircSpan = styled.div`
    margin-left: 5px;
    font-weight: bold;
`;

const BUYER_TABS = [
    {
        label: 'Найти продавцов',
        value: 'index',
        link: '/'
    },
    {
        label: (
            <LabRow>
                <span>
                    Мои прокси
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_cat']}/>
                </CircSpan>
            </LabRow>
        ),
        value: 'proxy',
        link: '/proxy'
    },
    {
        label: (
            <LabRow>
                <span>
                    Мои объявления
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_order_proposal']}/>
                </CircSpan>
            </LabRow>
        ),
        link: '/orders',
        value: 'orders',

    }
];

const SELLER_TABS = [
    {
        label: 'Найти покупателя',
        value: 'index',
        link: '/'
    },

    {
        label: (
            <LabRow>
                <span>
                    Мои объявления
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_incoming_deal']}/>
                </CircSpan>
            </LabRow>
        ),
        value: 'products',
        link: '/products',
    },

    {
        label: 'Мои прокси',
        value: 'proxy',
        link: '/proxy',
    },
]

export default function ReshakaTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading, userRole} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading,
        userRole: state.ui.userRole
    }), []));


    if (currentUser == undefined) {
        return (
            <IraGuestTemplate>
                {props.children}
            </IraGuestTemplate>
        );
    }

    let {firstName, avatar, telegramChatIds = []} = currentUser;
    let tabs = (userRole == 'buyer') ? BUYER_TABS : SELLER_TABS;
    let hasTelegram = (currentUser.telegramChatIds != undefined && currentUser.telegramChatIds.length > 0);

    return (
        <Wrapper>

            <HeadingSection>
                <HeadingInner>
                    <VeryTopLeftPlaceholder>
                        <LogoImg src={require('../../assets/images/brok_lo.png')} onClick={() => {
                        // <LogoImg src={require('./images/EDCT.svg')} onClick={() => {
                            CommonHelper.linkTo('/');
                        }}/>
                        {tabs.map((a, i) => {
                            return (
                                <TabItem selected={(active == a.value)} onClick={() => {
                                    CommonHelper.linkTo(`${a.link}`);
                                }}>
                                    {a.label}
                                </TabItem>
                            )
                        })}

                    </VeryTopLeftPlaceholder>
                    <VeryTopRightPlaceholder>

                        <SwitcherPlaceholder>
                            <SwitchItem selected={(userRole == 'buyer')} onClick={() => {
                                dispatch(uiActions.setUserRole('buyer'));
                                CommonHelper.linkTo(`/`);
                            }}>
                                купить
                                <NotificationsBubble style={{marginLeft: 5}} types={['new_cat', 'new_order_proposal']}/>
                            </SwitchItem>
                            <SwitchItem selected={(userRole == 'seller')} onClick={() => {
                                dispatch(uiActions.setUserRole('seller'));
                                CommonHelper.linkTo(`/`);
                            }}>
                                продать
                                <NotificationsBubble style={{marginLeft: 5, fontWeight: 'bold'}}
                                                     types={['new_incoming_deal']}/>
                            </SwitchItem>
                        </SwitcherPlaceholder>

                        <CurrentUserPlaceholder onClick={() => {
                            CommonHelper.linkTo(`/settings`);
                        }}>
                            <Avatar src={avatar}/>
                            <CurrentUserName>
                                {CommonHelper.getUserName(currentUser)}
                                {hasTelegram == true ? null :
                                    <span style={{marginLeft: 3}}>
                                        {'⚠️'}
                                    </span>
                                }

                            </CurrentUserName>
                        </CurrentUserPlaceholder>


                    </VeryTopRightPlaceholder>
                </HeadingInner>
            </HeadingSection>

            <ContentSection>
                {props.children}
            </ContentSection>

        </Wrapper>
    );
}

const ContentInner = styled.div`
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: 1200px){
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const TabItem = styled.div`
    margin-right: 30px;
    color: #333333;
    font-size: 16px;
    line-height: 32px;
    cursor: pointer;
    border-bottom: 1px solid ${props => (props.selected == true ? '#FF911C' : 'transparent')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    
    :hover{
      border-bottom: 1px solid #FF911C;
    }
`;

const SwitcherPlaceholder = styled.div`
    height: 40px;
    border-radius: 1000px;
    border: 1px solid #FF911C;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-right: 10px;
`;

const SwitchItem = styled.div`
    border-radius: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    
    background: ${props => (props.selected ? '#FF911C' : 'transparent')};
    color: ${props => (props.selected ? '#FFFFFF' : 'black')};
    opacity: ${props => (props.selected ? 1 : 0.5)};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :first-of-type{
      
    }
    :last-of-type{
      
    }
    :hover{
      color: ${props => (props.selected == true ? '#FFFFFF' : '#FF911C')};
      opacity: 1;
    }
`;

const CurrentUserPlaceholder = styled.div`
    padding-left: 10px;
    border-left: 1px solid whitesmoke;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Avatar = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 1000px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => (props.src == undefined ? voAva : props.src)});
    margin-right: 5px;
`;

const CurrentUserName = styled.div`
    
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;  
    background: #F7F8FA;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family: Montserrat;
`;

const HeadingSection = styled.div`
    height: 60px;
    width: 100vw;
    background: white;
    box-sizing: border-box;
    border-bottom: 1px solid #EAEDF3;    
`;

const HeadingInner = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 1200px){
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const ContentSection = styled.div`
    flex: 1;
    padding-top: 20px;
    overflow-y: auto;
`;

const LogoImg = styled.img`
    cursor: pointer;
    height: 20px;
    margin-right: 30px;
    :hover{
      opacity: 0.9;
    }
    @media(max-width: 820px){display: none;}
`;

const VeryTopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const VeryTopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProfilePlaceholder = styled.div`
    
`;

// const RIGHT_LINKS = [
//     {
//         name: 'index',
//         link: '/',
//         label: (
//             <LabRow>
//                 <span>
//                     Купить прокси
//                 </span>
//                 <CircSpan>
//                     <NotificationsBubble types={['new_cat', 'new_order_proposal']}/>
//                 </CircSpan>
//             </LabRow>
//         ),
//         emojiIcon: require('./images/icons/emoji_home.svg'),
//         selectedIcon: require('./images/icons/sel_home.svg'),
//         icon: require('./images/icons/home.svg'),
//         isTop: true
//     },
//     {
//         name: 'sell',
//         link: '/sell',
//         label: (
//             <LabRow>
//                 <span>
//                     Продать прокси
//                 </span>
//                 <CircSpan>
//                     <NotificationsBubble types={['new_incoming_deal']}/>
//                 </CircSpan>
//             </LabRow>
//         ),
//         emojiIcon: require('./images/icons/mon_bag.svg'),
//         selectedIcon: require('./images/icons/sel_calendar.svg'),
//         icon: require('./images/icons/calendar.svg'),
//         isTop: true
//     }
// ];
