import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ToolsAPI = {

    async getProxyIpInfo(data) {
        let {ip, address, port, proxyType = 'socks5', login, password} = data;
        let rIp = ip || address;
        let d = (await axios.get(`${API_ENDPOINT}/check_proxy`, {
            params: {
                ip: rIp,
                port: port,
                proxyType: proxyType,
                login: login,
                password: password
            }
        })).data.result;
        return d;
    },

    async getProxyIpByDetailsData(data) {
        try {
            let {ip, address, port, proxyType = 'socks5', login, password} = data;
            let rIp = ip || address;
            let d = (await axios.get(`${API_ENDPOINT}/check_proxy`, {
                params: {
                    ip: rIp,
                    port: port,
                    proxyType: proxyType,
                    login: login,
                    password: password
                }
            })).data.result;
            d = JSON.parse(d);
            return d.ip;
        } catch (e) {
            return undefined;
        }
    },

    async getMobIpWithTries(data, triesNumber = 7, dt = 3 * 1000, skipIp) {
        await delay(dt);
        let result = undefined;
        for (let i = 0; i < triesNumber; i++) {
            result = await this.getProxyIpByDetailsData(data);
            if (result != undefined && (skipIp != undefined && result != skipIp)) {
                break;
            }
            await delay(dt);
        }
        return result;
    },

    async getDogMobIP(dogId) {
        let ip = undefined;
        try {
            ip = (await axios.get(`${API_ENDPOINT}/dog/${dogId}/mob_ip`)).data.result.ip;
        } catch (ee) {

        }
        return ip;
    },


    async checkIfChangeIpWorking(data) {
        let currentIp = undefined, nextIp = undefined;
        try {
            currentIp = await this.getProxyIpByDetailsData(data);
            let pld = (await axios.post(`${API_ENDPOINT}/tools/change_ip`, data)).data;
            nextIp = await this.getMobIpWithTries(data, 10, 4 * 1000, currentIp); // 7 tries with 4 seconds in between
        } catch (e) {

        }
        console.log('checkIfChangeIpWorking: currentIp, nextIp = ', currentIp, nextIp);
        return (nextIp != currentIp && currentIp != undefined && nextIp != undefined);
    }


}

export default ToolsAPI;

function delay(time) {
    return new Promise(function (resolve) {
        setTimeout(resolve, time)
    });
}
