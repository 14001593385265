import BidsAPI from '../../api/BidsAPI'

import * as types from '../ActionTypes'

let loadBids_ = () => {
    return {
        type: types.LOAD_BIDS
    }
}
let loadBidsSuccess = (bids) => {
    return {
        type: types.LOAD_BIDS_SUCCESS,
        bids: bids
    }
}
let loadBidsFailed = (error) => {
    return {
        type: types.LOAD_BIDS_FAIL,
        error: error
    }
}

//thunk
export function loadAllBids() {
    return (dispatch, getState) => {
        dispatch(loadBids_());
        return BidsAPI.getAllBids().then(
            bids => dispatch(loadBidsSuccess(bids)),
            error => dispatch(loadBidsFailed(error))
        )
    }
}

export function loadBidById(id) {
    return (dispatch, getState) => {
        dispatch(loadBids_());
        return BidsAPI.getBid(id).then(
            bid => dispatch(loadBidsSuccess([bid])),
            error => dispatch(loadBidsFailed(error))
        )
    }
}

let createBid_ = () => {
    return {
        type: types.CREATE_BID
    }
}
let createBidSuccess = (bid) => {
    return {
        type: types.CREATE_BID_SUCCESS,
        bid: bid
    }
}
let createBidFailed = (error) => {
    return {
        type: types.CREATE_BID_FAIL,
        error: error
    }
}

//thunk
export function createBid(data) {
    return (dispatch, getState) => {
        dispatch(createBid_());
        return BidsAPI.createBid(data).then(
            bid => dispatch(createBidSuccess(bid)),
            error => dispatch(createBidFailed(error))
        )
    }
}

let updateBid_ = () => {
    return {
        type: types.UPDATE_BID
    }
}
let updateBidSuccess = (bid) => {
    return {
        type: types.UPDATE_BID_SUCCESS,
        bid: bid
    }
}
let updateBidFailed = (error) => {
    return {
        type: types.UPDATE_BID_FAIL,
        error: error
    }
}

//thunk
export function updateBid(data) {
    return (dispatch, getState) => {
        dispatch(updateBid_());
        return BidsAPI.updateBid(data).then(
            bid => dispatch(updateBidSuccess(bid)),
            error => dispatch(updateBidFailed(error))
        )
    }
}

let deleteBid_ = () => {
    return {
        type: types.DELETE_BID
    }
}
let deleteBidSuccess = (id) => {
    return {
        type: types.DELETE_BID_SUCCESS,
        id: id
    }
}
let deleteBidFailed = (error) => {
    return {
        type: types.DELETE_BID_FAIL,
        error: error
    }
}

//thunk
export function deleteBid(id) {
    return (dispatch, getState) => {
        dispatch(deleteBid_());
        return BidsAPI.deleteBid(id).then(
            () => dispatch(deleteBidSuccess(id)),
            error => dispatch(deleteBidFailed(error))
        )
    }
}
