import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ls from 'local-storage'

import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";

export default function IproxyConnectionsPanel(props) {
    const {
        onProxySelect = d => {
            console.log('onProxySelect: d = ', d);
        }
    } = props;


    const [apiKey, setApiKey] = useState(ls('iproxy_api_key'));
    const [loading, setLoading] = useState(false);
    const [connections, setConnections] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);
    const [proxies, setProxies] = useState([]);

    useEffect(() => {
        if (apiKey == undefined || apiKey.length < 10) {
            return;
        }
        setLoading(true);
        axios.get(`https://api.iproxy.online/v1/connections`, {
            headers: {
                'Authorization': apiKey
            }
        }).then(d => d.data.result).then(arr => {
            setConnections(arr);
            setLoading(false);
        }).catch(err => {
            // window.alert(err.message);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (selectedId == undefined || apiKey == undefined || apiKey.length < 10) {
            return;
        }
        setProxies([]);
        setLoading(true);
        axios.get(`https://api.iproxy.online/v1/connections/${selectedId}/proxies`, {
            headers: {
                'Authorization': apiKey
            }
        }).then(d => d.data.result).then(arr => {
            setProxies(arr);
            setLoading(false);
        }).catch(err => {
            window.alert(err.message);
            setLoading(false);
        });
    }, [selectedId]);

    let selectedConnection = (selectedId == undefined) ? undefined : connections.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            {connections.length > 0 ?
                <TopPlaceholder style={{marginTop: 10}}>
                    <span>
                    {`Ключ iProxy`}
                        <b style={{marginLeft: 5}}>
                            {`${apiKey}`}
                        </b>
                    </span>

                    <span style={{marginLeft: 10, cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                        setConnections([]);
                    }}>
                        использовать другой ключ
                    </span>

                </TopPlaceholder>
                :
                <TopPlaceholder>
                    <TopLeft>
                        <Input value={apiKey}
                               disabled={loading}
                               placeholder={'iProxy API Key'}
                               onChange={evt => {
                                   setApiKey(evt.target.value);
                                   ls('iproxy_api_key', evt.target.value);
                                   setConnections([]);
                               }}/>
                    </TopLeft>
                    <TopRight>
                        <Spin visible={loading || props.loading}/>
                        {loading == true || props.loading == true ? null :
                            <BlueButton onClick={async () => {
                                if (apiKey == undefined || apiKey.length < 5) {
                                    return;
                                }
                                setLoading(true);
                                try {
                                    let pld = await axios.get(`https://api.iproxy.online/v1/connections`, {
                                        headers: {
                                            'Authorization': apiKey
                                        }
                                    });
                                    setConnections(pld.data.result);
                                    setLoading(false);
                                } catch (exc) {
                                    console.log('exc');
                                    setLoading(false);
                                }
                            }}>
                                Load
                            </BlueButton>
                        }
                    </TopRight>
                </TopPlaceholder>
            }

            <Content>
                <Half>
                    <ConnectionsList>
                        {connections.map((a, i) => {
                            return (
                                <ConnectionItem key={a.id}
                                                selected={(selectedId == a.id)}
                                                onClick={() => {
                                                    setSelectedId(a.id);
                                                }}>
                                    <ItemName>
                                        {a.name}
                                    </ItemName>
                                    {a.description == undefined ? null :
                                        <ItemDescription>
                                            {a.description}
                                        </ItemDescription>
                                    }
                                </ConnectionItem>
                            )
                        })}
                    </ConnectionsList>
                </Half>

                {selectedConnection == undefined ? null :
                    <Half key={selectedId}>
                        <ConnectionsList>
                            <Spin visible={loading || props.loading}/>

                            {proxies.map((a, i) => {
                                return (
                                    <ConnectionItem key={a.id}
                                                    onClick={() => {
                                                        onProxySelect({...a, changeIpUrl: selectedConnection.changeIpUrl});
                                                    }}>
                                        <ItemName>
                                            {`${a.type}://${a.login}:${a.password}@${a.ip}:${a.port}`}
                                        </ItemName>
                                        {a.description == undefined ? null :
                                            <ItemDescription>
                                                {a.description}
                                            </ItemDescription>
                                        }
                                    </ConnectionItem>
                                )
                            })}
                        </ConnectionsList>
                    </Half>
                }

            </Content>

        </Wrapper>
    );
}

const ConnectionItem = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    color: ${props => (props.selected == true ? 'white' : 'black')};
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :hover{
      opacity: 0.8;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ItemName = styled.div`
    font-weight: bold;
`;

const ItemDescription = styled.div`
    opacity: 0.5;
    font-size: 12px;
`;

const ConnectionsList = styled.div`
    
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    overflow-y: auto;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    :first-of-type{
      padding-right: 10px;
      border-right: 1px solid lightgrey;
    }
    :last-of-type{
      padding-left: 10px;
    }
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;

const TopLeft = styled.div`
    flex: 1;
`;

const TopRight = styled.div`
    margin-left: 20px;
`;
