import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from "styled-components";
import {Label} from "../../ira/ui/Inputs";
import Select from "react-select";

import Spin from 'arui-feather/spin'

import {BlueButton} from "../../ira/ui/Buttons";

const PROXY_TYPES_OPTIONS = [
    {
        label: 'Socks5',
        value: 'socks5'
    },
    {
        label: 'HTTP',
        value: 'http'
    }
];


export default function BookUserProxyForm(props) {
    const {
        locations = [],
        canBook = true,
        onBook = d => {

        },
        loading = false
    } = props;
    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'socks5' : props.proxyType);
    const [location, setLocation] = useState(props.location);

    console.log('BookUserProxyForm: render: locations = ', locations);

    const LOCATION_OPTIONS = locations.map(a => ({value: a, label: a}));

    useEffect(() => {
        let fLocation = (locations.length == 0) ? undefined : locations[0];
        setLocation(fLocation);
    }, [locations]);

    return (
        <Wrapper>
            <Title>Book proxy</Title>

            <div>
                <Field>
                    <Label>
                        Type:
                    </Label>
                    <Select options={PROXY_TYPES_OPTIONS}
                            value={PROXY_TYPES_OPTIONS.filter(a => (a.value == proxyType))[0]}
                            onChange={a => {
                                setProxyType(a.value);
                            }}/>
                </Field>
                <Field>
                    <Label>
                        Proxy location:
                    </Label>
                    <Select options={LOCATION_OPTIONS}
                            value={LOCATION_OPTIONS.filter(a => (a.value == location))[0]}
                            onChange={a => {
                                setLocation(a.value);
                            }}/>
                </Field>
                {/*<Field>*/}
                {/*<Label>*/}
                {/*City:*/}
                {/*</Label>*/}
                {/*<Select options={[]}*/}
                {/*value={[]}*/}
                {/*onChange={a => {*/}
                {/*}}/>*/}
                {/*</Field>*/}
                {canBook == false ? null :
                    <BlueButton onClick={() => {
                        if (location == undefined) {
                            return;
                        }
                        let countryCode = location.split(', ')[0];
                        let city = location.split(', ')[1];
                        onBook({
                            city: city,
                            countryCode: countryCode
                        })
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null : 'Book'}
                    </BlueButton>
                }
            </div>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const Reservation = styled.div`
    background: #6380F2;
    border-radius: 50px;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;    
    
    :hover{
        background: #FFA71B;
    }
`;
