import moment from 'moment-timezone'
import {API_ENDPOINT, DEFAULT_TIMEZONE, UNITPAY_ID} from "../constants/config";
import axios from 'axios'

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

const CommonHelper = {

        linkTo(patientUrl, inNewTab = false) {
            let url = window.location.origin + window.location.pathname + '#' + patientUrl;
            if (inNewTab == false) {
                window.location.href = url;
            } else {
                let win = window.open(url, '_blank');
                win.focus();
            }
        },

        getRuMonthByTimestamp(t) {
            let m = +moment(t).tz(DEFAULT_TIMEZONE).format('M') - 1;
            return RU_MONTHS_ARRAY[+m];
        },

        getCurrentUserRole(state) {
            if (state == undefined) {
                return false;
            }
            let {currentUserId, usersMap} = state.users;
            let currentUser = usersMap.get(currentUserId);
            if (currentUser == undefined) {
                return undefined;
            }
            return currentUser.userRole;
        },

        getRuNameOfRole(role) {
            let map = {
                franchisee: 'Франчайзи',
                admin: 'Админ',
                teacher: 'Тренер',
                student: 'Ученик',
            }
            return map[role];
        },

        getUserName(user) {
            if (user == undefined) {
                return 'Guest';
            }
            let ie = a => (a == undefined || a.trim() == '');
            let {firstName, lastName, email, id} = user;
            if (ie(firstName)) {
                // if (email == undefined) {
                //     return id;
                // }
                // return email;
                return `Имя не указано`
            }
            return `${firstName}`;
        },

        async getReplenishmentUrl(userId, sum) {
            // sha256
            let signature = (await axios.get(`${API_ENDPOINT}/signature`, {
                params: {
                    account: userId,
                    sum: sum
                }
            })).data.result;
            let url = `https://unitpay.ru/pay/${UNITPAY_ID}?sum=${sum}&account=${userId}&desc=${sum}&signature=${signature}&currency=RUB`;
            return url;
        },

        listsHaveIntersection(arr1, arr2) {
            if (arr1 == undefined || arr2 == undefined) {
                return false;
            }
            let f = false;
            for (let i in arr1) {
                let a = arr1[i];
                if (arr2.indexOf(a) > -1) {
                    f = true;
                    break;
                }
            }
            return f;
        }

    }
;

export default CommonHelper;
