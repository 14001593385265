import i18n from "i18next";

export const DEFAULT_TIMEZONE = 'Europe/Moscow';
export const UNITPAY_ID = '223931-10204';

export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';

export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

// export const API_ENDPOINT = 'https://proxy.broker/api';
export const API_ENDPOINT = 'https://master.pbroker.zprx.net/api';

export const FIREBASE_CREDENTIALS = {
    apiKey: "AIzaSyCkMiS1PbE38yJ6SJ7rm-VzZBLV0pFFQaM",
    authDomain: "realtimechess-a9566.firebaseapp.com",
    databaseURL: "https://realtimechess-a9566.firebaseio.com",
    projectId: "realtimechess-a9566",
    storageBucket: "",
    messagingSenderId: "397914717078"
};

function buildTemplate(time) {
    let timeDefenition = '';
    if (i18n.language === 'en') {
        timeDefenition = time <= 1 ? 'minute' : 'minutes';
    } else {
        timeDefenition = time >= 5 || time === 0
            ? 'минут'
            : time === 1
                ? 'минута'
                : 'минуты'
    }
    return i18n.language === 'en' ? `every ${time} ${timeDefenition}` : `каждые ${time} ${timeDefenition}`;
}

export const IP_CHANGE_MINUTES = [
    {
        label: buildTemplate(2),
        value: 2
    },
    {
        label: buildTemplate(4),
        value: 4
    },
    {
        label: buildTemplate(5),
        value: 5
    },
    {
        label: buildTemplate(10),
        value: 10
    },
    {
        label: buildTemplate(15),
        value: 15
    },
    {
        label: buildTemplate(30),
        value: 30
    },
    {
        label: buildTemplate(45),
        value: 45
    },
    {
        label: buildTemplate(60),
        value: 60
    },
    {
        label: buildTemplate(120),
        value: 120
    },
];

