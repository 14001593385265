import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateProfilePanel from "../users/panels/UpdateProfilePanel";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import UserProfileBalancePanel from "../users/panels/UserProfileBalancePanel";
import UpdateUpdatePaymentMethodsPanel from "../users/panels/UserUpdatePaymentMethodsPanel";
import ReshakaTemplate from "../templates/ReshakaTemplate";
import * as usersActions from "../../redux/actions/UsersActions";
import UserTelegramPanel from "../telegram/panels/UserTelegramPanel";

const TABS = [
    {
        label: 'Профиль',
        value: 'profile'
    },
    {
        label: 'Способы оплаты',
        value: 'payment_methods'
    },

    // {
    //     label: 'About me',
    //     value: 'about'
    // },
    {
        label: 'Баланс',
        value: 'balance',
    },
]

export default function SettingsApp(props) {
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'profile' : mode);
    const {currentUserId, currentUserEmail, telegramChatIds} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let currentUserEmail = (currentUser == undefined) ? undefined : currentUser.email;
        let telegramChatIds = (currentUser == undefined) ? [] : currentUser.telegramChatIds
        return {
            currentUserId: state.users.currentUserId,
            currentUserEmail: currentUserEmail,
            telegramChatIds: telegramChatIds
        }
    }, []));
    const dispatch = useDispatch();
    let hasTelegram = (telegramChatIds != undefined && telegramChatIds.length > 0);

    return (
        <ReshakaTemplate active={'settings'}>

            <HorHeading>
                <HorHeadingInner>
                    <TopLeft>
                        {TABS.map((a, i) => {
                            return (
                                <TabItem selected={(tab == a.value)} key={a.value} onClick={() => {
                                    setTab(a.value);
                                    // CommonHelper.linkTo(`/settings/${a.value}`);
                                }}>
                                    {a.label}
                                </TabItem>
                            )
                        })}
                        <TabItem selected={(tab == 'telegram')} onClick={() => {
                            setTab('telegram');
                        }}>
                            {`Telegram ${hasTelegram == true ? '' : '⚠️'}`}
                        </TabItem>

                    </TopLeft>
                    <TopRight>
                        <div style={{marginRight: 25, opacity: 0.3}}>
                            {`ID: `}
                            <span style={{fontWeight: 'bold'}}>
                                {currentUserId}
                            </span>
                        </div>
                        <TabItem style={{marginRight: 0}} onClick={async () => {
                            let pld = await dispatch(usersActions.logOut());
                            if (pld.error == undefined) {
                                CommonHelper.linkTo('/');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }
                        }}>
                            Выход
                        </TabItem>
                    </TopRight>
                </HorHeadingInner>

            </HorHeading>

            <Wrapper key={tab}>

                {tab != 'profile' ? null :
                    <>
                    <Content>
                        <SubHeading style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                Общая информация
                            </div>
                            <div>
                                {`Email: ${currentUserEmail}`}
                            </div>
                        </SubHeading>
                        <UpdateProfilePanel/>
                    </Content>
                    <Content>
                        <SubHeading>
                            Способы оплаты
                        </SubHeading>
                        <UpdateUpdatePaymentMethodsPanel/>
                    </Content>
                    </>

                }

                {tab != 'balance' ? null :
                    <>
                    <UserProfileBalancePanel/>
                    </>
                }

                {tab != 'payment_methods' ? null :
                    <Content>
                        <SubHeading>
                            Способы оплаты
                        </SubHeading>
                        <UpdateUpdatePaymentMethodsPanel/>
                    </Content>
                }

                {tab != 'telegram' ? null :
                    <Content>
                        <SubHeading>
                            Telegram
                        </SubHeading>
                        <UserTelegramPanel/>
                    </Content>
                }



            </Wrapper>

        </ReshakaTemplate>
    );
}

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 20px;
    @media(max-width: 1200px){
      padding-left: 20px;
      padding-right: 20px;
    }
`;

const topHeight = 50;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: -20px;
`;

const HorHeadingInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: ${topHeight}px;
    
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 1200px){
      padding-left: 20px;
      padding-right: 20px;
    }
    
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#020089' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 40px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
    margin-bottom: 30px;
    :last-of-type{
      margin-bottom: 10px;
    }
`;

