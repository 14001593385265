import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {FACELESS_AVATAR} from "../../constants/config";
import CommonHelper from "../../helpers/CommonHelper";
import * as usersActions from "../../redux/actions/UsersActions";
import IraGuestTemplate from "./IraGuestTemplate";
import NotificationsBubble from "../notifications/panels/NotificationsBubble";

const telegaImg = require('../../assets/images/telega_ico.svg')

export default function IraTeacherTemplate(props) {
    const {
        active = 'index',
        hasLeft = true
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        loading: state.users.loading
    }), []));

    if (currentUser == undefined) {
        return (
            <IraGuestTemplate>
                {props.children}
            </IraGuestTemplate>
        );
    }

    console.log('template: render: currentUser = ', currentUser);

    const {email, firstName, lastName, avatar, name, userRole, telegramChatIds} = currentUser;
    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    let hasTelegram = (telegramChatIds != undefined && telegramChatIds.length > 0);

    return (
        <Wrapper>
            {hasLeft == false ? null :
                <Left>
                    <LogoPlaceholder>
                        <Logo src={require('./images/logo_temp_pr.png')}
                              onClick={() => {
                                  CommonHelper.linkTo(`/`);
                              }}
                        />
                    </LogoPlaceholder>
                    <LeftContent>
                        <CurrentUserPlaceholder onClick={() => {
                            CommonHelper.linkTo(`/user/${currentUser.id}`)
                        }}>
                            <CurrentUserAvatarPlaceholder src={ava}/>
                            <CurrentUserInfo>
                                <UserName>
                                    {CommonHelper.getUserName(currentUser)}
                                </UserName>
                                <UserRating>
                                    {'Рейтинг: 10'}
                                </UserRating>
                            </CurrentUserInfo>
                        </CurrentUserPlaceholder>

                        <TopLinksPlaceholder>
                            {renderLinks(STUDENT_LINKS.filter(a => (a.isTop == true)), active)}
                        </TopLinksPlaceholder>

                        <BottomLinksPlaceholder>
                            {renderLinks(STUDENT_LINKS.filter(a => (a.isTop != true)), active)}
                        </BottomLinksPlaceholder>

                        <BottomLinksPlaceholder>
                            <LinkItem selected={('telegram' == active)} onClick={() => {
                                CommonHelper.linkTo(`/telegram`);
                            }}>
                                {/*<LinkImage src={a.icon} selectedSrc={a.selectedIcon} selected={(isActive)}/>*/}
                                <LinkImage src={telegaImg}
                                           selectedSrc={telegaImg}
                                           selected={('telegram' == active)}/>
                                <LinkLabel>
                                    {'Телеграм'}
                                    {hasTelegram == true ? null : ' ⚠️ ⚠️ ⚠️'
                                    }
                                </LinkLabel>
                            </LinkItem>
                        </BottomLinksPlaceholder>


                        <VeryBottomLinksPlaceholder>
                            <BottomLink selected={(active == 'settings')} onClick={() => {
                                CommonHelper.linkTo(`/settings`);
                            }}>
                                Настройки
                            </BottomLink>
                            <BottomLink onClick={async () => {
                                let pld = await dispatch(usersActions.logOut());
                                if (pld.error == undefined) {
                                    CommonHelper.linkTo('/');
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 100);
                                }
                            }}>
                                Выход
                            </BottomLink>
                        </VeryBottomLinksPlaceholder>

                    </LeftContent>
                </Left>
            }

            <Right id={'template_right_content'}>
                {props.children}
            </Right>

        </Wrapper>
    );
}

const renderLinks = (links, active) => links.map((a, i) => {
    let isActive = (active == a.name);
    return (
        <LinkItem selected={isActive} key={`${a.name}_${i}`} onClick={() => {
            CommonHelper.linkTo(a.link);
        }}>
            {/*<LinkImage src={a.icon} selectedSrc={a.selectedIcon} selected={(isActive)}/>*/}
            <LinkImage src={a.emojiIcon} selectedSrc={a.emojiIcon} selected={(isActive)}/>
            <LinkLabel>
                {a.label}
            </LinkLabel>
        </LinkItem>
    )
});

const topHeight = 70;
const leftWidth = 300;

const TopLinksPlaceholder = styled.div`
    border-bottom: 1px solid rgba(133,147,168,0.51);
    margin-bottom: 10px;
    padding-bottom: 10px;
`;

const BottomLinksPlaceholder = styled.div`
    margin-top: 10px;
    margin-bottom: 40px;
`;

const TgPlaceholder = styled.div`
    box-sizing: border-box;
    padding-left: 30px;
`;

const VeryBottomLinksPlaceholder = styled.div`
    box-sizing: border-box;
    padding-left: 30px;
`;

const BottomLink = styled.div`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};
    :hover{
      color: #FFA71B;  
    }
`;

const LinkItem = styled.div`
    padding-left: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    color: ${props => (props.selected ? '#FFA71B' : '#8593A8')};
    border-left: 8px solid ${props => (props.selected ? '#FFA71B' : 'transparent')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :hover{
      border-left: 8px solid #FFA71B;
      color: #FFA71B;  
    }
`;

const LinkImage = styled.div`
    height: 24px;
    width: 24px;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => (props.selected == true ? props.selectedSrc : props.src)});
    ${LinkItem}:hover & {
      background-image: url(${props => props.selectedSrc});    
    }
`;

const LinkLabel = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
`;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background: #E5E5E5;
    font-family: Montserrat;
`;

const Left = styled.div`
    width: ${leftWidth}px;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: white;
`;

const LogoPlaceholder = styled.div`
    width: 100%;
    height: ${topHeight}px;
`;

const Logo = styled.img`
    width: 100%;
    height: 100%;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const LeftContent = styled.div`
    height: calc(100vh - ${topHeight}px);
    max-height: calc(100vh - ${topHeight}px);
    overflow-y: auto;
    box-sizing: border-box;
    padding-top: 25px;
`;

const CurrentUserPlaceholder = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const CurrentUserAvatarPlaceholder = styled.div`
    height: 45px;
    width: 45px;
    background-color: lightgrey;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    margin-right: 9px;
`;

const CurrentUserInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
`;

const UserName = styled.div`
    color: #333333;
    font-weight: bold;
`;

const UserRating = styled.div`
    color: #939598;
`;

const Right = styled.div`
    height: 100vh;
    max-height: 100vh;
    // width: calc(100vw - ${leftWidth}px);
    overflow-y: auto;
    flex: 1;
    box-sizing: border-box;
`;

const LabRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CircSpan = styled.div`
    margin-left: 5px;
    font-weight: bold;
`;

const STUDENT_LINKS = [
    {
        name: 'index',
        link: '/',
        label: (
            <LabRow>
                <span>
                    Купить прокси
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_cat', 'new_order_proposal']}/>
                </CircSpan>
            </LabRow>
        ),
        emojiIcon: require('./images/icons/emoji_home.svg'),
        selectedIcon: require('./images/icons/sel_home.svg'),
        icon: require('./images/icons/home.svg'),
        isTop: true
    },
    // {
    //     name: 'buy',
    //     link: '/buy',
    //     label: 'Купить',
    //     emojiIcon: require('./images/icons/buy.svg'),
    //     selectedIcon: require('./images/icons/sel_calendar.svg'),
    //     icon: require('./images/icons/calendar.svg'),
    //     isTop: true
    // },
    {
        name: 'sell',
        link: '/sell',
        label: (
            <LabRow>
                <span>
                    Продать прокси
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_incoming_deal']}/>
                </CircSpan>
            </LabRow>
        ),
        emojiIcon: require('./images/icons/mon_bag.svg'),
        selectedIcon: require('./images/icons/sel_calendar.svg'),
        icon: require('./images/icons/calendar.svg'),
        isTop: true
    }
];
