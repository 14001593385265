import ProductsAPI from '../../api/ProductsAPI'

import * as types from '../ActionTypes'

let loadProducts_ = () => {
    return {
        type: types.LOAD_PRODUCTS
    }
}
let loadProductsSuccess = (products) => {
    return {
        type: types.LOAD_PRODUCTS_SUCCESS,
        products: products
    }
}
let loadProductsFailed = (error) => {
    return {
        type: types.LOAD_PRODUCTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllProducts() {
    return (dispatch, getState) => {
        dispatch(loadProducts_());
        return ProductsAPI.getAllProducts().then(
            products => dispatch(loadProductsSuccess(products)),
            error => dispatch(loadProductsFailed(error))
        )
    }
}

export function loadUserProducts(userId) {
    return (dispatch, getState) => {
        dispatch(loadProducts_());
        return ProductsAPI.getUserProducts(userId).then(
            products => dispatch(loadProductsSuccess(products)),
            error => dispatch(loadProductsFailed(error))
        )
    }
}

export function loadProductById(id) {
    return (dispatch, getState) => {
        dispatch(loadProducts_());
        return ProductsAPI.getProduct(id).then(
            product => dispatch(loadProductsSuccess([product])),
            error => dispatch(loadProductsFailed(error))
        )
    }
}

let createProduct_ = () => {
    return {
        type: types.CREATE_PRODUCT
    }
}
let createProductSuccess = (product) => {
    return {
        type: types.CREATE_PRODUCT_SUCCESS,
        product: product
    }
}
let createProductFailed = (error) => {
    return {
        type: types.CREATE_PRODUCT_FAIL,
        error: error
    }
}

//thunk
export function createProduct(data) {
    return (dispatch, getState) => {
        dispatch(createProduct_());
        return ProductsAPI.createProduct(data).then(
            product => dispatch(createProductSuccess(product)),
            error => dispatch(createProductFailed(error))
        )
    }
}

let updateProduct_ = () => {
    return {
        type: types.UPDATE_PRODUCT
    }
}
let updateProductSuccess = (product) => {
    return {
        type: types.UPDATE_PRODUCT_SUCCESS,
        product: product
    }
}
let updateProductFailed = (error) => {
    return {
        type: types.UPDATE_PRODUCT_FAIL,
        error: error
    }
}

//thunk
export function updateProduct(data) {
    return (dispatch, getState) => {
        dispatch(updateProduct_());
        return ProductsAPI.updateProduct(data).then(
            product => dispatch(updateProductSuccess(product)),
            error => dispatch(updateProductFailed(error))
        )
    }
}

let deleteProduct_ = () => {
    return {
        type: types.DELETE_PRODUCT
    }
}
let deleteProductSuccess = (id) => {
    return {
        type: types.DELETE_PRODUCT_SUCCESS,
        id: id
    }
}
let deleteProductFailed = (error) => {
    return {
        type: types.DELETE_PRODUCT_FAIL,
        error: error
    }
}

//thunk
export function deleteProduct(id) {
    return (dispatch, getState) => {
        dispatch(deleteProduct_());
        return ProductsAPI.deleteProduct(id).then(
            () => dispatch(deleteProductSuccess(id)),
            error => dispatch(deleteProductFailed(error))
        )
    }
}
