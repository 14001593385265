import DealsAPI from '../../api/DealsAPI'

import * as types from '../ActionTypes'

let loadDeals_ = () => {
    return {
        type: types.LOAD_DEALS
    }
}
let loadDealsSuccess = (deals) => {
    return {
        type: types.LOAD_DEALS_SUCCESS,
        deals: deals
    }
}
let loadDealsFailed = (error) => {
    return {
        type: types.LOAD_DEALS_FAIL,
        error: error
    }
}

//thunk
export function loadAllDeals() {
    return (dispatch, getState) => {
        dispatch(loadDeals_());
        return DealsAPI.getAllDeals().then(
            deals => dispatch(loadDealsSuccess(deals)),
            error => dispatch(loadDealsFailed(error))
        )
    }
}

export function loadUserDeals(userId) {
    return (dispatch, getState) => {
        dispatch(loadDeals_());
        return DealsAPI.getUserDeals(userId).then(
            deals => dispatch(loadDealsSuccess(deals)),
            error => dispatch(loadDealsFailed(error))
        )
    }
}

export function loadProductDeals(productId) {
    return (dispatch, getState) => {
        dispatch(loadDeals_());
        return DealsAPI.getProductDeals(productId).then(
            deals => dispatch(loadDealsSuccess(deals)),
            error => dispatch(loadDealsFailed(error))
        )
    }
}

export function loadDealById(id) {
    return (dispatch, getState) => {
        dispatch(loadDeals_());
        return DealsAPI.getDeal(id).then(
            deal => dispatch(loadDealsSuccess([deal])),
            error => dispatch(loadDealsFailed(error))
        )
    }
}

let createDeal_ = () => {
    return {
        type: types.CREATE_DEAL
    }
}
let createDealSuccess = (deal) => {
    return {
        type: types.CREATE_DEAL_SUCCESS,
        deal: deal
    }
}
let createDealFailed = (error) => {
    return {
        type: types.CREATE_DEAL_FAIL,
        error: error
    }
}

//thunk
export function createDeal(data) {
    return (dispatch, getState) => {
        dispatch(createDeal_());
        return DealsAPI.createDeal(data).then(
            deal => dispatch(createDealSuccess(deal)),
            error => dispatch(createDealFailed(error))
        )
    }
}

let updateDeal_ = () => {
    return {
        type: types.UPDATE_DEAL
    }
}
let updateDealSuccess = (deal) => {
    return {
        type: types.UPDATE_DEAL_SUCCESS,
        deal: deal
    }
}
let updateDealFailed = (error) => {
    return {
        type: types.UPDATE_DEAL_FAIL,
        error: error
    }
}

//thunk
export function updateDeal(data) {
    return (dispatch, getState) => {
        dispatch(updateDeal_());
        return DealsAPI.updateDeal(data).then(
            deal => dispatch(updateDealSuccess(deal)),
            error => dispatch(updateDealFailed(error))
        )
    }
}

let deleteDeal_ = () => {
    return {
        type: types.DELETE_DEAL
    }
}
let deleteDealSuccess = (id) => {
    return {
        type: types.DELETE_DEAL_SUCCESS,
        id: id
    }
}
let deleteDealFailed = (error) => {
    return {
        type: types.DELETE_DEAL_FAIL,
        error: error
    }
}

//thunk
export function deleteDeal(id) {
    return (dispatch, getState) => {
        dispatch(deleteDeal_());
        return DealsAPI.deleteDeal(id).then(
            () => dispatch(deleteDealSuccess(id)),
            error => dispatch(deleteDealFailed(error))
        )
    }
}
