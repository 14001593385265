import BookingsAPI from '../../api/BookingsAPI'

import * as types from '../ActionTypes'

let loadBookings_ = () => {
    return {
        type: types.LOAD_BOOKINGS
    }
}
let loadBookingsSuccess = (bookings) => {
    return {
        type: types.LOAD_BOOKINGS_SUCCESS,
        bookings: bookings
    }
}
let loadBookingsFailed = (error) => {
    return {
        type: types.LOAD_BOOKINGS_FAIL,
        error: error
    }
}

//thunk
export function loadAllBookings() {
    return (dispatch, getState) => {
        dispatch(loadBookings_());
        return BookingsAPI.getAllBookings().then(
            bookings => dispatch(loadBookingsSuccess(bookings)),
            error => dispatch(loadBookingsFailed(error))
        )
    }
}

export function loadUserBookings(userId) {
    return (dispatch, getState) => {
        dispatch(loadBookings_());
        return BookingsAPI.getUserBookings(userId).then(
            bookings => dispatch(loadBookingsSuccess(bookings)),
            error => dispatch(loadBookingsFailed(error))
        )
    }
}

export function acceptBooking(id) {
    return (dispatch, getState) => {
        dispatch(loadBookings_());
        return BookingsAPI.acceptBooking(id).then(
            booking => dispatch(loadBookingsSuccess([booking])),
            error => dispatch(loadBookingsFailed(error))
        )
    }
}

export function loadBookingById(id) {
    return (dispatch, getState) => {
        dispatch(loadBookings_());
        return BookingsAPI.getBooking(id).then(
            booking => dispatch(loadBookingsSuccess([booking])),
            error => dispatch(loadBookingsFailed(error))
        )
    }
}

let createBooking_ = () => {
    return {
        type: types.CREATE_BOOKING
    }
}
let createBookingSuccess = (booking) => {
    return {
        type: types.CREATE_BOOKING_SUCCESS,
        booking: booking
    }
}
let createBookingFailed = (error) => {
    return {
        type: types.CREATE_BOOKING_FAIL,
        error: error
    }
}

//thunk
export function createBooking(data) {
    return (dispatch, getState) => {
        dispatch(createBooking_());
        return BookingsAPI.createBooking(data).then(
            booking => dispatch(createBookingSuccess(booking)),
            error => dispatch(createBookingFailed(error))
        )
    }
}

let updateBooking_ = () => {
    return {
        type: types.UPDATE_BOOKING
    }
}
let updateBookingSuccess = (booking) => {
    return {
        type: types.UPDATE_BOOKING_SUCCESS,
        booking: booking
    }
}
let updateBookingFailed = (error) => {
    return {
        type: types.UPDATE_BOOKING_FAIL,
        error: error
    }
}

//thunk
export function updateBooking(data) {
    return (dispatch, getState) => {
        dispatch(updateBooking_());
        return BookingsAPI.updateBooking(data).then(
            booking => dispatch(updateBookingSuccess(booking)),
            error => dispatch(updateBookingFailed(error))
        )
    }
}

let deleteBooking_ = () => {
    return {
        type: types.DELETE_BOOKING
    }
}
let deleteBookingSuccess = (id) => {
    return {
        type: types.DELETE_BOOKING_SUCCESS,
        id: id
    }
}
let deleteBookingFailed = (error) => {
    return {
        type: types.DELETE_BOOKING_FAIL,
        error: error
    }
}

//thunk
export function deleteBooking(id) {
    return (dispatch, getState) => {
        dispatch(deleteBooking_());
        return BookingsAPI.deleteBooking(id).then(
            () => dispatch(deleteBookingSuccess(id)),
            error => dispatch(deleteBookingFailed(error))
        )
    }
}
