import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo, Component} from 'react';
import styled from "styled-components";
import Select, {createFilter} from "react-select";
import axios from 'axios'
import Spin from 'arui-feather/spin'

import {BlueButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";

import {Label, Input, Textarea} from "../../ira/ui/Inputs";
import NiceConfirm from "../../modals/NiceConfirm";
import {FixedSizeList as List} from "react-window";
import {PAYMENT_OPTIONS} from "../../users/panels/UserUpdatePaymentMethodsPanel";
import SmartSelect from "../../ira/ui/SmartSelect";

export default function UpdateOrderForm(props) {
    const {
        loading,
        onSave = d => {

        },
        canDelete = false,
        onDelete = () => {

        },
        saveButtonName = 'Сохранить'
    } = props;

    const [telegram, setTelegram] = useState(props.telegram == undefined ? '' : props.telegram);
    const [contacts, setContacts] = useState(props.contacts == undefined ? '' : props.contacts);

    const [countriesLoading, setCountriesLoading] = useState(false);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods == undefined ? [] : props.paymentMethods);

    const [allCountries, setAllCountries] = useState([]);
    const [allCities, setAllCities] = useState([]);

    const [countryCode, setCountryCode] = useState(props.countryCode == undefined ? 'RU' : props.countryCode);
    const [city, setCity] = useState(props.city == undefined ? 'Moscow' : props.city);
    const [message, setMessage] = useState(props.message == undefined ? '' : props.message);

    const [proxyType, setProxyType] = useState(props.proxyType == undefined ? 'socks5' : props.proxyType);
    const [location, setLocation] = useState(props.location);

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    useEffect(() => {
        setCountriesLoading(true);
        axios.get(`${API_ENDPOINT}/countries`).then(d => d.data).then(arr => {
            setAllCountries(arr);
            setCountriesLoading(false);
        })
    }, []);

    useEffect(() => {
        setCitiesLoading(true);
        axios.get(`${API_ENDPOINT}/country/${countryCode}/cities`).then(d => d.data.cities).then(arr => {
            console.log('aaarrr = ', arr);
            let sortedArr = arr.filter(a => (a != undefined && a.name_en != undefined)).sort((a, b) => {
                let s1 = a.name_en.toLowerCase();
                let s2 = a.name_en.toLowerCase();
                if (s1 > s2) {
                    return -1;
                }
                if (s1 < s2) {
                    return 1;
                }
                return 0;
            })
            setAllCities(sortedArr);
            setCitiesLoading(false);
        })
    }, [countryCode]);


    const COUNTRIES_OPTIONS = allCountries.map(a => ({value: a.iso, label: a.name_en}));
    const CITIES_OPTIONS = allCities.map(a => ({value: a.name_en, label: a.name_en}));

    return (
        <Wrapper>
            <Row>

                <Half>
                    <Field>
                        <Label>
                            Страна
                        </Label>
                        <Select options={COUNTRIES_OPTIONS}
                                value={COUNTRIES_OPTIONS.filter(a => (a.value == countryCode))[0]}
                                onChange={a => {
                                    console.log('onSelect: a = ', a);
                                    setCountryCode(a.value);
                                    setCity(undefined);
                                }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Город
                        </Label>
                        <Spin visible={citiesLoading}/>
                        {citiesLoading == true ? null :
                            <Select options={CITIES_OPTIONS}
                                    components={{MenuList}}
                                    filterOption={createFilter({ignoreAccents: false})}
                                    value={CITIES_OPTIONS.filter(a => (a.value == city))[0]}
                                    onChange={a => {
                                        console.log('onSelect: a = ', a);
                                        setCity(a.value);
                                    }}/>
                        }
                    </Field>
                </Half>

            </Row>

            <Row>
                <Half>
                    <Field>
                        <Label>
                            Способы оплаты
                        </Label>
                        <SmartSelect options={PAYMENT_OPTIONS}
                                     selectedValues={paymentMethods}
                                     isMulti={true}
                                     placeholder={'Укажите способ оплаты'}
                                     onSelect={a => {
                                         setPaymentMethods(a);
                                     }}
                        />
                    </Field>
                </Half>
            </Row>

            <Field>
                <Label>
                    Укажите желаемую стоимость
                </Label>
                <Textarea value={message}
                          placeholder={'Укажите желаемую стоимость. Так же вы можете указать тут в свободной форме все, что считаете важным для покупаемых прокси.'}
                          onChange={evt => {
                              setMessage(evt.target.value);
                          }}
                />
            </Field>

            <Row>

                <Half>
                    <Field>
                        <Label>
                            Ваш Telegram
                        </Label>
                        <Input value={telegram} placeholder={'Укажите для связи с Вами'} onChange={evt => {
                            setTelegram(evt.target.value);
                        }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Дополнительные контакты
                        </Label>
                        <Input value={contacts} placeholder={'Доп. контакты для связи продавца с вами'}
                               onChange={evt => {
                                   setContacts(evt.target.value);
                               }}/>
                    </Field>
                </Half>

            </Row>

            <BottomSavePlaceholder canDelete={canDelete}>
                <BlueButton style={{width: 200}} loading={loading} onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        countryCode,
                        city,
                        message,
                        paymentMethods,
                        telegram,
                        contacts
                    });
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : saveButtonName}
                </BlueButton>
                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        setConfirmDeleteVisible(true);
                    }}>
                        удалить прокси
                    </DeleteSpan>
                }
            </BottomSavePlaceholder>

            {confirmDeleteVisible == false ? null :
                <NiceConfirm
                    onClose={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onCancel={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onConfirm={() => {
                        onDelete();
                    }}
                    confirmText={'YES, DELETE'}
                    cancelText={'No, nevermind'}
                    heading={'Delete proxy'}
                    subHeading={'Are you sure that you want to remove this proxy from the marketplace?'}

                />
            }

        </Wrapper>
    )
}

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    :hover{
      opacity: 0.9;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    
    padding-left: 5px;
    padding-right: 5px;
    
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;


const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const Reservation = styled.div`
    background: #6380F2;
    border-radius: 50px;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;    
    
    :hover{
        background: #FFA71B;
    }
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;


const height = 35;

class MenuList extends Component {
    render() {
        const {options, children, maxHeight, getValue} = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
