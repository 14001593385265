/**
 * Created by sabir on 19.07.17.
 */

import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'
import ls from 'local-storage'


const initialState = {
    userRole: (ls('ui_user_role') == undefined) ? 'buyer' : ls('ui_user_role')
}

const UiReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.SET_UI_USER_ROLE:
            return {
                ...state,
                userRole: action.role
            }


        default:
            return state;
    }

}

export default UiReducer;
