import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SnakesAPI = {

    getUserSnakes(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/snakes`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getDogSnakes(dogId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/dog/${dogId}/snakes`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAllSnakes() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/snakes/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getSnake(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/snake/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createSnake(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/snake`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateSnake(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/snake`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteSnake(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/snake`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default SnakesAPI;
