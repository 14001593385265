import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../../helpers/ReactHelper";

import * as actions from '../../../redux/actions/NotificationsActions'

export default function NotificationsDaemon(props) {
    const {interval = 10 * 1000} = props;
    const {
        loading, currentUserId, notifications
    } = useMappedState(useCallback(state => {
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            notifications: state.notifications.notificationsMap.toArray()
        }
    }, []));
    const dispatch = useDispatch();


    ReactHelper.useInterval(() => {
        if (currentUserId != undefined) {
            dispatch(actions.loadUserNotifications(currentUserId))
        }
    }, interval);

    return (
        <Wrapper>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: none;
`;
