import CatsAPI from '../../api/CatsAPI'

import * as types from '../ActionTypes'

let loadCats_ = () => {
    return {
        type: types.LOAD_CATS
    }
}
let loadCatsSuccess = (cats) => {
    return {
        type: types.LOAD_CATS_SUCCESS,
        cats: cats
    }
}
let loadCatsFailed = (error) => {
    return {
        type: types.LOAD_CATS_FAIL,
        error: error
    }
}

//thunk
export function loadAllCats() {
    return (dispatch, getState) => {
        dispatch(loadCats_());
        return CatsAPI.getAllCats().then(
            cats => dispatch(loadCatsSuccess(cats)),
            error => dispatch(loadCatsFailed(error))
        )
    }
}

export function loadUserCats(userId) {
    return (dispatch, getState) => {
        dispatch(loadCats_());
        return CatsAPI.getUserCats(userId).then(
            cats => dispatch(loadCatsSuccess(cats)),
            error => dispatch(loadCatsFailed(error))
        )
    }
}

export function loadCatById(id) {
    return (dispatch, getState) => {
        dispatch(loadCats_());
        return CatsAPI.getCat(id).then(
            cat => dispatch(loadCatsSuccess([cat])),
            error => dispatch(loadCatsFailed(error))
        )
    }
}

let createCat_ = () => {
    return {
        type: types.CREATE_CAT
    }
}
let createCatSuccess = (cat) => {
    return {
        type: types.CREATE_CAT_SUCCESS,
        cat: cat
    }
}
let createCatFailed = (error) => {
    return {
        type: types.CREATE_CAT_FAIL,
        error: error
    }
}

//thunk
export function createCat(data) {
    return (dispatch, getState) => {
        dispatch(createCat_());
        return CatsAPI.createCat(data).then(
            cat => dispatch(createCatSuccess(cat)),
            error => dispatch(createCatFailed(error))
        )
    }
}

let updateCat_ = () => {
    return {
        type: types.UPDATE_CAT
    }
}
let updateCatSuccess = (cat) => {
    return {
        type: types.UPDATE_CAT_SUCCESS,
        cat: cat
    }
}
let updateCatFailed = (error) => {
    return {
        type: types.UPDATE_CAT_FAIL,
        error: error
    }
}

//thunk
export function updateCat(data) {
    return (dispatch, getState) => {
        dispatch(updateCat_());
        return CatsAPI.updateCat(data).then(
            cat => dispatch(updateCatSuccess(cat)),
            error => dispatch(updateCatFailed(error))
        )
    }
}

let deleteCat_ = () => {
    return {
        type: types.DELETE_CAT
    }
}
let deleteCatSuccess = (id) => {
    return {
        type: types.DELETE_CAT_SUCCESS,
        id: id
    }
}
let deleteCatFailed = (error) => {
    return {
        type: types.DELETE_CAT_FAIL,
        error: error
    }
}

//thunk
export function deleteCat(id) {
    return (dispatch, getState) => {
        dispatch(deleteCat_());
        return CatsAPI.deleteCat(id).then(
            () => dispatch(deleteCatSuccess(id)),
            error => dispatch(deleteCatFailed(error))
        )
    }
}
