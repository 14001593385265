import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios'
import FirebaseAPI from "./api/FirebaseAPI";

const getToken = () => {
    let tk = window.localStorage.getItem('auth_token');
    if (tk == undefined || tk == '') {
        return;
    }
    return tk;
};

// axios.interceptors.request.use((config) => {
//     let token = getToken();
//     if (token != undefined) {
//         config.headers['x-token'] = token;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

axios.interceptors.request.use((config) => {
    let token = getToken();
    if (token != undefined){
        let url = new URL(config.url);
        url.searchParams.set('token', token);
        config.url = url.toString();
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


FirebaseAPI.initFirebase();

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();
