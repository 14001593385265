import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo, Component} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {FixedSizeList as List} from "react-window";

import {Input, Label, Textarea} from "../../ira/ui/Inputs";
import '../../dogs/forms/style.css'
import Select, {createFilter} from 'react-select'
import {BlueButton} from "../../ira/ui/Buttons";
import NiceConfirm from "../../modals/NiceConfirm";

export default function UpdateProductForm(props) {
    const {
        loading = false,
        canDelete = false,
        onSave = d => {

        },
        onDelete = () => {

        }
    } = props;

    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    const [countryCode, setCountryCode] = useState(props.countryCode == undefined ? 'RU' : props.countryCode);
    const [city, setCity] = useState(props.city);

    const [operatorsText, setOperatorsText] = useState(props.operatorsText == undefined ? '' : props.operatorsText);

    const [pricePerMonth, setPricePerMonth] = useState(props.pricePerMonth == undefined ? '' : props.pricePerMonth);
    const [pricePerWeek, setPricePerWeek] = useState(props.pricePerWeek == undefined ? '' : props.pricePerWeek);
    const [pricePerDay, setPricePerDay] = useState(props.pricePerDay == undefined ? '' : props.pricePerDay);

    const [countriesLoading, setCountriesLoading] = useState(false);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [allCities, setAllCities] = useState([]);

    useEffect(() => {
        setCountriesLoading(true);
        axios.get(`${API_ENDPOINT}/countries`).then(d => d.data).then(arr => {
            setAllCountries(arr);
            setCountriesLoading(false);
        })
    }, []);

    useEffect(() => {
        setCitiesLoading(true);
        axios.get(`${API_ENDPOINT}/country/${countryCode}/cities`).then(d => d.data.cities).then(arr => {
            console.log('aaarrr = ', arr);
            let sortedArr = arr.filter(a => (a != undefined && a.name_en != undefined)).sort((a, b) => {
                let s1 = a.name_en.toLowerCase();
                let s2 = a.name_en.toLowerCase();
                if (s1 > s2) {
                    return -1;
                }
                if (s1 < s2) {
                    return 1;
                }
                return 0;
            });
            setAllCities(sortedArr);
            setCitiesLoading(false);
        })
    }, [countryCode]);


    const COUNTRIES_OPTIONS = allCountries.map(a => ({value: a.iso, label: a.name_en}));
    const CITIES_OPTIONS = allCities.map(a => ({value: a.name_en, label: a.name_en}));

    if (countriesLoading == true) {
        return (
            <Wrapper>
                <Spin visible={true}/>
            </Wrapper>
        )
    }

    console.log('city = ', city);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input
                    placeholder={'Например, "Прокси МТС Москва"'}
                    value={name}
                    onChange={evt => {
                        setName(evt.target.value);
                    }}/>
            </Field>

            {/*<Field>*/}
            {/*<Label>*/}
            {/*Описание*/}
            {/*</Label>*/}
            {/*<Textarea*/}
            {/*placeholder={'Описание'}*/}
            {/*value={description}*/}
            {/*onChange={evt => {*/}
            {/*setDescription(evt.target.value);*/}
            {/*}}/>*/}
            {/*</Field>*/}

            <Row>

                <Half>
                    <Field>
                        <Label>
                            Страна
                        </Label>
                        <Select options={COUNTRIES_OPTIONS}
                                placeholder={'Укажите страну'}
                                value={COUNTRIES_OPTIONS.filter(a => (a.value == countryCode))[0]}
                                onChange={a => {
                                    console.log('onSelect: a = ', a);
                                    setCountryCode(a.value);
                                    setCity(undefined);
                                }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Город
                        </Label>
                        <Spin visible={citiesLoading}/>
                        {citiesLoading == true ? null :
                            <Select options={CITIES_OPTIONS}
                                    placeholder={'Выберите город'}
                                    components={{MenuList}}
                                    filterOption={createFilter({ignoreAccents: false})}
                                    value={CITIES_OPTIONS.filter(a => (a.value == city))[0]}
                                    onChange={a => {
                                        console.log('onSelect: a = ', a);
                                        setCity(a.value);
                                    }}/>
                        }
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Операторы
                        </Label>
                        <Input value={operatorsText} placeholder={'Операторы через запятую'} onChange={evt => {
                            setOperatorsText(evt.target.value);
                        }}/>
                    </Field>
                </Half>

            </Row>

            <Row>
                <Half>
                    <Field>
                        <Label>
                            Цена за 1 день
                        </Label>
                        <Input
                            placeholder={'в долларах США'}
                            value={pricePerDay}
                            onChange={evt => {
                                setPricePerDay(evt.target.value);
                            }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Цена за 7 дней
                        </Label>
                        <Input
                            placeholder={'в долларах США'}
                            value={pricePerWeek}
                            onChange={evt => {
                                setPricePerWeek(evt.target.value);
                            }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Цена за 30 дней
                        </Label>
                        <Input
                            placeholder={'в долларах США'}
                            value={pricePerMonth}
                            onChange={evt => {
                                setPricePerMonth(evt.target.value);
                            }}/>
                    </Field>
                </Half>
            </Row>

            <BottomSavePlaceholder canDelete={canDelete}>
                <BlueButton style={{width: 200}} loading={loading} onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        name,
                        description,
                        countryCode,
                        city,
                        operatorsText,

                        pricePerWeek: +pricePerWeek,
                        pricePerDay: +pricePerDay,
                        pricePerMonth: +pricePerMonth,
                    });
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : 'Save'}
                </BlueButton>

                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        setConfirmDeleteVisible(true);
                    }}>
                        Удалить предложение
                    </DeleteSpan>
                }

            </BottomSavePlaceholder>

            {confirmDeleteVisible == false ? null :
                <NiceConfirm
                    onClose={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onCancel={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onConfirm={() => {
                        onDelete();
                    }}
                    confirmText={'ДА, УДАЛИТЬ'}
                    cancelText={'Нет, не удалять'}
                    heading={'Удалить'}
                    subHeading={'Вы уверены?'}

                />
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 20px;
    :hover{
      opacity: 0.9;
    }
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    
    padding-left: 5px;
    padding-right: 5px;
    
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;
`;


const height = 35;

class MenuList extends Component {
    render() {
        const {options, children, maxHeight, getValue} = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
