import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as notificationsActions from '../../../redux/actions/NotificationsActions'
import * as catsActions from '../../../redux/actions/CatsActions'
import * as snakesActions from '../../../redux/actions/SnakesActions'
import * as dogsActions from '../../../redux/actions/DogsActions'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import CommonHelper from "../../../helpers/CommonHelper";
import ChangeCatIPWrapper from "../tools/ChangeCatIPWrapper";
import {RedButton} from "../../ira/ui/Buttons";
import DogIpInfoView from "../../dogs/tools/DogIpInfoView";

import {Label} from "../../ira/ui/Inputs";
import UpdateCatForm from "../forms/UpdateCatForm";
import {API_ENDPOINT} from "../../../constants/config";
import NotificationsBubble from "../../notifications/panels/NotificationsBubble";

export default function UserCatsPanel(props) {
    const {
        userId
    } = props;

    const {
        loading, usersMap, uId, dogs,
        isMe, getDogActiveSnakes, allSnakes,
        bookings, getDog, cats, getCatSnakes,
        getDogBookings, currentUserId, getUser
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading || state.cats.loading || state.dogs.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            cats: state.cats.catsMap.toArray()
                .filter(a => (a.userId == uId))
                .sort((a, b) => (+b.timestamp - +a.timestamp)),
            isMe: uId == state.users.currentUserId,
            getDog: dId => state.dogs.dogsMap.get(dId),
            getCatSnakes: catId => state.snakes.snakesMap.toArray().filter(a => (a.catId == catId)),
            getUser: x => state.users.usersMap.get(x),
            allSnakes: state.snakes.snakesMap.toArray()
        }
    }, [userId]));

    const [selectedId, setSelectedId] = useState();
    const [mode, setMode] = useState('view');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(catsActions.loadUserCats(uId));
        dispatch(snakesActions.loadUserSnakes(uId));
        dispatch(dogsActions.loadAllDogs()); // todo: optimize
    }, [uId]);

    useEffect(() => {
        if (selectedId != undefined) {
            dispatch(notificationsActions.deleteSmartNotification({referenceId: selectedId, type: 'new_cat'}));
        }
    }, [selectedId]);

    let selectedCat = cats.filter(a => (a.id == selectedId))[0];
    let selectedSnake = (selectedCat == undefined) ? undefined : getCatSnakes(selectedCat.id)[0];
    let selectedDog = (selectedSnake == undefined) ? undefined : getDog(selectedSnake.dogId);

    const noCats = (cats.length == 0 && loading == false);

    return (
        <Wrapper>

            {noCats == false ? null :
                <div>
                    Здесь будут отображаться купленные Вами прокси
                    {/*Вы еще не нашли ни 1 прокси.*/}
                    {/*<FindLinkSpan onClick={() => {*/}
                    {/*CommonHelper.linkTo(`/`);*/}
                    {/*}}>*/}
                    {/*Find and book the first one!*/}
                    {/*</FindLinkSpan>*/}
                </div>
            }

            <ListPlaceholder>
                {cats.map((a, i) => {
                    let snakes = getCatSnakes(a.id);
                    let snake = snakes[0];
                    let dog = (snake == undefined) ? undefined : getDog(snake.dogId);
                    let dogOwner = (dog == undefined) ? undefined : getUser(dog.userId);
                    // let {ip, port, , contacts, timestamp, fromTimestamp, toTimestamp} = a;
                    // let label = (name == undefined) ? `${dog.countryCode}, ${dog.city}` : name;
                    let name = (a.name == undefined ? `${dog.countryCode}, ${dog.city}` : a.name);
                    return (
                        <Box key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            {dog == undefined ? null :
                                <BoxTop>
                                    <BoxName>
                                        <NotificationsBubble types={['new_cat']}
                                                             style={{
                                                                 display: 'inline-flex',
                                                                 verticalAlign: 'top',
                                                                 marginRight: 5
                                                             }}
                                                             referenceIds={[a.id]}/>
                                        {name}
                                    </BoxName>
                                    {dog.countryCode == undefined ? null :
                                        <BigFlagPlaceholder>
                                            {flag(dog.countryCode)}
                                        </BigFlagPlaceholder>
                                    }
                                </BoxTop>
                            }
                            <BoxMiddle>

                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/plug_d.svg')}/>
                                    <BoxRowName>
                                        {`${a.proxyType}://${a.login}:${a.password}@${a.address}:${a.port}`}
                                    </BoxRowName>
                                </BoxRow>

                                {snake == undefined ? null :
                                    <BoxRow>
                                        <BoxIconLittle src={require('../../../assets/images/cal_cal.svg')}/>
                                        <BoxRowName>
                                            {`${moment(snake.fromTimestamp).format('DD.MM.YYYY')} - ${moment(snake.toTimestamp).format('DD.MM.YYYY')}`}
                                        </BoxRowName>
                                    </BoxRow>
                                }
                                {dogOwner == undefined ? null :
                                    <BoxRow>
                                        <BoxIconLittle src={require('../../../assets/images/cont.svg')}/>
                                        <BoxRowName>
                                            {/*{`${dogOwner.publicContacts}`}*/}
                                            {`Telegram: ${dogOwner.telegram}`}
                                        </BoxRowName>
                                    </BoxRow>
                                }
                            </BoxMiddle>
                        </Box>
                    )
                })}
            </ListPlaceholder>

            {(selectedCat == undefined || selectedDog == undefined || selectedSnake == undefined || mode == 'edit') ? null :
                <NiceModalWithHeading width={600} onClose={() => {
                    setSelectedId(undefined);
                    setMode('view');
                }}
                                      subHeading={`${selectedDog.countryCode}, ${selectedDog.city}, ${moment(selectedSnake.fromTimestamp).format('DD.MM.YYYY')} - ${moment(selectedSnake.toTimestamp).format('DD.MM.YYYY')}`}
                                      heading={`${selectedCat.name == undefined ? `${selectedDog.countryCode}, ${selectedDog.city}` : selectedCat.name}`}>

                    <SellerInfoPlaceholder>

                        <div style={{marginTop: 30}}>

                            <ConnBox>
                                <InfoRow style={{width: 100, flex: 'none'}}>
                                    <Label>
                                        Type
                                    </Label>
                                    <InfoRowValue>
                                        {selectedCat.proxyType}
                                    </InfoRowValue>
                                </InfoRow>
                                <InfoRow style={{flex: 'auto'}}>
                                    <Label>
                                        Login
                                    </Label>
                                    <InfoRowValue>
                                        {selectedCat.login}
                                    </InfoRowValue>

                                </InfoRow>

                                <InfoRow style={{flex: 'auto'}}>
                                    <Label>
                                        Password
                                    </Label>
                                    <InfoRowValue>
                                        {selectedCat.password}
                                    </InfoRowValue>
                                </InfoRow>

                                <InfoRow style={{width: 140, flex: 'none'}}>
                                    <Label>
                                        IP
                                    </Label>
                                    <InfoRowValue>
                                        {selectedCat.address}
                                    </InfoRowValue>
                                </InfoRow>

                                <InfoRow style={{width: 60, flex: 'none'}}>
                                    <Label>
                                        Port
                                    </Label>
                                    <InfoRowValue>
                                        {selectedCat.port}
                                    </InfoRowValue>
                                </InfoRow>
                            </ConnBox>


                            <InfoRow2>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <InfoRowName style={{marginRight: 10}}>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <span style={{marginRight: 5}}>
                                                Mobile IP:
                                            </span>
                                            <span>
                                                <DogIpInfoView {...selectedCat} watchInterval={7 * 1000}/>
                                            </span>
                                        </div>
                                        <div style={{cursor: 'pointer'}} onClick={() => {
                                            setMode('edit');
                                        }}>
                                            {`Смена IP по расписанию: ${selectedCat.ipChangeEnabled != true ? 'нет' : `${selectedCat.ipChangeMinutesInterval} мин`}`}
                                        </div>
                                    </InfoRowName>
                                    <InfoRowValue>

                                    </InfoRowValue>
                                </div>

                                <div>
                                    <ChangeCatIPWrapper id={selectedCat.id}>
                                        <RedButton>
                                            Change IP
                                        </RedButton>
                                    </ChangeCatIPWrapper>
                                </div>
                            </InfoRow2>

                        </div>

                        <VeryBottomPlaceholder>
                            <SellerDetailsSpan onClick={() => {
                                CommonHelper.linkTo(`/user/${selectedDog.userId}`, true)
                            }}>
                                Продавец
                            </SellerDetailsSpan>
                            <EditSpan onClick={() => {
                                setMode('edit');
                            }}>
                                <EditImg src={require('../../../assets/images/pencil.svg')}/>
                                <span>
                                    редактировать
                                </span>
                            </EditSpan>
                            <EditSpan style={{marginLeft: 10}} onClick={() => {
                                setMode('details');
                            }}>
                                <EditImg src={require('../../../assets/images/info_svg_.svg')}/>
                                <span>
                                     детали
                                </span>
                            </EditSpan>
                        </VeryBottomPlaceholder>

                    </SellerInfoPlaceholder>

                </NiceModalWithHeading>
            }

            {(selectedCat == undefined || selectedDog == undefined || selectedSnake == undefined || mode == 'view') ? null :
                <NiceModalWithHeading width={500} onClose={() => {
                    setMode('view');
                }}
                                      subHeading={``}
                                      heading={{'edit': 'Редактирование', 'details': 'Детали'}[mode]}>

                    {mode != 'edit' ? null :
                        <UpdateCatForm {...selectedCat} loading={loading} onSave={async d => {
                            console.log('onSave: d = ', d);
                            await dispatch(catsActions.updateCat({...d, id: selectedCat.id}));
                            setMode('view');
                        }}/>
                    }

                    {mode != 'details' ? null :
                        <div>
                            <DetField>
                                <Label>
                                    Ссылка для смены IP
                                </Label>
                                <div>
                                    <a href={`${'https://proxy.broker/api'}/cat/${selectedCat.id}/change_ip`}
                                       target={'_blank'}>
                                        {`${'https://proxy.broker/api'}/cat/${selectedCat.id}/change_ip`}
                                    </a>
                                </div>
                            </DetField>
                            <DetField>
                                <Label>
                                    Дата создания прокси
                                </Label>
                                <div>
                                    {moment(selectedCat.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                                </div>
                            </DetField>
                        </div>
                    }


                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const DetField = styled.div`
    margin-bottom: 10px;
`;

const VeryBottomPlaceholder = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #8593A8;
`;

const EditSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
`;

const EditImg = styled.img`
    height: 14px;
    margin-right: 5px;
`;

const FindLinkSpan = styled.span`
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
    color: #258AFF;
`;

const Wrapper = styled.div`
    
`;

const ConnBox = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
`;

const SellerDetailsSpan = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-right: 20px;
    //font-size: 12px;
`;

const InfoRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    flex: 1;
    text-align: left;
`;

const InfoRow2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
`;

const InfoRowName = styled.div`
    
`;

const InfoRowValue = styled.div`
    
`;

const SellerInfoPlaceholder = styled.div`
    
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;


const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;

const ListPlaceholder = styled.div`
    //display: flex;
    //flex-wrap: wrap;
`;

const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    //width: calc(50% - 15px);
    //max-width: calc(50% - 15px);
    
      
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 0px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    //:nth-of-type(2n){
    //  margin-right: 0px;
    //}
    :hover{
      opacity: 0.8;
    }
`;


const BoxIconLittle = styled.img`
    width: 24px;
`;


const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;

const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;
