import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import CommonHelper from "../../../helpers/CommonHelper";

const voidAva = require('../../../assets/images/vo_ava.jpg');

export default function UserView(props) {
    const {countries = [], avatar, id, telegram, timestamp, paymentMethods = [], lastSeenTimestamp} = props;

    return (
        <User>
            <ProfilePic pic={avatar}/>
            <UserData>
                <UserDataTop>
                    <UserName onClick={() => {
                        CommonHelper.linkTo(`/user/${id}`);
                    }}>
                        {CommonHelper.getUserName(props)}
                    </UserName>
                </UserDataTop>

                <div>
                    <LocationList>
                        {countries.map((a, i) => {
                            return (
                                <Location key={a}>
                                    {`${flag(a)}`}
                                    <span style={{marginLeft: 7}}>
                                            {`${a}`}
                                        </span>
                                </Location>
                            )
                        })}
                    </LocationList>
                </div>


                <div style={{fontSize: 12, opacity: 0.7}}>
                    {`Telegram:`}
                    {telegram == undefined ? 'Не указан' :
                        <TLink href={`https://t.me/${telegram.replace('@', '')}`}
                               target={'_blank'}>
                            {`@${telegram.replace('@', '')}`}
                        </TLink>
                    }
                    <span style={{marginLeft: 5}}>
                                            {`ID: ${id}`}
                                        </span>
                    {lastSeenTimestamp == undefined ? null :
                        <span style={{marginLeft: 10}}>
                            {`Был на сайте: ${moment(lastSeenTimestamp).format('DD.MM.YYYY HH:mm')}`}
                        </span>
                    }

                </div>
                <div>
                                        <span style={{fontSize: 12, opacity: 0.5}}>
                                            {`${moment(timestamp).format('DD.MM.YYYY HH:mm')}`}
                                        </span>
                </div>

                <div>
                    <PaymentMethodList>
                        {(paymentMethods == undefined ? [] : paymentMethods).map((p, i) => {
                            return (
                                <PaymentMethod key={`${p}_${i}`}>
                                    {p}
                                </PaymentMethod>
                            )
                        })}
                    </PaymentMethodList>
                </div>

            </UserData>

        </User>
    );
}

const Wrapper = styled.div`
    
`;


const ProfilePic = styled.div`
  width: 120px;
  height: 120px;
  background-color: #f5f2f2;
  border-radius: 100px;
  background-position: center;
  background-size: contain;
  background-image: url(${props => (props.pic == undefined ? voidAva : props.pic)});
`;


const TLink = styled.a`
    text-decoration: underline;
    color: black;
    opacity: 0.7;
    margin-left: 5px;
    margin-right: 5px;
    :hover{
      opacity: 1;
    }
`;

const PaymentMethodList = styled.div`
  margin-top: 10px;
  display: flex;
`;

const PaymentMethod = styled.div`
  background-color: #eaeaea; 
  width: fit-content;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 13px;
  margin: 0 5px;
  
  :first-child {
    margin-left: 0;
  }
`;

const LocationList = styled.div`
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     margin-top: 5px;
`;

const Location = styled.div`
    font-size: 15px;
    color: rgba(82,82,82,0.8);
    border-right: 1px solid rgba(82,82,82,0.4);
    padding: 0 10px;
    
    :first-child {
      padding-left: 0;
    }
    
    :last-child {
      padding-right: 0;
      border: none;
    }
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserData = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 15px;
  flex: 1;
  margin: 10px 0;
`;


const UserName = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #202224;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: underline;
`;

const UserDataTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const UserDataTopActionsPlaceholder = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
`;
