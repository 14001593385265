import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateProfilePanel from "../users/panels/UpdateProfilePanel";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import UserProfileBalancePanel from "../users/panels/UserProfileBalancePanel";
import UserDogsPanel from "../dogs/panels/UserDogsPanel";
import UserBookingsPanel from "../bookings/panels/UserBookingsPanel";
import AllOrdersPanel from "../orders/panels/AllOrdersPanel";
import UserProductsPanel from "../products/panels/UserProductsPanel";
import NotificationsBubble from "../notifications/panels/NotificationsBubble";
import ReshakaTemplate from "../templates/ReshakaTemplate";

const LabRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CircSpan = styled.div`
    margin-left: 5px;
    font-weight: bold;
`;


const TABS = [
    {
        label: 'Найти покупателя',
        value: 'orders',
    },

    {
        label: (
            <LabRow>
                <span>
                    Продать прокси
                </span>
                <CircSpan>
                    <NotificationsBubble types={['new_incoming_deal']}/>
                </CircSpan>
            </LabRow>
        ),
        value: 'products'
    },

    {
        label: 'Мои прокси',
        value: 'proxies'
    },
]

export default function BuyApp(props) {
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'orders' : mode);
    const [balance, setBalance] = useState(undefined);

    let selTabName = {
        products: 'Услуги',
        proxies: 'Прокси на продажу',
        bookings: 'Входящие заявки на мои прокси',
        // orders: 'Все объявления',
        orders: '',
        customers: 'Customers',
    }[tab];


    return (
        <ReshakaTemplate active={'sell'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                                CommonHelper.linkTo(`/sell/${a.value}`);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper>
                {selTabName == '' ? null :
                    <Heading>
                        {selTabName}
                    </Heading>
                }


                {tab != 'proxies' ? null :
                    <NarrowContetn>
                        <UserDogsPanel/>
                    </NarrowContetn>
                }

                {tab != 'orders' ? null :
                    <NarrowContetn>
                        <AllOrdersPanel/>
                    </NarrowContetn>
                }

                {tab != 'bookings' ? null :
                    <NarrowContetn>
                        <UserBookingsPanel mode={'incoming'}/>
                    </NarrowContetn>
                }

                {tab != 'products' ? null :
                    <NarrowContetn>
                        <UserProductsPanel/>
                    </NarrowContetn>
                }


                {tab != 'search' ? null :
                    <Content>
                        <SubHeading>
                            Find proxy with filters
                        </SubHeading>

                    </Content>
                }

            </Wrapper>

        </ReshakaTemplate>
    );
}

const NarrowContetn = styled.div`
    
`;

const Wrapper = styled.div`
    padding: 40px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 40px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
`;

