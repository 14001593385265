import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as testimonialsActions from '../../../redux/actions/TestimonialsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import CommonHelper from "../../../helpers/CommonHelper";
import UpdateTestimonialForm from "../forms/UpdateTestimonialForm";
import {BlueButton} from "../../ira/ui/Buttons";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";

import ReactStars from "react-rating-stars-component";

const noAvaImage = require('../../../assets/images/vo_ava.jpg')

export default function TestimonialsPanel(props) {
    const {
        userId,
        canAddTestimonial = true
    } = props;

    const [addVisible, setAddVisible] = useState(false);

    const {testimonials, getUser, loading} = useMappedState(useCallback(state => {
        return {
            testimonials: state.testimonials.testimonialsMap.toArray().filter(a => (a.userId == userId)),
            getUser: x => state.users.usersMap.get(x),
            loading: state.testimonials.loading
        }
    }, [userId]));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(testimonialsActions.loadAllTestimonials())
        dispatch(usersActions.loadAllUsers())
    }, [userId]);

    let items = testimonials.map(a => ({...a, creator: getUser(a.creatorId)})).filter(a => (a.creator != undefined));

    return (
        <Wrapper>

            <PageHeading>
                {items.length == 0 ? 'Отзывы' : 'Отзывы'}
            </PageHeading>

            <ItemsPlaceholder>
                {items.map((a, i) => {
                    let {creator, text} = a;
                    return (
                        <Item key={a.id}>
                            <ItemTop>
                                <UserAva src={a.avatar}/>
                                <TopRight>
                                    <TopInfo>
                                        <Name onClick={() => {
                                            CommonHelper.linkTo(`/user/${creator.id}`);
                                        }}>
                                            <div>
                                                {CommonHelper.getUserName(creator)}
                                            </div>
                                            <div>
                                                <ReactStars count={5} value={a.rating} edit={false}/>
                                            </div>
                                        </Name>
                                        <DatePlaceholder>
                                            {moment(a.timestamp).format('DD.MM.YYYY')}
                                        </DatePlaceholder>
                                    </TopInfo>
                                    <Text>
                                        {text}
                                    </Text>
                                </TopRight>
                            </ItemTop>

                        </Item>
                    )
                })}
            </ItemsPlaceholder>

            {canAddTestimonial == false ? null :
                <BottomPlaceholder>

                    {items.length > 0 ? null :
                        <p>
                            Будьте первым, кто оставит отзыв
                        </p>
                    }

                    <BlueButton onClick={() => {
                        setAddVisible(true);
                    }}>
                        Написать отзыв
                    </BlueButton>

                </BottomPlaceholder>
            }


            {addVisible == false ? null :
                <NiceModalWithHeading
                    heading={'Оставьте свой отзыв'}
                    subHeading={'Напишите свой отзыв о пользователе, качестве предоставленной услуги'}
                    onClose={() => {
                        setAddVisible(false);
                    }}
                >

                    <UpdateTestimonialForm loading={loading}
                                           onSave={async d => {
                                               dispatch(testimonialsActions.createTestimonial({
                                                   ...d,
                                                   userId: userId
                                               }));
                                               setAddVisible(false);
                                           }}/>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const UserAva = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => (props.src == undefined ? noAvaImage : props.src)});
    margin-right: 5px;
`;

const PageHeading = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
`;

const Wrapper = styled.div`
    
`;

const ItemsPlaceholder = styled.div`
    
`;

const ItemTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Item = styled.div`
    padding: 10px;
    background: white;
    border-radius: 5px;
    border: 1px solid whitesmoke;
    margin-bottom: 15px;
`;

const Name = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const TopRight = styled.div`
    flex: 1;
    box-sizing: border-box;
`;

const TopInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const DatePlaceholder = styled.div`
    opacity: 0.8;
    font-size: 12px;
`;

const Text = styled.div`
    font-size: 16px;
    font-style: italic;
    padding-top: 0px;
    font-weight: normal;
    opacity: 0.8;
    //padding-left: 53px;
`;
