import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from "styled-components";


export default function UserCommetsHistoryPanel({user, ...props}) {
    const [tab, setTab] = useState(0);


    return (
        <Wrapper>
            <Tiles>
                {/*<Tile isActive={tab == 0} onClick={() => setTab(0)}>Purchase</Tile>*/}
                <Tile isActive={tab == 1}  onClick={() => setTab(1)}>Comments</Tile>
            </Tiles>

            <Content>
                {/*{tab}*/}
                {'User has no comments yet'}
            </Content>
        </Wrapper>
    )
}



const Wrapper= styled.div`
 
`;


const Tiles= styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;

`;


const Tile= styled.div`
  border-bottom: ${props => props.isActive ? '2px solid #6280f2' : 'none'} ;
  margin-right: 10px;
  cursor: pointer;
`;


const Content= styled.div`
  padding: 10px 0;
`;
