import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const DealsAPI = {

    getUserDeals(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/deals`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                console.log('getUserDeals: loaded deals = ', pld.result);
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getProductDeals(productId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/product/${productId}/deals`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                console.log('getProductDeals: loaded deals = ', pld.result);
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },


    getAllDeals() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/deals/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getDeal(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/deal/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createDeal(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/deal`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateDeal(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/deal`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteDeal(id) {
        console.log('deleteDeal occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/deal`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default DealsAPI;
