import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateProductForm from "../forms/UpdateProductForm";

import * as actions from '../../../redux/actions/ProductsActions'
import {RedButton} from "../../ira/ui/Buttons";
import CommonHelper from "../../../helpers/CommonHelper";
import ProductDealsPanel from "../../deals/panels/ProductDealsPanel";
import UserView from "../../users/views/UserView";
import * as dealsActions from "../../../redux/actions/DealsActions";
import UpdateDealForm from "../../deals/forms/UpdateDealForm";
import ProductFlowTool from "../tools/ProductFlowTool";

export default function ProductPanel(props) {
    const {id} = props;
    const {product, currentUser, order, myDeals, loading, isMyProduct, creator, currentUserId, myTelegram} = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let product = state.products.productsMap.get(id);
        let isMyProduct = (product != undefined) && (currentUserId == product.userId);
        let creator = (product == undefined) ? undefined : state.users.usersMap.get(product.userId);
        let myTelegram = (currentUser == undefined) ? undefined : currentUser.telegram;
        return {
            product: product,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            loading: state.products.loading,
            isMyProduct: isMyProduct,
            creator: creator,
            myTelegram: myTelegram,
            myDeals: state.deals.dealsMap.toArray().filter(a => (a.productId == id && a.buyerId == currentUserId))
        }
    }, []));
    const dispatch = useDispatch();

    const [editVisible, setEditVisible] = useState(false);

    useEffect(() => {
        dispatch(actions.loadProductById(id));
        dispatch(dealsActions.loadProductDeals(id));
    }, [id]);

    const [selectedId, setSelectedId] = useState();
    const [addVisible, setAddVisible] = useState(false);

    if (product == undefined) {
        return (
            <Code/>
        )
    }

    let {countryCode, city, name, operatorsText = '', pricePerWeek, pricePerDay, pricePerMonth} = product;
    let label = (name == undefined) ? `${countryCode}, ${city}` : name;

    console.log('ProductPanel: render: myDeals = ', myDeals);

    return (
        <Wrapper>

            {isMyProduct == false ? null :
                <TopCrumbPlaceholder>
                    <CrumbItemLink onClick={() => {
                        CommonHelper.linkTo(`/products`);
                    }}>
                        Все предложения
                    </CrumbItemLink>
                    {' / '}
                    <span>
                    {label}
                </span>
                </TopCrumbPlaceholder>
            }

            <VeryTopPlaceholder>

                <div style={{flex: 1}}>
                    <Box>

                        {creator == undefined ? null :
                            <div style={{paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid whitesmoke'}}>
                                <UserView {...creator} />
                            </div>
                        }

                        <BoxTop>
                            <BoxName>
                                {label}
                            </BoxName>
                            {countryCode == undefined ? null :
                                <BigFlagPlaceholder>
                                    {flag(countryCode)}
                                </BigFlagPlaceholder>
                            }
                        </BoxTop>
                        <BoxMiddle>
                            <BoxRow>
                                <BoxIconLittle src={require('../../../assets/images/worldwide.svg')}/>
                                <BoxRowName>
                                    {`${countryCode}, ${city}`}
                                </BoxRowName>
                            </BoxRow>

                            <BoxRow>
                                <BoxIconLittle src={require('../../../assets/images/pro_money.svg')}/>
                                <BoxRowName>
                                    {`1д - ${pricePerDay}$, 7д - ${pricePerWeek}$, 30д - ${pricePerMonth}$,`}
                                </BoxRowName>
                            </BoxRow>

                            <BoxRow>
                                <BoxIconLittle src={require('../../../assets/images/info_svg_.svg')}/>
                                <BoxRowName>
                                    {operatorsText}
                                </BoxRowName>
                            </BoxRow>

                            {isMyProduct == false ? null :
                                <BoxRow onClick={() => {
                                    setEditVisible(true);
                                }}>
                                    <BoxIconLittle src={require('../../../assets/images/pencil.svg')}/>
                                    <BoxRowName style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                        редактировать
                                    </BoxRowName>
                                </BoxRow>
                            }

                        </BoxMiddle>

                        {isMyProduct == true ? null :
                            <div style={{marginTop: 20, display: 'flex', flexDirection: 'row'}}>
                                <RedButton onClick={() => {
                                    setAddVisible(true);
                                }}>
                                    Запросить прокси
                                </RedButton>
                            </div>
                        }

                    </Box>
                </div>

                {isMyProduct == true ? null :
                    <React.Fragment>
                        <TopRightFlowPlaceholder style={{width: 550}}>
                            <ProductFlowTool hasMyDeal={myDeals.length > 0}/>
                        </TopRightFlowPlaceholder>
                    </React.Fragment>
                }

            </VeryTopPlaceholder>


            <div style={{marginTop: 20}}>
                <ProductDealsPanel productId={id}/>
            </div>

            {editVisible == false ? null :
                <NiceModalWithHeading width={820}
                                      heading={'Редактировать услугу'}
                                      subHeading={''}
                                      onClose={() => {
                                          setEditVisible(false);
                                      }}>

                    <UpdateProductForm {...product}
                                       loading={loading}
                                       canDelete={true}
                                       onSave={async d => {
                                           await dispatch(actions.updateProduct({...d, id: product.id}));
                                           setEditVisible(false);
                                       }}
                                       onDelete={async () => {
                                           await dispatch(actions.deleteProduct(product.id));
                                           setEditVisible(false);
                                           CommonHelper.linkTo(`sell/products`);
                                       }}

                    />

                </NiceModalWithHeading>
            }

            {addVisible == false ? null :
                <NiceModalWithHeading heading={'Запросить прокси'}
                                      width={820}
                                      subHeading={'Укажите информацию для поставщика прокси'}
                                      onClose={() => {
                                          setAddVisible(false);
                                      }}>

                    <UpdateDealForm
                        loading={loading}
                        telegram={myTelegram}
                        canSelectProduct={false}
                        onSave={async d => {
                            await dispatch(dealsActions.createDeal({
                                ...d,
                                productId: id,
                                buyerId: currentUserId,
                                sellerId: product.userId
                            }));
                            setAddVisible(false);
                        }}

                    />

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CrumbItem = styled.div`
    
`;

const VeryTopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media(max-width: 920px){
      width: 100%;
      display: block;
    }
`;

const TopRightFlowPlaceholder = styled.div`
    width: 550px;
    @media(max-width: 920px){
      width: 100%;
      display: block;
    }
`;

const CrumbItemLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-right: 5px;
    :hover{
      opacity: 0.8;
    }
`;

const TopCrumbPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;


const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;


const ListPlaceholder = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    margin-bottom: 30px;
    margin-top: 0px;
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
`;


const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;

const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;

