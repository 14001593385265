import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton} from "../ira/ui/Buttons";
import CommonHelper from "../../helpers/CommonHelper";

export default function IraGuestTemplate(props) {


    return (
        <Wrapper className={'IraGuestTemplate'}>

            <HeaderPlaceholder>

                <LogoPlaceholder>
                    <LogoImg
                        src={require('../../assets/images/proxy_logo_long.png')}
                        onClick={() => {
                            window.location.href = `/`;
                        }}
                    />
                </LogoPlaceholder>

                <BlueButton onClick={() => {
                    CommonHelper.linkTo(`/`);
                }}>
                    Sign in
                </BlueButton>

            </HeaderPlaceholder>

            {props.children}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: #E5E5E5;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
`;

const HeaderPlaceholder = styled.div`
    width: 100vw;
    max-width: 100vw;
    height: 60px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-between;
    overflow-x: hidden;
`;

const LogoPlaceholder = styled.div`
    width: 300px;
    height: 100%;
`;

const LoginPlaceholder = styled.div`
    
`;

const LogoImg = styled.img`
    height: 100%;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
