import {combineReducers} from 'redux';

import UsersReducer from './UsersReducer.js';
import OrdersReducer from './OrdersReducer.js';
import BidsReducer from './BidsReducer.js';
import CatsReducer from './CatsReducer.js';
import DogsReducer from './DogsReducer.js';
import SnakesReducer from './SnakesReducer.js';
import BookingsReducer from './BookingsReducer.js';
import ProductsReducer from './ProductsReducer.js';
import DealsReducer from './DealsReducer.js';
import TestimonialsReducer from './TestimonialsReducer.js';
import NotificationsReducer from './NotificationsReducer.js';
import UiReducer from './UiReducer.js';

export const reducer = combineReducers({
    users: UsersReducer,
    deals: DealsReducer,
    notifications: NotificationsReducer,
    ui: UiReducer,

    cats: CatsReducer,
    products: ProductsReducer,
    dogs: DogsReducer,
    snakes: SnakesReducer,
    bookings: BookingsReducer,
    bids: BidsReducer,
    orders: OrdersReducer,

    testimonials: TestimonialsReducer
});
