import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const BookingsAPI = {

    getUserBookings(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}/bookings`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    acceptBooking(bookingId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/booking/${bookingId}/accept`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },


    getAllBookings() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/bookings/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getBooking(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/booking/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createBooking(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/booking`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateBooking(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/booking`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteBooking(id) {
        console.log('deleteBooking occured! id = ', id);
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/booking`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default BookingsAPI;
