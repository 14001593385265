import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import flag from "country-code-emoji";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateProductForm from "../forms/UpdateProductForm";

import * as actions from '../../../redux/actions/ProductsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import {RedButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";
import {PAYMENT_OPTIONS} from "../../users/panels/UserUpdatePaymentMethodsPanel";

import SmartSelect from "../../ira/ui/SmartSelect";

import {Label, Input} from "../../ira/ui/Inputs";
import CommonHelper from "../../../helpers/CommonHelper";

const listsHaveIntersection = (arr1, arr2) => {
    if (arr1 == undefined || arr2 == undefined) {
        return false;
    }
    let f = false;
    for (let i in arr1) {
        let a = arr1[i];
        if (arr2.indexOf(a) > -1) {
            f = true;
            break;
        }
    }
    return f;
};

export default function SearchProductsPanel(props) {
    const {userId} = props;
    const {
        currentUser, order, loading, isMe, products,
        uiUserRole,
        getUserDogs, getUser, currentUserId, allOrders, isMyOrder, uId
    } = useMappedState(useCallback(state => {
        let currentUserId = state.users.currentUserId;
        let uId = (userId == undefined) ? currentUserId : userId;
        console.log('uId = ', uId);
        let products = state.products.productsMap.toArray();
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            products: products,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            uId: uId,
            loading: state.products.loading,
            getUser: x => state.users.usersMap.get(x),
            uiUserRole: state.ui.userRole
        }
    }, []));
    const dispatch = useDispatch();

    const [baseCountries, setBaseCountries] = useState([]);
    const [selectedCountryCodes, setSelectedCountryCodes] = useState([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

    useEffect(() => {
        dispatch(actions.loadAllProducts(uId));
        dispatch(usersActions.loadAllProviders(uId));
        axios.get(`${API_ENDPOINT}/countries`).then(a => a.data).then(arr => {
            setBaseCountries(arr);
        })
    }, [uId]);

    const avCountSet = products.map(a => a.countryCode).reduce((st, a) => st.add(a), Set());

    const COUNTRIES_OPTIONS = baseCountries.filter(a => avCountSet.has(a.iso)).map(a => ({
        value: a.iso,
        label: `${flag(a.iso)} ${a.name_en}`
    }));

    let fProducts = products.map(a => ({...a, seller: getUser(a.userId)})).filter(a => (a.seller != undefined));
    fProducts = (selectedCountryCodes.length == 0) ? fProducts : fProducts.filter(a => listsHaveIntersection(selectedCountryCodes, [a.countryCode]));
    fProducts = (selectedPaymentMethods.length == 0) ? fProducts : fProducts.filter(a => listsHaveIntersection(selectedPaymentMethods, a.seller.paymentMethods));

    fProducts = fProducts.sort((a, b) => {
        let x = +((a.pricePerMonth == undefined) ? 1000000 : a.pricePerMonth);
        let y = +((b.pricePerMonth == undefined) ? 1000000 : b.pricePerMonth);
        let isXTrusted = (a.seller != undefined && a.seller.badges != undefined && a.seller.badges.indexOf('trusted') > -1);
        let isYTrusted = (b.seller != undefined && b.seller.badges != undefined && b.seller.badges.indexOf('trusted') > -1);
        let xDeposit = (a.seller == undefined || a.seller.deposit == undefined) ? 0 : 10.0 * (+a.seller.deposit);
        let yDeposit = (b.seller == undefined || b.seller.deposit == undefined) ? 0 : 10.0 * (+b.seller.deposit);

        x = Math.abs(x) - xDeposit;
        y = Math.abs(y) - yDeposit;
        if (+x <= 5 || +x > +10000) {
            x = +x + 10000;
        }
        if (+y <= 5 || +x > +10000) {
            y = +y + 10000;
        }
        x = +x - xDeposit;
        y = +y - yDeposit;
        if (isXTrusted) {
            x = +x - 100000;
        }
        if (isYTrusted) {
            y = +y - 100000;
        }
        return (x - y);
    })

    return (
        <Wrapper>

            <TopFiltersPlaceholder>
                <TopHalf style={{paddingRight: 5}}>
                    <Label>
                        ГЕО
                    </Label>
                    <SmartSelect options={COUNTRIES_OPTIONS}
                                 selectedValues={selectedCountryCodes}
                                 isMulti={true}
                                 placeholder={'Выбрать страну'}
                                 onSelect={a => {
                                     setSelectedCountryCodes(a);
                                 }}
                    />
                </TopHalf>
                <TopHalf style={{paddingLeft: 5}}>
                    <Label>
                        Способы оплаты
                    </Label>
                    <SmartSelect options={PAYMENT_OPTIONS}
                                 selectedValues={selectedPaymentMethods}
                                 isMulti={true}
                                 placeholder={'Выберите способ оплаты'}
                                 onSelect={a => {
                                     setSelectedPaymentMethods(a);
                                 }}
                    />
                </TopHalf>
            </TopFiltersPlaceholder>

            <ListPlaceholder>

                <HeadingItem>
                    <CountryTd>
                        {'🌎 ГЕО'}
                    </CountryTd>
                    <CityTd>
                        Город
                    </CityTd>
                    <OperatorTd style={{fontSize: 16, opacity: 1}}>
                        Оператор
                    </OperatorTd>
                    <PriceTd>
                        месяц
                    </PriceTd>
                    <PriceTd>
                        неделя
                    </PriceTd>
                    <PriceTd>
                        день
                    </PriceTd>
                    <SellerTd>
                        Продавец
                    </SellerTd>
                    <DepositTd>
                        Депозит
                    </DepositTd>

                    {/*<RatingTd>*/}

                    {/*</RatingTd>*/}
                </HeadingItem>

                {fProducts.map((a, i) => {
                    let {
                        countryCode, city, name, userId, seller,
                        operatorsText = '', pricePerWeek, pricePerDay, pricePerMonth
                    } = a;
                    let sellerHasTelegram = (seller != undefined && seller.telegramChatIds != undefined && seller.telegramChatIds.length > 0);
                    let isTrusted = (seller != undefined && seller.badges != undefined && seller.badges.indexOf('trusted') > -1);
                    return (
                        <Item key={a.id} onClick={() => {
                            // CommonHelper.linkTo(`/user/${seller.id}`);
                            CommonHelper.linkTo(`/product/${a.id}`);
                        }}>
                            <CountryTd>
                                {`${countryCode == undefined ? '' : flag(countryCode)} ${countryCode}`}
                            </CountryTd>
                            <CityTd>
                                {city}
                            </CityTd>
                            <OperatorTd>
                                {operatorsText}
                            </OperatorTd>
                            <PriceTd style={{textAlign: 'center', justifyContent: 'center'}}>
                                {pricePerMonth == undefined ? '-' : `${pricePerMonth}$`}
                            </PriceTd>
                            <PriceTd style={{textAlign: 'center', justifyContent: 'center'}}>
                                {pricePerWeek == undefined ? '-' : `${pricePerWeek}$`}
                            </PriceTd>
                            <PriceTd style={{textAlign: 'center', justifyContent: 'center'}}>
                                {pricePerDay == undefined ? '-' : `${pricePerDay}$`}
                            </PriceTd>
                            <SellerTd>
                                {sellerHasTelegram == false ? null :
                                    <SmallTgImg src={require('../../../assets/images/telega_ico.svg')}/>
                                }
                                {isTrusted == false ? null :
                                    <SmallTgImg style={{opacity: 1, width: 17, height: 17}}
                                                src={require('../../../assets/images/badge.svg')}/>
                                }
                                {CommonHelper.getUserName(seller)}
                            </SellerTd>

                            <DepositTd>
                                {(seller.deposit == undefined ? '-' : `${seller.deposit}$`)}
                            </DepositTd>

                            {/*<RatingTd>*/}

                            {/*</RatingTd>*/}
                        </Item>
                    )
                })}
            </ListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SmallTgImg = styled.img`
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 3px;
  opacity: 0.4;
`;

const TopFiltersPlaceholder = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const TopHalf = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;


const BoxRowName = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: #7C8087;
  opacity: 0.8;
  margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
  width: 50px;
  text-align: right;
  font-size: 44px;
`;


const ListPlaceholder = styled.div`

`;


const InfoPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const P = styled.p`

`;

const HeadingItem = styled.div`
  box-sizing: border-box;
  padding: 10px;
  background: floralwhite;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-weight: bold;
`;

const Item = styled.div`
  box-sizing: border-box;
  padding: 10px;
  background: white;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  cursor: pointer;

  :hover {
    background: #FFA71B;
    color: white;
  }
`;

const CountryTd = styled.div`
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  border-right: 1px solid whitesmoke;
  margin-right: 20px;
`;

const CityTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
`;

const OperatorTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  font-size: 12px;
  opacity: 0.7;
`;

const PriceTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  justify-content: center;
`;

const DepositTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100px;
  justify-content: flex-end;
`;

const SellerTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  text-align: right;
  justify-content: flex-end;
`;

const RatingTd = styled.div`

`;

const Box = styled.div`
  background: #FFFFFF;
  padding: 24px;
  width: calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  margin-bottom: 30px;
  margin-top: 0px;
  margin-right: 30px;

  cursor: pointer;

  border: 0.6px solid #D5D5D5;
  box-sizing: border-box;
  border-radius: 12px;

  :nth-of-type(3n) {
    margin-right: 0px;
  }

  :hover {
    opacity: 0.8;
  }

  @media (max-width: 1300px) {
    width: calc(50% - 15px);
    max-width: calc(50% - 15px);
    :nth-of-type(3n) {
      margin-right: 30px;
    }

    :nth-of-type(2n) {
      margin-right: 0px;
    }
  }

`;


const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  width: 100%;
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const BoxIconLittle = styled.img`
  width: 24px;
`;

const BoxMiddle = styled.div`

`;

const BoxName = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #202224;
  flex: 1;
  padding-right: 20px;
  box-sizing: border-box;
`;

