import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    dealsMap: Map(),
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const DealsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_DEAL:
        case types.UPDATE_DEAL:
        case types.DELETE_DEAL:
        case types.LOAD_DEALS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_DEAL_FAIL:
        case types.UPDATE_DEAL_FAIL:
        case types.DELETE_DEAL_FAIL:
        case types.LOAD_DEALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_DEAL_SUCCESS:
        case types.UPDATE_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                dealsMap: state.dealsMap.set(action.deal.id, action.deal)
            }


        case types.DELETE_DEAL_SUCCESS:
            return {
                ...state,
                loading: false,
                dealsMap: state.dealsMap.delete(action.id),
            }


        case types.LOAD_DEALS_SUCCESS:
            return {
                ...state,
                loading: false,
                dealsMap: state.dealsMap
                    .merge(action.deals.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default DealsReducer;
