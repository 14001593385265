import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const BidsAPI = {

    getOrderBids(orderId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/order/${orderId}/bids`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getAllBids() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/bids/all`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getBid(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/bid/${id}`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    createBid(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/bid`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateBid(data) {
        return new Promise((resolve, reject) => {
            axios.put(`${API_ENDPOINT}/bid`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    deleteBid(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/bid`, {data: {id: id}}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

}

export default BidsAPI;
