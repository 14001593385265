/**
 * Created by sabir on 19.07.17.
 */

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const LOAD_FORMS = 'LOAD_FORMS'
export const LOAD_FORMS_SUCCESS = 'LOAD_FORMS_SUCCESS'
export const LOAD_FORMS_FAIL = 'LOAD_FORMS_FAIL'

export const CREATE_FORM = 'CREATE_FORM'
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS'
export const CREATE_FORM_FAIL = 'CREATE_FORM_FAIL'

export const UPDATE_FORM = 'UPDATE_FORM'
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS'
export const UPDATE_FORM_FAIL = 'UPDATE_FORM_FAIL'

export const DELETE_FORM = 'DELETE_FORM'
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS'
export const DELETE_FORM_FAIL = 'DELETE_FORM_FAIL'

export const SELECT_FORM = 'SELECT_FORM'
export const UNSELECT_FORM = 'UNSELECT_FORM'

export const SELECT_ORGANIZATION = 'SELECT_ORGANIZATION'
export const UNSELECT_ORGANIZATION = 'UNSELECT_ORGANIZATION'

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL'

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS'
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL'

export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
export const LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAIL = 'LOAD_ORGANIZATIONS_FAIL'

//upload
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const LOAD_USER_LINKS = 'LOAD_USER_LINKS'
export const LOAD_USER_LINKS_SUCCESS = 'LOAD_USER_LINKS_SUCCESS'
export const LOAD_USER_LINKS_FAIL = 'LOAD_USER_LINKS_FAIL'

export const CREATE_USER_LINK = 'CREATE_USER_LINK'
export const CREATE_USER_LINK_SUCCESS = 'CREATE_USER_LINK_SUCCESS'
export const CREATE_USER_LINK_FAIL = 'CREATE_USER_LINK_FAIL'

export const UPDATE_USER_LINK = 'UPDATE_USER_LINK'
export const UPDATE_USER_LINK_SUCCESS = 'UPDATE_USER_LINK_SUCCESS'
export const UPDATE_USER_LINK_FAIL = 'UPDATE_USER_LINK_FAIL'

export const DELETE_USER_LINK = 'DELETE_USER_LINK'
export const DELETE_USER_LINK_SUCCESS = 'DELETE_USER_LINK_SUCCESS'
export const DELETE_USER_LINK_FAIL = 'DELETE_USER_LINK_FAIL'


//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const SEARCH_USERS = 'SEARCH_USERS'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL'


//stuff
export const LOAD_STUFFS = 'LOAD_STUFFS'
export const LOAD_STUFFS_SUCCESS = 'LOAD_STUFFS_SUCCESS'
export const LOAD_STUFFS_FAIL = 'LOAD_STUFFS_FAIL'

export const CREATE_STUFF = 'CREATE_STUFF'
export const CREATE_STUFF_SUCCESS = 'CREATE_STUFF_SUCCESS'
export const CREATE_STUFF_FAIL = 'CREATE_STUFF_FAIL'

export const UPDATE_STUFF = 'UPDATE_STUFF'
export const UPDATE_STUFF_SUCCESS = 'UPDATE_STUFF_SUCCESS'
export const UPDATE_STUFF_FAIL = 'UPDATE_STUFF_FAIL'

export const DELETE_STUFF = 'DELETE_STUFF'
export const DELETE_STUFF_SUCCESS = 'DELETE_STUFF_SUCCESS'
export const DELETE_STUFF_FAIL = 'DELETE_STUFF_FAIL'

export const SELECT_STUFF = 'SELECT_STUFF'
export const UNSELECT_STUFF = 'UNSELECT_STUFF'

//questions
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL'

export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL'

export const LOAD_QUESTIONS = 'LOAD_QUESTIONS'
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS'
export const LOAD_QUESTIONS_FAIL = 'LOAD_QUESTIONS_FAIL'

export const SELECT_QUESTION = 'SELECT_QUESTION'
export const UNSELECT_QUESTION = 'UNSELECT_QUESTION'

// tournaments

export const CREATE_TOURNAMENT = 'CREATE_TOURNAMENT'
export const CREATE_TOURNAMENT_SUCCESS = 'CREATE_TOURNAMENT_SUCCESS'
export const CREATE_TOURNAMENT_FAIL = 'CREATE_TOURNAMENT_FAIL'

export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT'
export const UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS'
export const UPDATE_TOURNAMENT_FAIL = 'UPDATE_TOURNAMENT_FAIL'

export const LOAD_TOURNAMENTS = 'LOAD_TOURNAMENTS'
export const LOAD_TOURNAMENTS_SUCCESS = 'LOAD_TOURNAMENTS_SUCCESS'
export const LOAD_TOURNAMENTS_FAIL = 'LOAD_TOURNAMENTS_FAIL'

export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT'
export const DELETE_TOURNAMENT_SUCCESS = 'DELETE_TOURNAMENT_SUCCESS'
export const DELETE_TOURNAMENT_FAIL = 'DELETE_TOURNAMENT_FAIL'

export const SELECT_TOURNAMENT = 'SELECT_TOURNAMENT'
export const UNSELECT_TOURNAMENT = 'UNSELECT_TOURNAMENT'

//stations
export const CREATE_SCHOOL = 'CREATE_SCHOOL'
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS'
export const CREATE_SCHOOL_FAIL = 'CREATE_SCHOOL_FAIL'

export const UPDATE_SCHOOL = 'UPDATE_SCHOOL'
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS'
export const UPDATE_SCHOOL_FAIL = 'UPDATE_SCHOOL_FAIL'

export const LOAD_SCHOOLS = 'LOAD_SCHOOLS'
export const LOAD_SCHOOLS_SUCCESS = 'LOAD_SCHOOLS_SUCCESS'
export const LOAD_SCHOOLS_FAIL = 'LOAD_SCHOOLS_FAIL'

export const DELETE_SCHOOL = 'DELETE_SCHOOL'
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS'
export const DELETE_SCHOOL_FAIL = 'DELETE_SCHOOL_FAIL'

export const SELECT_SCHOOL = 'SELECT_SCHOOL'
export const UNSELECT_SCHOOL = 'UNSELECT_SCHOOL'

//pages
export const CREATE_PAGE = 'CREATE_PAGE'
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_FAIL = 'CREATE_PAGE_FAIL'

export const UPDATE_PAGE = 'UPDATE_PAGE'
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_FAIL = 'UPDATE_PAGE_FAIL'

export const DELETE_PAGE = 'DELETE_PAGE'
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL'

export const LOAD_PAGES = 'LOAD_PAGES'
export const LOAD_PAGES_SUCCESS = 'LOAD_PAGES_SUCCESS'
export const LOAD_PAGES_FAIL = 'LOAD_PAGES_FAIL'

export const SELECT_PAGE = 'SELECT_PAGE'
export const UNSELECT_PAGE = 'UNSELECT_PAGE'


//groups
export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'

export const LOAD_GROUPS = 'LOAD_GROUPS'
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS'
export const LOAD_GROUPS_FAIL = 'LOAD_GROUPS_FAIL'

export const SELECT_GROUP = 'SELECT_GROUP'
export const UNSELECT_GROUP = 'UNSELECT_GROUP'

export const UNSELECT_PAGE_GROUP_QUESTION = 'UNSELECT_PAGE_GROUP_QUESTION'

//edit mode
export const SET_CONFIG_EDIT_MODE = 'SET_CONFIG_EDIT_MODE'

export const SELECT_CONFIG_USER = 'SELECT_CONFIG_USER'
export const SELECT_CONFIG_VERSION_TIMESTAMP = 'SELECT_CONFIG_VERSION_TIMESTAMP'
export const SELECT_CONFIG_VERSION_USER_AND_TIMESTAMP = 'SELECT_CONFIG_VERSION_USER_AND_TIMESTAMP'

//answers
//answers
export const CREATE_ANSWER = 'CREATE_ANSWER'
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS'
export const CREATE_ANSWER_FAIL = 'CREATE_ANSWER_FAIL'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS'
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL'

export const DELETE_ANSWER = 'DELETE_ANSWER'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_FAIL = 'DELETE_ANSWER_FAIL'

export const LOAD_ANSWERS = 'LOAD_ANSWERS'
export const LOAD_ANSWERS_SUCCESS = 'LOAD_ANSWERS_SUCCESS'
export const LOAD_ANSWERS_FAIL = 'LOAD_ANSWERS_FAIL'

//records

//records
export const CREATE_RECORD = 'CREATE_RECORD'
export const CREATE_RECORD_SUCCESS = 'CREATE_RECORD_SUCCESS'
export const CREATE_RECORD_FAIL = 'CREATE_RECORD_FAIL'

export const UPDATE_RECORD = 'UPDATE_RECORD'
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS'
export const UPDATE_RECORD_FAIL = 'UPDATE_RECORD_FAIL'

export const DELETE_RECORD = 'DELETE_RECORD'
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS'
export const DELETE_RECORD_FAIL = 'DELETE_RECORD_FAIL'

export const LOAD_RECORDS = 'LOAD_RECORDS'
export const LOAD_RECORDS_SUCCESS = 'LOAD_RECORDS_SUCCESS'
export const LOAD_RECORDS_FAIL = 'LOAD_RECORDS_FAIL'

export const SELECT_RECORD = 'SELECT_RECORD'
export const UNSELECT_RECORD = 'UNSELECT_RECORD'

export const SELECT_SECOND_RECORD = 'SELECT_SECOND_RECORD'
export const UNSELECT_SECOND_RECORD = 'UNSELECT_SECOND_RECORD'

// courses

export const LOAD_COURSES = 'LOAD_COURSES'
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS'
export const LOAD_COURSES_FAIL = 'LOAD_COURSES_FAIL'

export const CREATE_COURSE = 'CREATE_COURSE'
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL'

export const UPDATE_COURSE = 'UPDATE_COURSE'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL'

export const DELETE_COURSE = 'DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL'

export const LOAD_UNITS = 'LOAD_UNITS'
export const LOAD_UNITS_SUCCESS = 'LOAD_UNITS_SUCCESS'
export const LOAD_UNITS_FAIL = 'LOAD_UNITS_FAIL'

export const CREATE_UNIT = 'CREATE_UNIT'
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS'
export const CREATE_UNIT_FAIL = 'CREATE_UNIT_FAIL'

export const UPDATE_UNIT = 'UPDATE_UNIT'
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS'
export const UPDATE_UNIT_FAIL = 'UPDATE_UNIT_FAIL'

export const DELETE_UNIT = 'DELETE_UNIT'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAIL = 'DELETE_UNIT_FAIL'

// exercises

export const LOAD_EXERCISES = 'LOAD_EXERCISES'
export const LOAD_EXERCISES_SUCCESS = 'LOAD_EXERCISES_SUCCESS'
export const LOAD_EXERCISES_FAIL = 'LOAD_EXERCISES_FAIL'

export const CREATE_EXERCISE = 'CREATE_EXERCISE'
export const CREATE_EXERCISE_SUCCESS = 'CREATE_EXERCISE_SUCCESS'
export const CREATE_EXERCISE_FAIL = 'CREATE_EXERCISE_FAIL'

export const UPDATE_EXERCISE = 'UPDATE_EXERCISE'
export const UPDATE_EXERCISE_SUCCESS = 'UPDATE_EXERCISE_SUCCESS'
export const UPDATE_EXERCISE_FAIL = 'UPDATE_EXERCISE_FAIL'

export const DELETE_EXERCISE = 'DELETE_EXERCISE'
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS'
export const DELETE_EXERCISE_FAIL = 'DELETE_EXERCISE_FAIL'

// rooms

export const CREATE_ROOM = 'CREATE_ROOM'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAIL = 'CREATE_ROOM_FAIL'

export const UPDATE_ROOM = 'UPDATE_ROOM'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAIL = 'UPDATE_ROOM_FAIL'

export const DELETE_ROOM = 'DELETE_ROOM'
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS'
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL'

export const LOAD_ROOMS = 'LOAD_ROOMS'
export const LOAD_ROOMS_SUCCESS = 'LOAD_ROOMS_SUCCESS'
export const LOAD_ROOMS_FAIL = 'LOAD_ROOMS_FAIL'

//lessons

export const CREATE_LESSON = 'CREATE_LESSON'
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS'
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL'

export const UPDATE_LESSON = 'UPDATE_LESSON'
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS'
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL'

export const DELETE_LESSON = 'DELETE_LESSON'
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS'
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL'

export const LOAD_LESSONS = 'LOAD_LESSONS'
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS'
export const LOAD_LESSONS_FAIL = 'LOAD_LESSONS_FAIL'

// documents

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const CREATE_DOCUMENT_FAIL = 'CREATE_DOCUMENT_FAIL'

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL'

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL'

export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS'
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS'
export const LOAD_DOCUMENTS_FAIL = 'LOAD_DOCUMENTS_FAIL'

// exercises groups

export const CREATE_EXERCISES_GROUP = 'CREATE_EXERCISES_GROUP'
export const CREATE_EXERCISES_GROUP_SUCCESS = 'CREATE_EXERCISES_GROUP_SUCCESS'
export const CREATE_EXERCISES_GROUP_FAIL = 'CREATE_EXERCISES_GROUP_FAIL'

export const UPDATE_EXERCISES_GROUP = 'UPDATE_EXERCISES_GROUP'
export const UPDATE_EXERCISES_GROUP_SUCCESS = 'UPDATE_EXERCISES_GROUP_SUCCESS'
export const UPDATE_EXERCISES_GROUP_FAIL = 'UPDATE_EXERCISES_GROUP_FAIL'

export const DELETE_EXERCISES_GROUP = 'DELETE_EXERCISES_GROUP'
export const DELETE_EXERCISES_GROUP_SUCCESS = 'DELETE_EXERCISES_GROUP_SUCCESS'
export const DELETE_EXERCISES_GROUP_FAIL = 'DELETE_EXERCISES_GROUP_FAIL'

export const LOAD_EXERCISES_GROUPS = 'LOAD_EXERCISES_GROUPS'
export const LOAD_EXERCISES_GROUPS_SUCCESS = 'LOAD_EXERCISES_GROUPS_SUCCESS'
export const LOAD_EXERCISES_GROUPS_FAIL = 'LOAD_EXERCISES_GROUPS_FAIL'

// teacher groups

export const CREATE_TEACHER_GROUP = 'CREATE_TEACHER_GROUP'
export const CREATE_TEACHER_GROUP_SUCCESS = 'CREATE_TEACHER_GROUP_SUCCESS'
export const CREATE_TEACHER_GROUP_FAIL = 'CREATE_TEACHER_GROUP_FAIL'

export const UPDATE_TEACHER_GROUP = 'UPDATE_TEACHER_GROUP'
export const UPDATE_TEACHER_GROUP_SUCCESS = 'UPDATE_TEACHER_GROUP_SUCCESS'
export const UPDATE_TEACHER_GROUP_FAIL = 'UPDATE_TEACHER_GROUP_FAIL'

export const DELETE_TEACHER_GROUP = 'DELETE_TEACHER_GROUP'
export const DELETE_TEACHER_GROUP_SUCCESS = 'DELETE_TEACHER_GROUP_SUCCESS'
export const DELETE_TEACHER_GROUP_FAIL = 'DELETE_TEACHER_GROUP_FAIL'

export const LOAD_TEACHER_GROUPS = 'LOAD_TEACHER_GROUPS'
export const LOAD_TEACHER_GROUPS_SUCCESS = 'LOAD_TEACHER_GROUPS_SUCCESS'
export const LOAD_TEACHER_GROUPS_FAIL = 'LOAD_TEACHER_GROUPS_FAIL'

//tags

export const CREATE_TAG = 'CREATE_TAG'
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL'

export const UPDATE_TAG = 'UPDATE_TAG'
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL'

export const DELETE_TAG = 'DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL'

export const LOAD_TAGS = 'LOAD_TAGS'
export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS'
export const LOAD_TAGS_FAIL = 'LOAD_TAGS_FAIL'

//exercises_folders
export const CREATE_EXERCISES_FOLDER = 'CREATE_EXERCISES_FOLDER'
export const CREATE_EXERCISES_FOLDER_SUCCESS = 'CREATE_EXERCISES_FOLDER_SUCCESS'
export const CREATE_EXERCISES_FOLDER_FAIL = 'CREATE_EXERCISES_FOLDER_FAIL'

export const DELETE_EXERCISES_FOLDER = 'DELETE_EXERCISES_FOLDER'
export const DELETE_EXERCISES_FOLDER_SUCCESS = 'DELETE_EXERCISES_FOLDER_SUCCESS'
export const DELETE_EXERCISES_FOLDER_FAIL = 'DELETE_EXERCISES_FOLDER_FAIL'

export const UPDATE_EXERCISES_FOLDER = 'UPDATE_EXERCISES_FOLDER'
export const UPDATE_EXERCISES_FOLDER_SUCCESS = 'UPDATE_EXERCISES_FOLDER_SUCCESS'
export const UPDATE_EXERCISES_FOLDER_FAIL = 'UPDATE_EXERCISES_FOLDER_FAIL'

export const LOAD_EXERCISES_FOLDERS = 'LOAD_EXERCISES_FOLDERS'
export const LOAD_EXERCISES_FOLDERS_SUCCESS = 'LOAD_EXERCISES_FOLDERS_SUCCESS'
export const LOAD_EXERCISES_FOLDERS_FAIL = 'LOAD_EXERCISES_FOLDERS_FAIL'

// games
export const CREATE_GAME = 'CREATE_GAME'
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS'
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL'

export const UPDATE_GAME = 'UPDATE_GAME'
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS'
export const UPDATE_GAME_FAIL = 'UPDATE_GAME_FAIL'

export const LOAD_GAMES = 'LOAD_GAMES'
export const LOAD_GAMES_SUCCESS = 'LOAD_GAMES_SUCCESS'
export const LOAD_GAMES_FAIL = 'LOAD_GAMES_FAIL'

export const DELETE_GAME = 'DELETE_GAME'
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS'
export const DELETE_GAME_FAIL = 'DELETE_GAME_FAIL'

//test

export const LOAD_TESTS = 'LOAD_TESTS'
export const LOAD_TESTS_SUCCESS = 'LOAD_TESTS_SUCCESS'
export const LOAD_TESTS_FAIL = 'LOAD_TESTS_FAIL'

export const CREATE_TEST = 'CREATE_TEST'
export const CREATE_TEST_SUCCESS = 'CREATE_TEST_SUCCESS'
export const CREATE_TEST_FAIL = 'CREATE_TEST_FAIL'

export const UPDATE_TEST = 'UPDATE_TEST'
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS'
export const UPDATE_TEST_FAIL = 'UPDATE_TEST_FAIL'

export const DELETE_TEST = 'DELETE_TEST'
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS'
export const DELETE_TEST_FAIL = 'DELETE_TEST_FAIL'

//ui

export const SET_DRAGGABLE_LESSON = 'SET_DRAGGABLE_LESSON'
export const UNSET_DRAGGABLE_LESSON = 'UNSET_DRAGGABLE_LESSON'

// smart exercises

export const CREATE_SMART_EXERCISE = 'CREATE_SMART_EXERCISE'
export const CREATE_SMART_EXERCISE_SUCCESS = 'CREATE_SMART_EXERCISE_SUCCESS'
export const CREATE_SMART_EXERCISE_FAIL = 'CREATE_SMART_EXERCISE_FAIL'

export const UPDATE_SMART_EXERCISE = 'UPDATE_SMART_EXERCISE'
export const UPDATE_SMART_EXERCISE_SUCCESS = 'UPDATE_SMART_EXERCISE_SUCCESS'
export const UPDATE_SMART_EXERCISE_FAIL = 'UPDATE_SMART_EXERCISE_FAIL'

export const DELETE_SMART_EXERCISE = 'DELETE_SMART_EXERCISE'
export const DELETE_SMART_EXERCISE_SUCCESS = 'DELETE_SMART_EXERCISE_SUCCESS'
export const DELETE_SMART_EXERCISE_FAIL = 'DELETE_SMART_EXERCISE_FAIL'

export const LOAD_SMART_EXERCISES = 'LOAD_SMART_EXERCISES'
export const LOAD_SMART_EXERCISES_SUCCESS = 'LOAD_SMART_EXERCISES_SUCCESS'
export const LOAD_SMART_EXERCISES_FAIL = 'LOAD_SMART_EXERCISES_FAIL'

// orders
export const LOAD_ORDERS = 'LOAD_ORDERS'
export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS'
export const LOAD_ORDERS_FAIL = 'LOAD_ORDERS_FAIL'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

export const DELETE_ORDER = 'DELETE_ORDER'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'

//dogs

export const LOAD_DOGS = 'LOAD_DOGS'
export const LOAD_DOGS_SUCCESS = 'LOAD_DOGS_SUCCESS'
export const LOAD_DOGS_FAIL = 'LOAD_DOGS_FAIL'

export const CREATE_DOG = 'CREATE_DOG'
export const CREATE_DOG_SUCCESS = 'CREATE_DOG_SUCCESS'
export const CREATE_DOG_FAIL = 'CREATE_DOG_FAIL'

export const UPDATE_DOG = 'UPDATE_DOG'
export const UPDATE_DOG_SUCCESS = 'UPDATE_DOG_SUCCESS'
export const UPDATE_DOG_FAIL = 'UPDATE_DOG_FAIL'

export const DELETE_DOG = 'DELETE_DOG'
export const DELETE_DOG_SUCCESS = 'DELETE_DOG_SUCCESS'
export const DELETE_DOG_FAIL = 'DELETE_DOG_FAIL'

// cats

export const LOAD_CATS = 'LOAD_CATS'
export const LOAD_CATS_SUCCESS = 'LOAD_CATS_SUCCESS'
export const LOAD_CATS_FAIL = 'LOAD_CATS_FAIL'

export const CREATE_CAT = 'CREATE_CAT'
export const CREATE_CAT_SUCCESS = 'CREATE_CAT_SUCCESS'
export const CREATE_CAT_FAIL = 'CREATE_CAT_FAIL'

export const UPDATE_CAT = 'UPDATE_CAT'
export const UPDATE_CAT_SUCCESS = 'UPDATE_CAT_SUCCESS'
export const UPDATE_CAT_FAIL = 'UPDATE_CAT_FAIL'

export const DELETE_CAT = 'DELETE_CAT'
export const DELETE_CAT_SUCCESS = 'DELETE_CAT_SUCCESS'
export const DELETE_CAT_FAIL = 'DELETE_CAT_FAIL'

// snakes

export const CREATE_SNAKE = 'CREATE_SNAKE'
export const CREATE_SNAKE_SUCCESS = 'CREATE_SNAKE_SUCCESS'
export const CREATE_SNAKE_FAIL = 'CREATE_SNAKE_FAIL'

export const UPDATE_SNAKE = 'UPDATE_SNAKE'
export const UPDATE_SNAKE_SUCCESS = 'UPDATE_SNAKE_SUCCESS'
export const UPDATE_SNAKE_FAIL = 'UPDATE_SNAKE_FAIL'

export const DELETE_SNAKE = 'DELETE_SNAKE'
export const DELETE_SNAKE_SUCCESS = 'DELETE_SNAKE_SUCCESS'
export const DELETE_SNAKE_FAIL = 'DELETE_SNAKE_FAIL'

export const LOAD_SNAKES = 'LOAD_SNAKES'
export const LOAD_SNAKES_SUCCESS = 'LOAD_SNAKES_SUCCESS'
export const LOAD_SNAKES_FAIL = 'LOAD_SNAKES_FAIL'

// bookings

export const CREATE_BOOKING = 'CREATE_BOOKING'
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS'
export const CREATE_BOOKING_FAIL = 'CREATE_BOOKING_FAIL'

export const DELETE_BOOKING = 'DELETE_BOOKING'
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS'
export const DELETE_BOOKING_FAIL = 'DELETE_BOOKING_FAIL'

export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS'
export const UPDATE_BOOKING_FAIL = 'UPDATE_BOOKING_FAIL'

export const LOAD_BOOKINGS = 'LOAD_BOOKINGS'
export const LOAD_BOOKINGS_SUCCESS = 'LOAD_BOOKINGS_SUCCESS'
export const LOAD_BOOKINGS_FAIL = 'LOAD_BOOKINGS_FAIL'

// bids

export const LOAD_BIDS = 'LOAD_BIDS'
export const LOAD_BIDS_SUCCESS = 'LOAD_BIDS_SUCCESS'
export const LOAD_BIDS_FAIL = 'LOAD_BIDS_FAIL'

export const UPDATE_BID = 'UPDATE_BID'
export const UPDATE_BID_SUCCESS = 'UPDATE_BID_SUCCESS'
export const UPDATE_BID_FAIL = 'UPDATE_BID_FAIL'

export const DELETE_BID = 'DELETE_BID'
export const DELETE_BID_SUCCESS = 'DELETE_BID_SUCCESS'
export const DELETE_BID_FAIL = 'DELETE_BID_FAIL'

export const CREATE_BID = 'CREATE_BID'
export const CREATE_BID_SUCCESS = 'CREATE_BID_SUCCESS'
export const CREATE_BID_FAIL = 'CREATE_BID_FAIL'

//
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAIL = 'LOAD_PRODUCTS_FAIL'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'

export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

//

export const CREATE_DEAL = 'CREATE_DEAL'
export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS'
export const CREATE_DEAL_FAIL = 'CREATE_DEAL_FAIL'

export const UPDATE_DEAL = 'UPDATE_DEAL'
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS'
export const UPDATE_DEAL_FAIL = 'UPDATE_DEAL_FAIL'

export const LOAD_DEALS = 'LOAD_DEALS'
export const LOAD_DEALS_SUCCESS = 'LOAD_DEALS_SUCCESS'
export const LOAD_DEALS_FAIL = 'LOAD_DEALS_FAIL'

export const DELETE_DEAL = 'DELETE_DEAL'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_FAIL = 'DELETE_DEAL_FAIL'

// testimonials

export const CREATE_TESTIMONIAL = 'CREATE_TESTIMONIAL'
export const CREATE_TESTIMONIAL_SUCCESS = 'CREATE_TESTIMONIAL_SUCCESS'
export const CREATE_TESTIMONIAL_FAIL = 'CREATE_TESTIMONIAL_FAIL'

export const UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL'
export const UPDATE_TESTIMONIAL_SUCCESS = 'UPDATE_TESTIMONIAL_SUCCESS'
export const UPDATE_TESTIMONIAL_FAIL = 'UPDATE_TESTIMONIAL_FAIL'

export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL'
export const DELETE_TESTIMONIAL_SUCCESS = 'DELETE_TESTIMONIAL_SUCCESS'
export const DELETE_TESTIMONIAL_FAIL = 'DELETE_TESTIMONIAL_FAIL'

export const LOAD_TESTIMONIALS = 'LOAD_TESTIMONIALS'
export const LOAD_TESTIMONIALS_SUCCESS = 'LOAD_TESTIMONIALS_SUCCESS'
export const LOAD_TESTIMONIALS_FAIL = 'LOAD_TESTIMONIALS_FAIL'

// notifications

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL'

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL'

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS'
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS'
export const LOAD_NOTIFICATIONS_FAIL = 'LOAD_NOTIFICATIONS_FAIL'

//ui
export const SET_UI_USER_ROLE = 'SET_UI_USER_ROLE'
