import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import flag from 'country-code-emoji';

import * as dogsActions from '../../../redux/actions/DogsActions'
import * as snakesActions from '../../../redux/actions/SnakesActions'
import * as bookingsActions from '../../../redux/actions/BookingsActions'


import {RedButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateBookingForm from "../../bookings/forms/UpdateBookingForm";
import NiceConfirm from "../../modals/NiceConfirm";
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserBookingsPanel(props) {
    const {userId, mode = 'outgoing'} = props;
    const [refreshing, setRefreshing] = useState(false);

    const [addVisible, setAddVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [successBookingId, setSuccessBookingId] = useState();

    const [acceptedBookingId, setAcceptedBookingId] = useState(undefined);

    const {
        loading, usersMap, uId, dogs,
        isMe, getDogActiveSnakes,
        bookings, getDog,
        getDogBookings, currentUserId
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            currentUserId: state.users.currentUserId,
            loading: state.users.loading || state.bookings.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            usersMap: state.users.usersMap,
            uId: uId,
            dogs: state.dogs.dogsMap.toArray().filter(a => (a.userId == uId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            bookings: state.bookings.bookingsMap.toArray()
                .filter(a => (a.sellerId == uId || a.buyerId == uId)).map(a => ({
                    ...a,
                    dog: state.dogs.dogsMap.get(a.dogId)
                })).filter(a => (a.dog != undefined))
                .sort((a, b) => (+b.timestamp - +a.timestamp)),
            isMe: uId == state.users.currentUserId,
            getDog: dId => state.dogs.dogsMap.get(dId),

        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dogsActions.loadUserDogs(uId));
        dispatch(dogsActions.loadAllDogs());
        dispatch(snakesActions.loadUserSnakes(uId));
        dispatch(bookingsActions.loadUserBookings(uId));
    }, [uId]);

    console.log('render: bookings = ', bookings);
    console.log('render: uId = ', uId);

    let selectedBooking = (selectedId == undefined) ? undefined : bookings.filter(a => (a.id == selectedId))[0];
    let incomingBookings = bookings.filter(a => (a.sellerId == uId));
    let outgoingBookings = bookings.filter(a => (a.buyerId == uId)).filter(a => (a.status != 'accepted'));
    let rBookings = (mode == 'incoming') ? incomingBookings : outgoingBookings;

    let selectedDog = (selectedBooking == undefined) ? undefined : getDog(selectedBooking.id);

    console.log('rBookings = ', rBookings);

    if (loading == false && rBookings.length == 0) {
        return (
            <Wrapper>
                {mode == 'incoming' ? 'You have no bookings from customers yet.' : 'You have no bookings yet'}
            </Wrapper>
        )
    }

    return (
        <Wrapper>

            <ListPlaceholder>
                {rBookings.map((a, i) => {
                    let {sellerId, buyerId, dogId, dog, message, contacts, timestamp, fromTimestamp, toTimestamp} = a;
                    let {countryCode, city} = dog;
                    // let label = (name == undefined) ? `${dog.countryCode}, ${dog.city}` : name;
                    return (
                        <Box key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <BoxTop>
                                <BoxName>
                                    {`${moment(timestamp).format('DD.MM.YYYY HH:mm')}, ${countryCode}, ${city}`}
                                </BoxName>
                                {countryCode == undefined ? null :
                                    <BigFlagPlaceholder>
                                        {flag(countryCode)}
                                    </BigFlagPlaceholder>
                                }
                            </BoxTop>
                            <BoxMiddle>
                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/cont.svg')}/>
                                    <BoxRowName>
                                        {`${contacts}`}
                                    </BoxRowName>
                                </BoxRow>
                                <BoxRow>
                                    <BoxIconLittle src={require('../../../assets/images/cal_cal.svg')}/>
                                    <BoxRowName>
                                        {`${moment(fromTimestamp).format('DD.MM.YYYY')} - ${moment(toTimestamp).format('DD.MM.YYYY')}`}
                                    </BoxRowName>
                                </BoxRow>
                                <MessagePlaceholder>
                                    {message}
                                </MessagePlaceholder>
                            </BoxMiddle>
                        </Box>
                    )
                })}
            </ListPlaceholder>


            {selectedBooking == undefined ? null :
                <NiceModalWithHeading
                    width={540}
                    heading={'Review booking'}
                    subHeading={(mode == 'outgoing') ? 'This is booking that you requested' : 'This is the booking that someone has created to use your proxy'}
                    onClose={() => {
                        setSelectedId(undefined);
                    }}>

                    <ModalInnerPlaceholder>

                        <SelectedBookingControlsPlaceholder>

                            <MessagePlaceholder style={{marginBottom: 30}}>
                                {(uId == selectedBooking.buyerId && selectedBooking.status == 'new') ? 'Seller will contact you via information that you provided.' : null}
                            </MessagePlaceholder>

                            <MessagePlaceholder style={{textAlign: 'left', display: 'block', marginBottom: 30}}>

                                {(selectedDog == undefined || uId != selectedBooking.sellerId) ? null :
                                    <div>
                                        {`This customer wants to book your proxy "${selectedDog.name}" (${selectedDog.countryCode}, ${selectedDog.city})`}
                                    </div>
                                }

                                <b>Contacts:</b> <span style={{textAlign: 'left'}}>{selectedBooking.contacts}</span>
                                <br/>
                                <br/>
                                <b>Message:</b> <span style={{textAlign: 'left'}}>{selectedBooking.message}</span>
                                <br/>
                                <br/>
                                <b>From date:</b> <span
                                style={{textAlign: 'left'}}>{moment(selectedBooking.fromTimestamp).format('DD.MM.YYYY')}</span>
                                <br/>
                                <b>To date:</b> <span
                                style={{textAlign: 'left'}}>{moment(selectedBooking.toTimestamp).format('DD.MM.YYYY')}</span>
                                <br/>
                                <br/>

                            </MessagePlaceholder>


                            {!(selectedBooking.status == 'accepted' || selectedBooking.buyerId == uId) ? null :
                                <DeleteButtonPlaceholder>
                                    <RedButton onClick={async () => {
                                        await dispatch(bookingsActions.deleteBooking(selectedBooking.id));
                                        setSelectedId(undefined);
                                    }}>
                                        Delete booking
                                    </RedButton>
                                </DeleteButtonPlaceholder>
                            }

                            {selectedBooking.status == 'accepted' || selectedBooking.sellerId != uId ? null :
                                <RedButton onClick={async () => {
                                    let pld = await dispatch(bookingsActions.acceptBooking(selectedBooking.id));
                                    setAcceptedBookingId(selectedId);
                                    setSelectedId(undefined);
                                }}>
                                    ACCEPT
                                </RedButton>
                            }

                        </SelectedBookingControlsPlaceholder>

                    </ModalInnerPlaceholder>

                </NiceModalWithHeading>
            }

            {successBookingId == undefined ? null :
                <NiceConfirm heading={'Booking created!'}
                             subHeading={'The booking has been created! Provider will contact you soon via contacts that you put in the request'}
                             onConfirm={() => {
                                 CommonHelper.linkTo(`/buy/bookings`);
                             }}
                             onCancel={() => {
                                 setSuccessBookingId(undefined);
                             }}
                             confirmText={'GO TO MY BOOKINGS'}
                             cancelText={'STAY ON THIS PAGE'}
                />
            }


        </Wrapper>
    );
}

const DeleteButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const SelectedBookingControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MessagePlaceholder = styled.div`
    
`;

const BoxRowName = styled.div`
    font-size: 16px;
    line-height: 28px;
    color: #7C8087;
    opacity: 0.8;
    margin-left: 15px;
`;

const BigFlagPlaceholder = styled.div`
    width: 50px;
    text-align: right;
    font-size: 44px;
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    justify-content: space-between;
    width: 100%;
`;

const BoxRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-bottom: 10px; 
`;


const BoxMiddle = styled.div`
    
`;

const BoxName = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: #202224;
    flex: 1;
    padding-right: 20px;
    box-sizing: border-box;
`;


const ModalInnerPlaceholder = styled.div`
    width: 100%;
    min-height: 140px;
`;

const Wrapper = styled.div`
    
`;

const Heading = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;


const ListPlaceholder = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Item = styled.div`
    
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const P = styled.p`
    
`;

const BoxIconLittle = styled.img`
    width: 24px;
`;


const Box = styled.div`
    background: #FFFFFF;
    padding: 24px;
    width: calc(50% - 15px);
    max-width: calc(50% - 15px);

      
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 30px;
    
    cursor: pointer;
    
    border: 0.6px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 12px;
    
    :nth-of-type(2n){
      margin-right: 0px;
    }
    :hover{
      opacity: 0.8;
    }
    
    
`;
