import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

import {Input, Textarea, Label} from "../../ira/ui/Inputs";

export default function UpdateProfileForm(props) {
    const {
        onSave = d => {
        },
        loading = false,
        extraContent = undefined
    } = props;

    console.log('UpdateProfileForm: render: props = ', props);

    const [telegram, setTelegram] = useState(props.telegram == undefined ? '' : props.telegram);
    const [avatar, setAvatar] = useState(props.avatar);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [about, setAbout] = useState(props.about == undefined ? '' : props.about);
    const [publicContacts, setPublicContacts] = useState(props.publicContacts == undefined ? '' : props.publicContacts);


    console.log('UpdateProfileForm: render: props = ', props);
    console.log('publicContacts = ', publicContacts);

    return (
        <Wrapper>

            <AvaSectionPlaceholder>

                <AvaPlaceholder>
                    <SimpleUploadPanel onUploaded={url => {
                        setAvatar(url);
                        onSave({avatar: url});
                    }}>
                        <AvaWrap>
                            <AvaBox src={avatar}>
                                {avatar != undefined ? null :
                                    <MaskImg src={require('../../../assets/images/photo_mask.svg')}/>
                                }
                            </AvaBox>
                            <AvaLabel>
                                Upload avatar
                            </AvaLabel>
                        </AvaWrap>
                    </SimpleUploadPanel>
                </AvaPlaceholder>
            </AvaSectionPlaceholder>

            <Row>
                <Field>
                    <Label>
                        Имя
                    </Label>
                    <Input placeholder={'Введите ваше имя'}
                           value={firstName}
                           onChange={evt => {
                               setFirstName(evt.target.value);
                           }}/>
                </Field>

                {/*<Field>*/}
                    {/*<Label>*/}
                        {/*Фамилия*/}
                    {/*</Label>*/}
                    {/*<Input placeholder={'Введите вашу фамилию'}*/}
                           {/*value={lastName}*/}
                           {/*onChange={evt => {*/}
                               {/*setLastName(evt.target.value);*/}
                           {/*}}/>*/}
                {/*</Field>*/}

                <Field>
                    <Label>
                        Номер телефона
                    </Label>
                    <Input placeholder={'Телефон'}
                           value={phone}
                           onChange={evt => {
                               setPhone(evt.target.value);
                           }}/>
                </Field>

                <Field>
                    <Label>
                        Telegram
                    </Label>
                    <Input placeholder={'Telegram'}
                           value={telegram}
                           onChange={evt => {
                               setTelegram(evt.target.value);
                           }}/>
                </Field>

            </Row>

            <Field style={{width: '100%'}}>
                <Label>
                    Обо мне
                </Label>
                <Textarea value={about}
                          placeholder={'Обо мне'}
                          onChange={evt => {
                              setAbout(evt.target.value);
                          }}/>
            </Field>

            {/*<Field style={{width: '100%', marginTop: 20}}>*/}
                {/*<Label>*/}
                    {/*Public contact details*/}
                {/*</Label>*/}
                {/*<Textarea value={publicContacts}*/}
                          {/*placeholder={'This information will be available for you customers'}*/}
                          {/*onChange={evt => {*/}
                              {/*setPublicContacts(evt.target.value);*/}
                          {/*}}/>*/}
            {/*</Field>*/}

            <ButtonPlaceholder>
                <Button onClick={() => {
                    onSave({
                        telegram,
                        firstName,
                        lastName,
                        phone,
                        avatar,
                        about,
                        // publicContacts
                    });
                }}>
                    <Spin theme={'alfa-on-color'} visible={loading}/>
                    {loading == true ? null :
                        'SAVE'
                    }

                </Button>
            </ButtonPlaceholder>

        </Wrapper>
    );
}

const Button = styled.div`
    border-radius: 100px;
    background: #FF624F;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 55px;
    padding-right: 55px;
    height: 44px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const ButtonPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const Wrapper = styled.div`
    
`;

const MaskImg = styled.div`
    height: 21px;
`;

const AvaPlaceholder = styled.div`
    
`;

const AvaWrap = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AvaBox = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 1000px;
    background: #ECECEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
`;

const AvaLabel = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    color: #085BFF;
`;

const AvaSectionPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const Field = styled.div`
    //width: calc(25% - 20px);
    width: calc(33.33% - 20px);
`;
