import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    dogsMap: Map(),
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const DogsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_DOG:
        case types.UPDATE_DOG:
        case types.DELETE_DOG:
        case types.LOAD_DOGS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_DOG_FAIL:
        case types.UPDATE_DOG_FAIL:
        case types.DELETE_DOG_FAIL:
        case types.LOAD_DOGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_DOG_SUCCESS:
        case types.UPDATE_DOG_SUCCESS:
            return {
                ...state,
                loading: false,
                dogsMap: state.dogsMap.set(action.dog.id, action.dog)
            }


        case types.DELETE_DOG_SUCCESS:
            return {
                ...state,
                loading: false,
                dogsMap: state.dogsMap.delete(action.id),
            }


        case types.LOAD_DOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                dogsMap: state.dogsMap
                    .merge(action.dogs.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default DogsReducer;
