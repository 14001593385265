import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import axios from 'axios'


import {GreenButton, PurpleButton} from "../ira/ui/Buttons";
import SmartSelect from "../ira/ui/SmartSelect";
import WeekCalendar from "../ira/calendars/WeekCalendar";
import SimpleMonthCalendar from "../ira/calendars/SimpleMonthCalendar";
import SimpleDateSelector from "../ira/calendars/SimpleDateSelector";
import TwoPeopleChatPanel from "../chat/panels/TwoPeopleChatPanel";
import IproxyConnectionsPanel from "../iproxy/panels/IproxyConnectionsPanel";


export default function DevApp() {
    const [pieces, setPieces] = useState([]);
    const [t, setT] = useState(undefined);

    return (
        <Wrapper>

            <IproxyConnectionsPanel/>

            {/*<div style={{width: 600, height: 300, backgroundColor: 'whitesmoke'}}>*/}
                {/*<TwoPeopleChatPanel firstUserId={'sabir'} secondUserId={'danon'}/>*/}
            {/*</div>*/}


            {/*<PurpleButton>*/}
            {/*Hello there!*/}
            {/*</PurpleButton>*/}

            {/*<div style={{width: 920, height: 700}}>*/}
            {/*<SimpleDateSelector/>*/}
            {/*</div>*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CommutePlaceholder = styled.div`
    width: 100vw;
    height: 100vh;
`;
