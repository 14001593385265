import DogsAPI from '../../api/DogsAPI'

import * as types from '../ActionTypes'

let loadDogs_ = () => {
    return {
        type: types.LOAD_DOGS
    }
}
let loadDogsSuccess = (dogs) => {
    return {
        type: types.LOAD_DOGS_SUCCESS,
        dogs: dogs
    }
}
let loadDogsFailed = (error) => {
    return {
        type: types.LOAD_DOGS_FAIL,
        error: error
    }
}

//thunk
export function loadAllDogs() {
    return (dispatch, getState) => {
        dispatch(loadDogs_());
        return DogsAPI.getAllDogs().then(
            dogs => dispatch(loadDogsSuccess(dogs)),
            error => dispatch(loadDogsFailed(error))
        )
    }
}

export function loadUserDogs(userId) {
    return (dispatch, getState) => {
        dispatch(loadDogs_());
        return DogsAPI.getUserDogs(userId).then(
            dogs => dispatch(loadDogsSuccess(dogs)),
            error => dispatch(loadDogsFailed(error))
        )
    }
}


export function loadDogById(id) {
    return (dispatch, getState) => {
        dispatch(loadDogs_());
        return DogsAPI.getDog(id).then(
            dog => dispatch(loadDogsSuccess([dog])),
            error => dispatch(loadDogsFailed(error))
        )
    }
}

let createDog_ = () => {
    return {
        type: types.CREATE_DOG
    }
}
let createDogSuccess = (dog) => {
    return {
        type: types.CREATE_DOG_SUCCESS,
        dog: dog
    }
}
let createDogFailed = (error) => {
    return {
        type: types.CREATE_DOG_FAIL,
        error: error
    }
}

//thunk
export function createDog(data) {
    return (dispatch, getState) => {
        dispatch(createDog_());
        return DogsAPI.createDog(data).then(
            dog => dispatch(createDogSuccess(dog)),
            error => dispatch(createDogFailed(error))
        )
    }
}

let updateDog_ = () => {
    return {
        type: types.UPDATE_DOG
    }
}
let updateDogSuccess = (dog) => {
    return {
        type: types.UPDATE_DOG_SUCCESS,
        dog: dog
    }
}
let updateDogFailed = (error) => {
    return {
        type: types.UPDATE_DOG_FAIL,
        error: error
    }
}

//thunk
export function updateDog(data) {
    return (dispatch, getState) => {
        dispatch(updateDog_());
        return DogsAPI.updateDog(data).then(
            dog => dispatch(updateDogSuccess(dog)),
            error => dispatch(updateDogFailed(error))
        )
    }
}

let deleteDog_ = () => {
    return {
        type: types.DELETE_DOG
    }
}
let deleteDogSuccess = (id) => {
    return {
        type: types.DELETE_DOG_SUCCESS,
        id: id
    }
}
let deleteDogFailed = (error) => {
    return {
        type: types.DELETE_DOG_FAIL,
        error: error
    }
}

//thunk
export function deleteDog(id) {
    return (dispatch, getState) => {
        dispatch(deleteDog_());
        return DogsAPI.deleteDog(id).then(
            () => dispatch(deleteDogSuccess(id)),
            error => dispatch(deleteDogFailed(error))
        )
    }
}
