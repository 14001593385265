import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo, Component} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {FixedSizeList as List} from "react-window";

import {Input, Label, Textarea} from "../../ira/ui/Inputs";
import '../../dogs/forms/style.css'
import Select, {createFilter} from 'react-select'
import {BlueButton} from "../../ira/ui/Buttons";
import NiceConfirm from "../../modals/NiceConfirm";

export default function UpdateDealForm(props) {
    const {
        loading = false,
        canDelete = false,
        canSelectProduct = true,
        onSave = d => {

        },
        products = [],
        onDelete = () => {

        }
    } = props;

    const [telegram, setTelegram] = useState(props.telegram == undefined ? '' : props.telegram);
    const [contacts, setContacts] = useState(props.contacts == undefined ? '' : props.contacts);
    const [text, setText] = useState(props.text == undefined ? '' : props.text);
    const [productId, setProductId] = useState(props.productId);


    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    const [countryCode, setCountryCode] = useState(props.countryCode == undefined ? 'RU' : props.countryCode);
    const [city, setCity] = useState(props.city);

    const [operatorsText, setOperatorsText] = useState(props.operatorsText == undefined ? '' : props.operatorsText);

    const [pricePerMonth, setPricePerMonth] = useState(props.pricePerMonth == undefined ? '' : props.pricePerMonth);
    const [pricePerWeek, setPricePerWeek] = useState(props.pricePerWeek == undefined ? '' : props.pricePerWeek);
    const [pricePerDay, setPricePerDay] = useState(props.pricePerDay == undefined ? '' : props.pricePerDay);

    const [countriesLoading, setCountriesLoading] = useState(false);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [allCities, setAllCities] = useState([]);

    useEffect(() => {
        setCountriesLoading(true);
        axios.get(`${API_ENDPOINT}/countries`).then(d => d.data).then(arr => {
            setAllCountries(arr);
            setCountriesLoading(false);
        })
    }, []);

    useEffect(() => {
        setCitiesLoading(true);
        axios.get(`${API_ENDPOINT}/country/${countryCode}/cities`).then(d => d.data.cities).then(arr => {
            console.log('aaarrr = ', arr);
            let sortedArr = arr.filter(a => (a != undefined && a.name_en != undefined)).sort((a, b) => {
                let s1 = a.name_en.toLowerCase();
                let s2 = a.name_en.toLowerCase();
                if (s1 > s2) {
                    return -1;
                }
                if (s1 < s2) {
                    return 1;
                }
                return 0;
            });
            setAllCities(sortedArr);
            setCitiesLoading(false);
        })
    }, [countryCode]);


    const COUNTRIES_OPTIONS = allCountries.map(a => ({value: a.iso, label: a.name_en}));
    const CITIES_OPTIONS = allCities.map(a => ({value: a.name_en, label: a.name_en}));

    if (countriesLoading == true) {
        return (
            <Wrapper>
                <Spin visible={true}/>
            </Wrapper>
        )
    }

    console.log('city = ', city);

    let productsOptions = products.map(a => ({value: a.id, label: a.name}))

    return (
        <Wrapper>

            <Field>
                <Label>
                    Сообщение для поставщика
                </Label>
                <Textarea
                    placeholder={'Напишите сообщение '}
                    value={text}
                    onChange={evt => {
                        setText(evt.target.value);
                    }}/>
            </Field>

            {canSelectProduct == false ? null :
                <Field>
                    <Label>
                        Выберите прокси, которые хотите заказать
                    </Label>
                    <Select options={productsOptions}
                            placeholder={'Укажите что вы хотите купить'}
                            value={productsOptions.filter(a => (a.value == productId))[0]}
                            onChange={a => {
                                setProductId(a.value);
                            }}/>
                </Field>
            }

            <Row>

                <Half>
                    <Field>
                        <Label>
                            Ваш Telegram
                        </Label>
                        <Input value={telegram} placeholder={'Укажите для связи с Вами'} onChange={evt => {
                            setTelegram(evt.target.value);
                        }}/>
                    </Field>
                </Half>

                <Half>
                    <Field>
                        <Label>
                            Дополнительные контакты
                        </Label>
                        <Input value={contacts} placeholder={'Доп. контакты для связи продавца с вами'}
                               onChange={evt => {
                                   setContacts(evt.target.value);
                               }}/>
                    </Field>
                </Half>

            </Row>

            <BottomSavePlaceholder canDelete={canDelete}>
                <BlueButton style={{width: 200}} loading={loading} onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        text,
                        telegram,
                        contacts,
                        productId
                    });
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : 'Save'}
                </BlueButton>

                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        setConfirmDeleteVisible(true);
                    }}>
                        Удалить предложение
                    </DeleteSpan>
                }

            </BottomSavePlaceholder>

            {confirmDeleteVisible == false ? null :
                <NiceConfirm
                    onClose={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onCancel={() => {
                        setConfirmDeleteVisible(false);
                    }}
                    onConfirm={() => {
                        onDelete();
                    }}
                    confirmText={'ДА, УДАЛИТЬ'}
                    cancelText={'Нет, не удалять'}
                    heading={'Удалить'}
                    subHeading={'Вы уверены?'}

                />
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const DeleteSpan = styled.div`
    cursor: pointer;
    color: #F07867;
    font-size: 14px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 20px;
    :hover{
      opacity: 0.9;
    }
`;

const BottomSavePlaceholder = styled.div`
    display: flex;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.canDelete == true ? 'space-between' : 'center')};
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
    box-sizing: border-box;
    
    padding-left: 5px;
    padding-right: 5px;
    
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;
`;


const height = 35;

class MenuList extends Component {
    render() {
        const {options, children, maxHeight, getValue} = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
